export class RoleCompanies
{
  constructor( roleId?: string, companyId?: number )
  {
    this.roleId = roleId;
    this.companyId = companyId;
  }

  public id?: number;
  public roleId?: string;
  public companyId?: number;
}
