// tslint:disable:no-console
import { Injectable } from '@angular/core';
import { HttpResponseBase } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Utilities } from '../../utils/utilities';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import { AppTranslationService } from '..';

const alertify: any = require('../../../../assets/scripts/alertify.js');

@Injectable()
export class AlertService {
  private messages = new Subject<AlertCommand>();
  private dialogs = new Subject<AlertDialog>();
  stickyToasties: number[] = [];
  private loadingMessageTimeoutId: any;
  private gT: any;
  /*  private messagesToastr = new Subject<NbToastrService>();*/


  constructor(private toastaService: ToastaService, private toastaConfig: ToastaConfig, private translationService: AppTranslationService) {
    this.toastaConfig.theme = 'material';
    this.toastaConfig.position = 'top-left';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = true;
    this.toastaConfig.timeout = 3000;

    this.gT = (key: string, interpolateParams?: object) => this.translationService.getTranslation(key, interpolateParams);

    this.getDialogEvent().subscribe(alert => this.showDialogs(alert));
    this.getMessageEvent().subscribe(message => this.showToasts(message));
  }

  showDialogs(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || 'OK',
        cancel: dialog.cancelLabel || 'Cancel'
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);
        break;
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          }, dialog.defaultValue);

        break;
    }
  }

  showToasts(alert: AlertCommand) {
    setTimeout(() => {
      if (alert.operation === 'clear') {
        for (const id of this.stickyToasties.slice(0)) {
          this.toastaService.clear(id);
        }

        return;
      }

      const toastOptions: ToastOptions = {
        title: alert.message.summary,
        msg: alert.message.detail,
        showClose: this.toastaConfig.showClose,
        timeout: this.toastaConfig.timeout,
        theme: this.toastaConfig.theme,
        showDuration: this.toastaConfig.showDuration
      };


      if (alert.operation === 'add_sticky') {
        toastOptions.timeout = 0;

        toastOptions.onAdd = (toast: ToastData) => {
          this.stickyToasties.push(toast.id);
        };

        toastOptions.onRemove = (toast: ToastData) => {
          const index = this.stickyToasties.indexOf(toast.id, 0);

          if (index > -1) {
            this.stickyToasties.splice(index, 1);
          }

          if (alert.onRemove) {
            alert.onRemove();
          }

          toast.onAdd = null;
          toast.onRemove = null;
        };
      } else {
        toastOptions.timeout = 6000;
      }


      switch (alert.message.severity) {
        case MessageSeverity.default: this.toastaService.default(toastOptions); break;
        case MessageSeverity.info: this.toastaService.info(toastOptions); break;
        case MessageSeverity.success: this.toastaService.success(toastOptions); break;
        case MessageSeverity.error: this.toastaService.error(toastOptions); break;
        case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
        case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
      }
    });
  }

  showDialog(message: string);
  showDialog(message: string, type: DialogType, okCallback: (val?: any) => any);
  showDialog(message: string, type: DialogType, okCallback?: (val?: any) => any, cancelCallback?: () => any, okLabel?: string, cancelLabel?: string, defaultValue?: string);
  showDialog(message: string, type?: DialogType, okCallback?: (val?: any) => any, cancelCallback?: () => any, okLabel?: string, cancelLabel?: string, defaultValue?: string) {

    if (!type) {
      type = DialogType.alert;
    }

    const alertDialog: AlertDialog = { message, type, okCallback, cancelCallback, okLabel, cancelLabel, defaultValue };

    this.showDialogs(alertDialog);

    // this.dialogs.next(alertDialog);
  }

  showMessage(summary: string);
  showMessage(summary: string, detail: string, severity: MessageSeverity);
  showMessage(summaryAndDetails: string[], summaryAndDetailsSeparator: string, severity: MessageSeverity);
  showMessage(response: HttpResponseBase, ignoreValueUseNull: string, severity: MessageSeverity);
  showMessage(data: any, separatorOrDetail?: string, severity?: MessageSeverity) {
    if (!severity) {
      severity = MessageSeverity.default;
    }

    if (data instanceof HttpResponseBase) {
      data = Utilities.getHttpResponseMessages(data);
      separatorOrDetail = Utilities.captionAndMessageSeparator;
    }

    if (data instanceof Array) {
      for (const message of data) {
        const msgObject = Utilities.splitInTwo(message, separatorOrDetail);

        this.showMessageHelper(msgObject.firstPart, msgObject.secondPart, severity, false);
      }
    } else {
      this.showMessageHelper(data, separatorOrDetail, severity, false);
    }
  }

  showStickyMessage(summary: string);
  showStickyMessage(summary: string, detail: string, severity: MessageSeverity, error?: any);
  showStickyMessage(summary: string, detail: string, severity: MessageSeverity, error?: any, onRemove?: () => any);
  showStickyMessage(summaryAndDetails: string[], summaryAndDetailsSeparator: string, severity: MessageSeverity);
  showStickyMessage(response: HttpResponseBase, ignoreValueUseNull: string, severity: MessageSeverity);
  showStickyMessage(data: string | string[] | HttpResponseBase, separatorOrDetail?: string, severity?: MessageSeverity, error?: any, onRemove?: () => any) {

    if (!severity) {
      severity = MessageSeverity.default;
    }

    if (data instanceof HttpResponseBase) {
      data = Utilities.getHttpResponseMessages(data);
      separatorOrDetail = Utilities.captionAndMessageSeparator;
    }

    if (data instanceof Array) {
      for (const message of data) {
        const msgObject = Utilities.splitInTwo(message, separatorOrDetail);

        this.showMessageHelper(msgObject.firstPart, msgObject.secondPart, severity, true);
      }
    } else {
      if (error) {
        const msg = `${this.gT('app.Error.Severity', { severity: MessageSeverity[severity], summary: data, detail: separatorOrDetail, error: Utilities.safeStringify(error) })}`;
        // const msg = `Severity: "${MessageSeverity[severity]}", Summary: "${data}", Detail: "${separatorOrDetail}", Error: "${Utilities.safeStringify(error)}"`;

        switch (severity) {
          case MessageSeverity.default:
            this.logInfo(msg);
            break;
          case MessageSeverity.info:
            this.logInfo(msg);
            break;
          case MessageSeverity.success:
            this.logMessage(msg);
            break;
          case MessageSeverity.error:
            this.logError(msg);
            break;
          case MessageSeverity.warn:
            this.logWarning(msg);
            break;
          case MessageSeverity.wait:
            this.logTrace(msg);
            break;
        }
      }

      this.showMessageHelper(data, separatorOrDetail, severity, true, onRemove);
    }
  }

  private showMessageHelper(summary: string, detail: string, severity: MessageSeverity, isSticky: boolean, onRemove?: () => any) {

    const alertCommand: AlertCommand = {
      operation: isSticky ? 'add_sticky' : 'add',
      message: { severity, summary, detail },
      onRemove
    };
    this.showToasts(alertCommand);

    // this.messages.next(alertCommand);
  }

  resetStickyMessage() {
    this.messages.next({ operation: 'clear' });
  }

  startLoadingMessage(message = `${this.gT('app.Load.Loading')}`, caption = '') {
    clearTimeout(this.loadingMessageTimeoutId);

    this.loadingMessageTimeoutId = setTimeout(() => {
      this.showStickyMessage(caption, message, MessageSeverity.wait);
    }, 1000);
  }

  stopLoadingMessage() {
    clearTimeout(this.loadingMessageTimeoutId);
    this.resetStickyMessage();
  }


  //#region generic
  //#region save
  saveSuccessMessage(isNew: boolean, entity: string, entityKey?: any) {
    this.stopLoadingMessage();
    let detail: string;
    if (isNew) {
      detail = `${this.gT('app.Save.SaveSucessCreate', { entityName: entity, key: entityKey })}`;
    } else {
      if (entityKey) {
        detail = `${this.gT('app.Save.SaveSucessUpdate', { entityName: entity, key: entityKey })}`;
      } else {
        detail = `${this.gT('app.Save.SaveSucessUpdateGeneric', { entityName: entity })}`;
      }
    }
    this.showMessage(`${this.gT('app.Save.Success')}`, detail, MessageSeverity.success);
  }

  saveErrorMessage(entity: string, error: any) {
    this.stopLoadingMessage();
    this.showStickyMessage(`${this.gT('app.Save.SaveError')}`, `${this.gT('app.Save.SaveErrorMessage', { entityName: entity })}`, MessageSeverity.error, error);
    this.showStickyMessage(error, null, MessageSeverity.error);
  }

  saveResetErrorMessage(entity: string, error: any) {
    this.stopLoadingMessage();
    this.showStickyMessage(`${this.gT('app.Save.SaveError')}`, `${this.gT('app.Save.SavingResetErrorMessage', { entityName: entity })}`, MessageSeverity.error, error);
    this.showStickyMessage(error, null, MessageSeverity.error);
  }
  //#endregion

  cancelMessage(entity?: string) {
    this.showMessage(`${this.gT('app.Cancel.Cancelled')}`, `${this.gT('app.Cancel.CancelledMessage')}`, MessageSeverity.default);
    this.resetStickyMessage();
  }

  deleteErrorMessage(entity: string, error: any, showAnotherMessage?: boolean) {
    this.stopLoadingMessage();
    this.showStickyMessage(`${this.gT('app.Delete.DeleteError')}`, `${this.gT('app.Error.Delete', { entityName: entity })} ${Utilities.getHttpResponseMessages(error)}`, MessageSeverity.error, error);
    if (showAnotherMessage && showAnotherMessage === true) {
      this.showStickyMessage(error, null, MessageSeverity.error);
    }
  }

  loadErrorMessage(entity: string, error: any, showAnotherMessage?: boolean) {
    this.stopLoadingMessage();
    this.showStickyMessage(`${this.gT('app.Load.LoadError')}`, `${this.gT('app.Error.Unable', { entityName: entity })} ${Utilities.getHttpResponseMessages(error)}`, MessageSeverity.error, error);
    if (showAnotherMessage && showAnotherMessage === true) {
      this.showStickyMessage(error, null, MessageSeverity.error);
    }
  }
  //#endregion

  logDebug(msg) {
    console.debug(msg);
  }

  logError(msg) {
    console.error(msg);
  }

  logInfo(msg) {
    console.info(msg);
  }

  logMessage(msg) {
    console.log(msg);
  }

  logTrace(msg) {
    console.trace(msg);
  }

  logWarning(msg) {
    console.warn(msg);
  }

  getDialogEvent(): Observable<AlertDialog> {
    return this.dialogs.asObservable();
  }

  getMessageEvent(): Observable<AlertCommand> {
    return this.messages.asObservable();
  }
}

// ******************** Dialog ********************//
export class AlertDialog {
  constructor(
    public message: string,
    public type: DialogType,
    public okCallback: (val?: any) => any,
    public cancelCallback: () => any,
    public defaultValue: string,
    public okLabel: string,
    public cancelLabel: string) {

  }
}

export enum DialogType {
  alert,
  confirm,
  prompt
}
// ******************** End ********************//


// ******************** Growls ********************//
export class AlertCommand {
  constructor(
    public operation: 'clear' | 'add' | 'add_sticky',
    public message?: AlertMessage,
    public onRemove?: () => any) { }
}

export class AlertMessage {
  constructor(
    public severity: MessageSeverity,
    public summary: string,
    public detail: string) { }
}

export enum MessageSeverity {
  default,
  info,
  success,
  error,
  warn,
  wait
}
// ******************** End ********************//
