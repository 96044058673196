import { AuditDetailValues } from './audit-detail-values.model';

export class AuditDetails {
  id?: number;
  idAudit?: string;
  idUser?: string;

  userName?: string;
  type?: string;
  dateTime?: Date;

  oldValues?: any;
  newValues?: any;

  affectedColumns?: any[];
  primaryKey?: any;
  auditDetailValues?: AuditDetailValues[];
}
