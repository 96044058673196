import { Component, Input } from '@angular/core';
import { UserErpPermission } from '../../../@core/models';
import { AlertService, AppTranslationService, MessageSeverity } from '../../../@core/services';
import { Utilities } from '../../../@core/utils';

@Component({
  selector: 'app-info-base',
  template: ''
})
export class InfoComponent {
  public selectedRows: any[];
  public selectedValues: { [key: string]: boolean; } = {};

  // "multiple" | "none" | "single"
  public selectionMode: string = 'none';

  public isEditMode: boolean = false;
  public isNewEntity: boolean = false;
  public isSaving: boolean = false;
  public showValidationErrors: boolean = true;
  public formResetToggle: boolean = true;
  public isEditingSelf: boolean = false;

  public showProduct: boolean = false;
  public getProduct: boolean = true;
  public editGrid: boolean = false;
  public showObs: boolean = false;
  public editingorderProduct: boolean = false;

  // public isViewOnly: boolean = false;

  public editingTypeName: { type: string, name: string };
  public editingName: { name: string };
  public loadingIndicator: boolean;
  public uniqueId: string = Utilities.uniqueId();

  public editingEntity: boolean = false;

  public idCompany: number;
  public userSalesman: string = 'N_A';
  public salesmanLine = '';
  public defaultUnit: string = 'UN';

  public currentPath: string;
  public lastPath: string;

  public gT: any;

  protected userErpPermission: UserErpPermission = new UserErpPermission();
  protected dataUserErpPermission: UserErpPermission[] = [];

  @Input() isViewOnly: boolean = false;
  @Input() isGeneralEditor: boolean = false;

  constructor(protected alertService: AlertService, protected translationService: AppTranslationService) {
    this.loadingIndicator = true;
    this.gT = (key: string, interpolateParams?: object) => this.translationService.getTranslation(key, interpolateParams);
  }

  getTranslate(value: string) {
    return this.gT(value);
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
}
