import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { AuthService } from '../services';

@Injectable()
export class AuthPermissions implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const url: string = state.url;
    return this.checkPermission(url, route);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {

    const url = `/${route.path}`;
    return this.checkPermission(url);
  }

  checkPermission(url: string, route?: ActivatedRouteSnapshot): boolean {
    let canView = false;
    try {
      const d = this.checkRouteChildrensPermissions(route);
      if (d && d.data && d.data.role) {
        const _role = d.data.role;
        canView = this.authService.userHasPermission(_role);
      }
      else if (route && route.data && route.data.role) {
        const _role = route.data.role;
        canView = this.authService.userHasPermission(_role);
      } else if (route && route.data && route.data.roles && Array.isArray(route.data.roles)) {
        for (const _role of route.data.roles) {
          if (this.authService.userHasPermission(_role)) {
            canView = true;
          }
        }
      }
    } catch {}
    return canView;
  }

  checkChildrensPermissions(childrens?: ActivatedRouteSnapshot[]): ActivatedRouteSnapshot {
    let result: ActivatedRouteSnapshot;
    if (childrens && childrens.length > 0) {
      for (const c of childrens) {
        result = this.checkChildrenPermissions(c);
      }
    }
    return result;
  }

  checkChildrenPermissions(root?: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    let result: ActivatedRouteSnapshot;
    if (root && root.children && root.children.length > 0) {
      result = this.checkChildrensPermissions(root.children);
    } else {
      result = root;
    }
    return result;
  }

  checkRouteChildrensPermissions(route?: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    let result: ActivatedRouteSnapshot;
    try {
      if (route && route.children && route.children.length > 0) {
        result = this.checkChildrensPermissions(route.children);
      } else {
        result = route;
      }
    } catch { }
    return result;
  }

}
