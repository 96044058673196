import { NgModule } from '@angular/core';

import { ThemeModule } from '../@theme/theme.module';

import { AuthBlockComponent, LoginComponent, LogoutComponent } from './components';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';

import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbCardModule,
  NbFormFieldModule
} from '@nebular/theme';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ThemeModule,
    AuthRoutingModule,
    //CoreModule,
    NbLayoutModule,
    NbCheckboxModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    NbCardModule,
    NbFormFieldModule
    //FormsModule,
  ],
  exports: [AuthComponent, AuthBlockComponent, LoginComponent, LogoutComponent],
  declarations: [
    AuthComponent, AuthBlockComponent, LoginComponent, LogoutComponent
  ],
})
export class AuthModule {
}
