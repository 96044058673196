import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Audit } from '../../models';
import { AuthService, ConfigurationService, ApiBaseService } from '..';

@Injectable()
export class AuditService extends ApiBaseService {

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService) {
    super(configurations, http, authService);
  }

  //#region get
  getAudit(id: number): Observable<Audit> {
    return this.getById<Audit>(`${this.controllerAudit}/audit`, id);
  }

  getAuditLines(id: number): Observable<Audit> {
    return this.getById<Audit>(`${this.controllerAudit}/auditLines`, id);
  }

  getAudits(userId: string): Observable<Audit[]> {
    return this.getBykey<Audit[]>(`${this.controllerAudit}/audities`, userId);
  }

  getAuditsByTable(table: string): Observable<Audit[]> {
    return this.getBykey<Audit[]>(`${this.controllerAudit}/auditiesTable`, table);
  }

  getAuditsByTableByFilters(table: string, dateFrom?: Date, dateTo?: Date): Observable<Audit[]> {
    return this.getBykeyByFilters<Audit[]>(`${this.controllerAudit}/auditiesTable`, table, dateFrom, dateTo);
  }

  getAuditsByIdTable(idTable: string, table: string): Observable<Audit[]> {
    return this.getBykeyKey2<Audit[]>(`${this.controllerAudit}/auditiesTable`, idTable, table);
  }
  //#endregion

  newAudit(entity: any): Observable<Audit> {
    return this.post<Audit>(this.controllerAudit, entity);
  }

  updateAudit(entity: any): Observable<Audit> {
    return this.put<Audit>(this.controllerAudit, entity);
  }

  deleteAudit(id: any): Observable<Audit> {
    return this.delete<Audit>(this.controllerAudit, id);
  }
}
