import { Component } from '@angular/core';
import { Subject } from 'rxjs';

import { UserErpPermission } from '../../../@core/models';
import { AppTranslationService } from '../../../@core/services';
import { Utilities } from '../../../@core/utils';

@Component({
  selector: 'app-management-base',
  template: ''
})
export class ManagementComponent {
  public destroy$: Subject<void> = new Subject<void>();

  public columns: any[] = [];
  public selectedRows: any[];
  // "multiple" | "none" | "single"
  public selectionMode: string = 'none';
  public editingTypeName: { type: string, name: string };
  public editingName: { name: string };
  public loadingIndicator: boolean;
  public uniqueId: string = Utilities.uniqueId();

  public editingEntity: boolean = false;
  protected isEditMode: boolean = false;
  public isSaving: boolean = false;
  public formResetToggle: boolean = true;
  public showError: boolean = false;
  public erpCompanDisabled: boolean = false;

  public idCompany: number;
  public userSalesman: string = 'N_A';

  public currentPath: string;

  protected gT: any;
  public dateFrom: Date;
  public dateTo: Date;
  public dateDisabled: boolean = false;
  public estado: string = 'All';

  public userErpPermission: UserErpPermission = new UserErpPermission();
  public dataUserErpPermission: UserErpPermission[] = [];

  constructor(protected translationService: AppTranslationService) {
    this.showError = false;
    this.loadingIndicator = true;
    this.dateFrom = Utilities.dateNowByMonth(-2);
    this.dateTo = Utilities.dateNow();
    this.gT = (key: string, interpolateParams?: object) => this.translationService.getTranslation(key, interpolateParams);
  }

  //#region toLowerCase
  public toLowerCase(value: string): string {
    if (value && value !== undefined) {
      return value.toLowerCase();
    } else {
      return '';
    }
  }
  //#endregion
}
