import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../@core/services';

@Component({
  selector: 'app-auth',
  styleUrls: ['auth.component.scss'],
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  subscription: any;

  authenticated: boolean = false;
  token: string = '';

  constructor(private authService: AuthService) {

//    let user: any = authService.currentUser;

    this.subscription = authService.onAuthenticationChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((authenticated: boolean) => {
        this.authenticated = authenticated;
      });
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
