import { ArtigoUnidades } from './artigo-unidades.model';
import { CategoryEntension } from '../catalog/catalog-extension.model';
import { Image } from '../images/image.model';
import { Sale } from '../sales/sale.model';
import { ImageCategoryIcon } from '../images/image.category.icon.model';
import { Price } from './price.model';

export class Artigos extends CategoryEntension
{
  order: Sale;

  idProduct: number;
  isSelect: boolean = false;
  image: Image;
  artigo: string;
  descricao: string;
  descricaoEn: string;
  descricaoDe: string;
  desconto: number;
  desconto2: number = 0;
  desconto3: number = 0;
  boxPrice: number;
  pricePerUnit: number;
  precUnit: number;
  pvp1: number;
  moeda: string;
  unidade: string;

  descontoOrg: number;
  descontoOrg2: number = 0;
  descontoOrg3: number = 0;
  unidadeOrg: string;

  marca: string;
  categoria: string;
  subCategoria: string;
  sku: string;
  variants: string;
  quantidade: number;
  totalPriceTax: number;
  totalPrice: number;
  iva: string;
  taxaIva: number;
  allowPriceUnit: boolean;
  unidadesPrecos: string;
  descontoFinanceiro: number;
  status: number;
  icons: ImageCategoryIcon[];
  unidades: ArtigoUnidades[];

  stock: number = 0;

  id: string;
  name: string;
  imageUrl: string;
  code: string;
  description: string;

  recordCount: number;
  prices: Price[];

  __collectionMeta: {
    recordCount: number;
    pageCount: number;
  };
}
