export class MoradasAlternativa {
  id: number;
  IdMorada: number;
  idOrder: number;
  idPurchaser: number;
  cliente: string;
  tipoEntidade: string;
  nome: string;
  moradaAlternativa: string;
  tipoEntidadeMoradaAlternativa: string;
  moradaPorDefeito: string;
  morada: string;
  morada2: number;
  localidade: string;
  distrito: string;
  codigoPostal: string;
  localidadeCodigoPostal: string;
  pais: string;
  descricaoMorada: string;
  fullAddress: string;
}
