import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DBkeys, LocalStoreManager } from '../conf';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart = new BehaviorSubject({
    orderId: this.orderId,
    itemCount: this.itemCount
  });

  cartValue = this.cart.asObservable();

  constructor(private storage: LocalStoreManager) { }

  get orderId(): number {
    const id = this.storage.getItem(DBkeys.ORDER_ID);
    return id ? Number.parseFloat(id) : 0;
  }

  set orderId(id: number) {
    this.storage.addItem(DBkeys.ORDER_ID, id.toString());
    this.cart.next({ orderId: id, itemCount: this.itemCount });
  }

  get itemCount(): number {
    const itemCount = this.storage.getItem(DBkeys.ORDER_ITEM_COUNT);

    return itemCount ? parseInt(itemCount) : 0;
  }

  set itemCount(amount: number) {
    this.storage.addItem(DBkeys.ORDER_ITEM_COUNT, amount.toString());
    this.cart.next({ orderId: this.orderId, itemCount: amount });
  }

  incrementItemCount(amount: number) {
    this.itemCount = this.itemCount + amount;
  }

  decrementItemCount(amount: number) {
    this.itemCount = this.itemCount - amount;
  }

  clearCart() {
    this.storage.deleteItem(DBkeys.ORDER_ITEM_COUNT);
    this.cart.next({ orderId: 0, itemCount: 0 });
  }
}
