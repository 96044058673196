import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ConfigurationService } from '../';
import { Utilities } from '../../utils/utilities';
import { ApiBaseService } from '../api/api-base.service';

@Injectable()
export class PurchaseEndpoint extends ApiBaseService {
  constructor(protected configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(configurations, http, authService);
  }

  //#region Purchases
  //#region Get
  getPurchaseByFiltersEndpoint<T>(id: any, companyId: number, salesman: string, purchaseType: number, internalPurchaseType: number, estado: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/purchase/purchase/${id}/${companyId}/${salesman}/${purchaseType}/${internalPurchaseType}/${estado}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchaseByFiltersEndpoint(id, companyId, salesman, purchaseType, internalPurchaseType, estado));
      }));
  }

  getPurchasesByFiltersEndpoint<T>(companyId: number, salesman: string, purchaseType: number, estado: string, dateFrom?: Date, dateTo?: Date): Observable<T> {
    let endpointUrl = `${this.configurations.baseUrl}/api/purchase/purchases/${companyId}/${salesman}/${purchaseType}/${estado}`;

    if (!Utilities.isNullOrUndefined(dateFrom) || !Utilities.isNullOrUndefined(dateTo)) {
      endpointUrl += `/${!Utilities.isNullOrUndefined(dateFrom) ? Utilities.DateToJson(dateFrom) : ' '}`;
      endpointUrl += `${!Utilities.isNullOrUndefined(dateTo) ? '/' + Utilities.DateToJson(dateTo) : ' '}`;
    }

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchasesByFiltersEndpoint(companyId, salesman, purchaseType, estado, dateFrom, dateTo));
      }));
  }

  getTopPurchasesEndpoint<T>(idCompany: number, tipoDoc: string, serie: string, provider: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/purchase/purchasesTop/${idCompany}/${tipoDoc}/${serie}/${provider}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTopPurchasesEndpoint(idCompany, tipoDoc, serie, provider));
      }));
  }

  getReportEndpoint<T>(idCompany: number, purchaseType: number, id: any, title: string, icon: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/purchase/report/${idCompany}/${purchaseType}/${id}/${title}/${icon}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getReportEndpoint(idCompany, purchaseType, id, title, icon));
      }));
  }

  setReportEndpoint<T>(idCompany: number, reportObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/purchase/report/${idCompany}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(reportObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setReportEndpoint(idCompany, reportObject));
      }));
  }
  //#endregion

  //#region Patch
  setPurchaseUpdateEndpoint<T>(idCompany: number, purchaseType: number, purchasesObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchase/${idCompany}/${purchaseType}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseUpdateEndpoint(idCompany, purchaseType, purchasesObject));
      }));
  }

  setPurchasesUpdateEndpoint<T>(idCompany: number, purchaseType: number, purchasesObject: any[]): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchases/${idCompany}/${purchaseType}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseUpdateEndpoint(idCompany, purchaseType, purchasesObject));
      }));
  }

  setPurchasePrintEndpoint<T>(idCompany: number, purchaseType: number, purchasesObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchasePrint/${idCompany}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchasePrintEndpoint(idCompany, purchaseType, purchasesObject));
      }));
  }
  //#endregion

  //#endregion

  //#region Get
  getGenericEndpoint<T>(endPoint: string, id: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/${endPoint}/${id}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getGenericEndpoint(endPoint, id));
      }));
  }

  getGenericsEndpoint<T>(endPoint: string, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/document/${endPoint}/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/document/${endPoint}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getGenericsEndpoint(endPoint, page, pageSize));
      }));
  }

  getByCompanydIdGenericEndpoint<T>(endPoint: string, companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/document/${endPoint}/${companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/document/${endPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByCompanydIdGenericEndpoint(endPoint, companyId, page, pageSize));
      }));
  }


  //#endregion

  //#region Cancel Purchases
  getCancelPurchasesEndpoint<T>(idCompany: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/cancelPurchases/${idCompany}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCancelPurchasesEndpoint(idCompany));
      }));
  }

  setPurchaseUpdateCancelEndpoint<T>(idCompany: number, purchasesObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchaseCancel/${idCompany}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseUpdateCancelEndpoint(idCompany, purchasesObject));
      }));
  }
  //#endregion

  //#region Moradas
  getMoradaAlternativaByEntidadeVendedorEndpoint<T>(idCompany: number, entidade: string, vendedor?: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/moradaalternativa/${idCompany}/${entidade}` + (vendedor ? `/${vendedor}` : '');
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMoradaAlternativaByEntidadeVendedorEndpoint(idCompany, entidade, vendedor));
      }));
  }
  //#endregion

  //#region Artigos
  getArtigoCatalogsEndpoint<T>(idCompany: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/artigosCatalog/${idCompany}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getArtigoCatalogsEndpoint(idCompany));
      }));
  }

  getArtigosByClientEndpoint<T>(idCompany: number, client: string, moradaAlternativa: string, unidade: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/artigoClient/${idCompany}/${client}/${moradaAlternativa}/${unidade}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getArtigosByClientEndpoint(idCompany, client, moradaAlternativa, unidade));
      }));
  }
  //#endregion

  getErpCompaniesEndPoint<T>(): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/companies`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getErpCompaniesEndPoint());
      }));
  }

  getReferenceEndPoint<T>(idCompany: number, provider: string, moradaAlternativa: string, ano: string, referencia: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/referencia/${idCompany}/${provider}/${moradaAlternativa}/${ano}/${referencia}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getReferenceEndPoint(idCompany, provider, moradaAlternativa, ano, referencia));
      }));
  }

  //#region Purchases Draft
  ////
  // Purchases Draft
  ////
  setPurchaseDraftDeleteEndpoint<T>(idCompany: number, purchasesObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchaseDelete/${idCompany}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseDraftDeleteEndpoint(idCompany, purchasesObject));
      }));
  }

  setPurchasesDraftDeleteEndpoint<T>(idCompany: number, purchasesObject: any[]): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchasesDeletes/${idCompany}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchasesDraftDeleteEndpoint(idCompany, purchasesObject));
      }));
  }

  setPurchaseDraftErpUpdateEndpoint<T>(idCompany: number, purchasesObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchaseErp/${idCompany}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseDraftErpUpdateEndpoint(idCompany, purchasesObject));
      }));
  }

  setPurchasesDraftErpUpdateEndpoint<T>(idCompany: number, purchasesObject: any[]): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchasesErp/${idCompany}`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchasesDraftErpUpdateEndpoint(idCompany, purchasesObject));
      }));
  }
  //#endregion

  getCurrentUserPermission<T>(): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/users/mepermssion`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCurrentUserPermission());
      }));
  }
}
