import { ArtigoUnidades } from '../artigos/artigo-unidades.model';
import { AuditableEntity } from '../commons';

export class PurchaseLines extends AuditableEntity {
  idPurchaseLine?: number;
  idPurchase?: number;
  idDoc?: string;
  id?: string;
  numLine?: number;
  artigo?: string;
  descricao?: string;

  descricaoPt?: string;
  descricaoEn?: string;
  descricaoDe?: string;

  tipoLinha?: string = '20';
  quantidade?: number;
  precUnit?: number;
  precUnitOrig?: number;
  boxPrice?: number;
  pricePerUnit?: number;
  desconto?: number;
  desconto2?: number;
  desconto3?: number;
  precoLiquido?: number;
  unidade?: string;
  armazem?: string;
  localizacao?: string;
  vendedor?: string;
  totalPriceTax?: number;
  totalPrice?: number;
  descontoFinanceiro?: number;
  taxaIva?: number;
  allowPriceUnit?: boolean;

  autoriza?: string;
  skuCode?: string;
  imageUrl?: string;
  name?: string;
  quantity?: number;
  skuId?: number;
  formattedTotalAmount?: string;
  formattedUnitAmount?: string;
  classificacao?: string;


  unidades?: ArtigoUnidades[];

  isSaving?: boolean;
  isEmailAutoriza?: boolean;
  sendEmail?: boolean;

  isEditing?: boolean;
}
