import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LayoutService, Utilities } from '../../../@core/utils';
import { AppTitleService, AuthService, CartService, CustomerService, AppTranslationService } from '../../../@core/services';
import { AppPortal, User, Permission, PermissionValues } from '../../../@core/models';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: User;
  isUserLoggedIn: boolean;
  appTitle = 'Portal';
  cartAmount: number = 0;
  cardVisibility: boolean = false;
  gT: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile', link: '/settings/users/current', queryParams: { profile: true }, hidden: false }, { title: 'Settings', link: '/settings/users/preference', hidden: false }, { title: 'Log out', link: '/auth/logout', hidden: false }];

  constructor(private authService: AuthService, private appTitleService: AppTitleService, private translationService: AppTranslationService,
    private cart: CartService, private sidebarService: NbSidebarService, private themeService: NbThemeService, private layoutService: LayoutService, private customerService: CustomerService, private breakpointService: NbMediaBreakpointsService) {
    this.appTitleService.appName = this.appTitle;
    this.gT = (key: string) => this.translationService.getTranslation(key);
    this.userMenu = [
      { title: this.gT('settings.tab.Profile'), link: '/settings/users/current', queryParams: { profile: true }, hidden: this.isHidden(Permission.manageUserPermission) },
      { title: this.gT('settings.tab.Preferences'), link: '/settings/users/preference', hidden: this.isHidden(Permission.viewUserPreferencesPermission) },
      { title: this.gT('pageHeader.Settings'), link: '/settings/users/info', hidden: this.isHidden(Permission.manageUsersPermission) },
      { title: this.gT('settings.tab.LogOut'), link: '/auth/logout', hidden: false }];
  }

  async ngOnInit() {

    try {
      await this.getTitleApp();
    } catch (e) {
      console.log('title::' + e);
    }

    this.isUserLoggedIn = this.authService.isLoggedIn;

    this.currentTheme = this.themeService.currentTheme;
    this.user = this.authService.currentUser;

    this.layoutService.setCardVisibility(false);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.layoutService.showCardVisibility$.subscribe(visible => this.cardVisibility = visible);
    this.cart.cartValue.subscribe(cart => this.cartAmount = cart.itemCount);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.authService.gotoHomePage();
    return false;
  }

  async getTitleApp() {
    const app = await this.customerService.getCustomerAsync<AppPortal>('portal', 'appPortalsTitle');
    if (app && app.title) {
      this.appTitle = app.title;
      this.appTitleService.appName = this.appTitle;
    }
  }

  isHidden(name?: PermissionValues): boolean {
    if (Utilities.isNullOrUndefined(name)) {
      return true;
    } else {
      return !this.authService.userHasPermission(name);
    }
  }
}
