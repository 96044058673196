import { Injectable } from '@angular/core';
import TreeView from 'devextreme/ui/tree_view';

import { Utilities } from './utilities';
import { Artigos, ProductCatalog, CatalogCategory, Image, ImageCategoryIcon, MenuItem } from '../models';

@Injectable()
export class UtilitiesTreeView {
  //#region Instance
  public static instance(elementId: string): TreeView {
    try {
      const element = document.getElementById(elementId);
      const instance = TreeView.getInstance(element) as TreeView;
      return instance;
    }
    catch (e) {
      console.log('UtilitiesTreeView::instance::' + e);
    }
  }


  public static instanceBeginUpdate(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.beginUpdate();
    }
    catch (e) {
      console.log('UtilitiesTreeView::instanceBeginUpdate::' + e);
    }
  }

  public static instanceEndUpdate(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.endUpdate();
    }
    catch (e) {
      console.log('UtilitiesTreeView::instanceEndUpdate::' + e);
    }
  }

  public static instanceCollapseRow(elementId: string, itemData: any) {
    try {
      const instance = this.instance(elementId);
      instance.collapseItem(itemData);
    }
    catch (e) {
      console.log('UtilitiesTreeView::collapseRow::' + e);
    }
  }

  public static instanceCollapseAll(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.collapseAll();
    }
    catch (e) {
      console.log('UtilitiesTreeView::collapseAll::' + e);
    }
  }

  public static instanceExpandRow(elementId: string, itemData: any) {
    try {
      const instance = this.instance(elementId);
      instance.expandItem(itemData);
    }
    catch (e) {
      console.log('UtilitiesTreeView::expandRow::' + e);
    }
  }

  public static instanceExpandAll(elementId: string) {
    try {
      const instance = this.instance(elementId);
      instance.expandAll();
    }
    catch (e) {
      console.log('UtilitiesTreeView::expandAll::' + e);
    }
  }
  //#endregion

  //#region Events

  //#region ContextMenu
  public static treeViewItemContextMenu(contextMenuTree: any, e: any): any {
    const selectedTreeItem = e.node;
    selectedTreeItem.idInstance = e.element.id;
    const isProduct = e.itemData.isProduct;
    const contextMenu = contextMenuTree.instance;
    contextMenu.option('items[0].visible', true);
    contextMenu.option('items[1].visible', true);
    contextMenu.option('items[2].visible', !isProduct);
    contextMenu.option('items[3].visible', !isProduct);
    contextMenu.option('items[4].visible', false);
    contextMenu.option('items[5].visible', false);
    contextMenu.option('items[6].visible', false);
    contextMenu.option('items[7].visible', false);
    contextMenu.option('items[8].visible', true);
    contextMenu.option('items[2].disabled', e.node.expanded);
    contextMenu.option('items[3].disabled', !e.node.expanded);
    return selectedTreeItem;
  }
  //#endregion

  //#region treeview catalogs
  //#region getTreeView
  private static getTreeView(driveName): TreeView {
    return driveName === 'catalog'
      ? this.instance('treeviewCatalog')
      : this.instance('treeviewProduct');
  }
  //#endregion

  //#region treeDragChange
  public static treeDragChange(e) {
    if (e.fromComponent === e.toComponent) {
      const nodes = e.element.querySelectorAll('.dx-treeview-node');
      const isDragIntoChild = nodes[e.fromIndex].querySelectorAll(`[data-item-id="${nodes[e.toIndex].getAttribute('data-item-id')}"]`).length > 0;
      if (isDragIntoChild) {
        e.cancel = true;
      }
    }
  }
  //#endregion

  //#region treeDragEnd
  public static treeDragEnd(e): ProductCatalog[] {
    let dataSource: ProductCatalog[];
    const toTreeView = this.getTreeView(e.toData);
    let items: any[] = toTreeView.getDataSource().items();
    dataSource = [...items];

    if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
      return dataSource;
    }
    const fromTreeView = this.getTreeView(e.fromData);

    const fromNode = this.findTreeNode(fromTreeView, e.fromIndex);
    const toNode = this.findTreeNode(toTreeView, this.calculateTreeToIndex(e));

    if (e.dropInsideItem && toNode !== null && !toNode.itemData.isDirectory) {
      return dataSource;
    }

    const fromTopVisibleItemId = this.getTreeTopVisibleNodeId(e.fromComponent);
    const toTopVisibleItemId = this.getTreeTopVisibleNodeId(e.toComponent);

    const fromItems = fromTreeView.option('items');
    const toItems = toTreeView.option('items');
    this.moveTreeNode(fromNode, toNode, fromItems, toItems, e.dropInsideItem);

    fromTreeView.option('items', fromItems);
    toTreeView.option('items', toItems);
    fromTreeView.scrollToItem(fromTopVisibleItemId);
    toTreeView.scrollToItem(toTopVisibleItemId);

    // this.reload( toTreeView, true );
    items = toTreeView.getDataSource().items();
    dataSource = [...items];

    return dataSource;
  }
  //#endregion

  //#region calculateTreeToIndex
  private static calculateTreeToIndex(e) {
    if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
      return e.toIndex;
    }

    return e.fromIndex >= e.toIndex
      ? e.toIndex
      : e.toIndex + 1;
  }
  //#endregion

  //#region findTreeViewNode
  private static findTreeNode(treeView, index) {
    const nodes = treeView.element().querySelectorAll('.dx-treeview-node');
    if (nodes.length <= index) {
      return null;
    }
    const id = nodes[index].getAttribute('data-item-id');
    return this.findTreeNodeById(treeView.getNodes(), id);
  }
  //#endregion

  //#region findTreeNodeById
  private static findTreeNodeById(nodes: any, id: any) {
    for (const node of nodes) {
      if (node.itemData.id === id) {
        return node;
      }
      else {
        const _node = this.findTreeNodeById(node.children, id);
        if (_node !== null) {
          return _node;
        }
      }
    }
    return null;
  }
  //#endregion

  //#region moveTreeNode
  private static moveTreeNode(fromNode, toNode, fromItems, toItems, isDropInsideItem) {
    const fromNodeContainingArray = this.getTreeNodeContainingArray(fromNode, fromItems);
    const fromIndex = fromNodeContainingArray.findIndex(item => item.id === fromNode.itemData.id);
    if (fromIndex < 0) {
      fromNodeContainingArray.splice(0, 1);
    }
    else {
      fromNodeContainingArray.splice(fromIndex, 1);
    }
    if (isDropInsideItem) {
      if (toNode.itemData.items && toNode.itemData.items !== undefined) {
        toNode.itemData.items.splice(toNode.itemData.items.length, 0, fromNode.itemData);
      }
      else if (toNode.itemData.products && toNode.itemData.products !== undefined) {
        toNode.itemData.products.splice(toNode.itemData.products.length, 0, fromNode.itemData);
      }
      else {
        toNode.itemData.products.splice(toNode.itemData.products.length, 0, fromNode.itemData);
      }
    }
    else {
      const toNodeContainingArray = this.getTreeNodeContainingArray(toNode, toItems);
      const toIndex = toNode === null
        ? toNodeContainingArray.length
        : toNodeContainingArray.findIndex(item => item.id === toNode.itemData.id);
      toNodeContainingArray.splice(toIndex, 0, fromNode.itemData);
    }
  }
  //#endregion

  //#region getTreeNodeContainingArray
  private static getTreeNodeContainingArray(node, rootArray) {
    return node === null || node.parent === null
      ? rootArray
      : ((node.parent.itemData.products == null || (node.parent.itemData.products != null && node.parent.itemData.products.length === 0)) ? node.parent.itemData : node.parent.itemData.products);
  }
  //#endregion

  //#region getTreeTopVisibleNodeId
  private static getTreeTopVisibleNodeId(component) {
    let treeViewElement: any;
    if (component.element && component.element.nativeElement) {
      treeViewElement = component.element.nativeElement;
    }
    else {
      treeViewElement = component.element();
    }
    const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
    const nodes = treeViewElement.querySelectorAll('.dx-treeview-node');
    for (const node of nodes) {
      const nodeTopPosition = node.getBoundingClientRect().top;
      if (nodeTopPosition >= treeViewTopPosition) {
        return node.getAttribute('data-item-id');
      }
    }

    return null;
  }
  //#endregion

  //#region reload Tree
  private static reload(treeInstance: TreeView, reloadDataSource?: boolean) {
    treeInstance.beginUpdate();
    if (reloadDataSource && reloadDataSource !== undefined && reloadDataSource === true) {
      treeInstance.getDataSource().reload();
    }
    treeInstance.updateDimensions();
    treeInstance.repaint();
    treeInstance.endUpdate();
  }
  //#endregion

  //#region getNodeById
  private static getNodeById(fromData: string, id: any): any {
    const nodes = this.getNodesItens(fromData);
    for (const node of nodes) {
      if (node.itemData && node.itemData.id === id) {
        return node;
      }
      else if (nodes.dataset && node.dataset.id === id) {
        return node;
      }
      else {
        const _node = this.findTreeNodeById(node.children, id);
        if (_node !== null) {
          return _node;
        }
      }
    }
    return null;
  }
  //#endregion

  //#region getNodesItens
  private static getNodesItens(fromData: string): any {
    const fromTreeView = this.instance(fromData); // this.getTreeView( fromData );
    return fromTreeView.getNodes();
  }
  //#endregion
  //#endregion

  //#endregion

  //#region getImageCategoryIcon
  private static getImageCategoryIcon(artigo: Artigos, categoryName: string): ImageCategoryIcon {
    let _icon: ImageCategoryIcon = null;
    if (!Utilities.isNullOrUndefined(artigo) && !Utilities.isNullOrUndefined(artigo.icons)) {
      _icon = artigo.icons.find(f => f.categoryName === categoryName);
    }
    return _icon;
  }
  //#endregion

  //#region CreateTree
  ///
  // CreateTree to catalog
  ///
  public static CreateTree(dataArtigos: Artigos[], dataCategories: CatalogCategory[]): ProductCatalog[] {
    const products: ProductCatalog[] = [];
    const list: ProductCatalog[] = [];
    let indexRow = 1;
    let indexLevel1 = 1;
    try {
      for (const marca of new Set(dataArtigos.map(p => p.marca.trim()))) {
        const _marca = dataCategories.find(f => f.name === 'Marca');
        if (_marca && _marca !== undefined) {
          indexLevel1 = indexRow;
          const _iconMarca: ImageCategoryIcon = this.getImageCategoryIcon(dataArtigos.find(f => f.marca.trim() === marca), marca);

          const productMarca: ProductCatalog = this.joinProducCatalog(0, marca, null, null, false, indexRow++, `${indexLevel1}`, `${indexLevel1}`, indexLevel1, `${indexLevel1}`, 'Marca', 'marca', dataCategories, _iconMarca);
          list.push(productMarca);
          let indexLevel2 = 1;
          for (const categoria of new Set(dataArtigos.filter(f => f.marca.trim() === marca).map(p => p.categoria.trim()))) {
            const _categoria = dataCategories.find(f => f.name === 'Categoria');
            if (_categoria && _categoria !== undefined) {
              indexLevel2 = indexRow;
              const _iconCategory: ImageCategoryIcon = this.getImageCategoryIcon(dataArtigos.find(f => f.marca.trim() === marca && f.categoria.trim() === categoria), categoria);

              const productCategoria: ProductCatalog = this.joinProducCatalog(0, categoria, null, `${marca}`, false, indexRow++, `${indexLevel2}`, `${indexLevel1}`, indexLevel2, `${indexLevel1}.${indexLevel2}`, 'Categoria', 'categoria', dataCategories, _iconCategory);
              list.push(productCategoria);
              let indexLevel3 = 1;
              for (const subCategoria of new Set(dataArtigos.filter(f => f.marca.trim() === marca && f.categoria.trim() === categoria).map(p => p.subCategoria.trim()))) {
                const _subCategoria = dataCategories.find(f => f.name === 'SubCategoria');
                if (_subCategoria && _subCategoria !== undefined) {
                  indexLevel3 = indexRow;
                  const _iconSubCategory: ImageCategoryIcon = this.getImageCategoryIcon(dataArtigos.find(f => f.marca.trim() === marca && f.categoria.trim() === categoria && f.subCategoria.trim() === subCategoria), subCategoria.trim());

                  const productSubCategoria: ProductCatalog = this.joinProducCatalog(0, subCategoria, null, `${marca} ${categoria}`, false, indexRow++, `${indexLevel3}`, `${indexLevel2}`, indexLevel3, `${indexLevel1}.${indexLevel2}.${indexLevel3}`, 'SubCategoria', 'subCategoria', dataCategories, _iconSubCategory);
                  list.push(productSubCategoria);
                  let indexLevel4 = 1;
                  for (const variants of new Set(dataArtigos.filter(f => f.marca.trim() === marca && f.categoria.trim() === categoria && f.subCategoria.trim() === subCategoria).map(p => p.variants.trim()))) {
                    const _variants = dataCategories.find(f => f.name === 'Variant');
                    if (_variants && _variants !== undefined) {
                      indexLevel4 = indexRow;
                      const _iconVariants: ImageCategoryIcon = this.getImageCategoryIcon(dataArtigos.find(f => f.marca.trim() === marca && f.categoria.trim() === categoria && f.subCategoria.trim() === subCategoria && f.variants.trim() === variants), variants);

                      const productVariant: ProductCatalog = this.joinProducCatalog(0, variants, null, `${marca} ${categoria} ${subCategoria}`, false, indexRow++, `${indexLevel4}`, `${indexLevel3}`, indexLevel4, `${indexLevel1}.${indexLevel2}.${indexLevel3}.${indexLevel4}`, 'Variant', 'variants', dataCategories, _iconVariants);
                      list.push(productVariant);
                      let indexLevel5 = 1;
                      for (const artigo of new Set(dataArtigos.filter(f => f.marca.trim() === marca && f.categoria.trim() === categoria && f.subCategoria.trim() === subCategoria && f.variants.trim() === variants).map(p => p.artigo.trim()))) {
                        const _artigo = dataCategories.find(f => f.name === 'Artigo');
                        if (_artigo && _artigo !== undefined) {
                          indexLevel5 = indexRow;
                          const _iconArtigo: ImageCategoryIcon = this.getImageCategoryIcon(dataArtigos.find(f => f.marca.trim() === marca && f.categoria.trim() === categoria && f.subCategoria.trim() === subCategoria && f.variants.trim() === variants && f.artigo.trim() === artigo), artigo);

                          let title = `${artigo}`;
                          for (const _title of new Set(dataArtigos.filter(f => f.marca.trim() === marca && f.categoria.trim() === categoria && f.subCategoria.trim() === subCategoria && f.variants.trim() === variants && f.artigo.trim() === artigo).map(p => `${p.artigo.trim()} - ${p.descricao.trim()}`))) {
                            title = _title;
                            break;
                          }
                          const productArtigo: ProductCatalog = this.joinProducCatalog(0, artigo, title, `${marca} ${categoria} ${subCategoria} ${variants}`, true, indexRow++, `${indexLevel5}`, `${indexLevel4}`, indexLevel5, `${indexLevel1}.${indexLevel2}.${indexLevel3}.${indexLevel4}.${indexLevel4}`, 'Artigo', 'sku', dataCategories, _iconArtigo);
                          productVariant.products.push(productArtigo);
                          list.push(productVariant);
                          indexLevel5++;
                        }
                      }
                      productSubCategoria.products.push(productVariant);
                    }
                  }
                  productCategoria.products.push(productSubCategoria);
                }
              }
              productMarca.products.push(productCategoria);
            }
          }
          products.push(productMarca);
        }
      }
    }
    catch (e) {
      console.log('CreateTree' + e);
    }

    return products;
  }
  //#endregion

  //#region joinProducCatalog
  ///
  // joinProductCatalog
  ///
  private static joinProducCatalog(idCatalog: number, name: string, title: string, parentName: string, isErpProduct: boolean, idRow: number, id: string, idParent: string, index: number, indexCatalog: string, category: string, groupName: string, dataCategories: CatalogCategory[], icon?: ImageCategoryIcon): ProductCatalog {
    const idCatalogsCategories = dataCategories.find(f => f.name === category);

    const product: ProductCatalog = new ProductCatalog();
    product.expanded = false;
    product.groupName = groupName;
    product.idCatalog = idCatalog;
    product.id = id;
    product.rowIndex = idRow;
    product.idParent = idParent;
    product.idCatalogsCategories = idCatalogsCategories.id;
    product.index = index;
    product.indexCatalog = indexCatalog;
    product.isDirectory = !isErpProduct;
    product.name = name;
    if (title && title !== undefined) {
      product.title = title;
    }
    else {
      product.title = name;
    }
    if (!Utilities.isNullOrUndefined(parentName)) {
      product.parentName = parentName;
    }
    else {
      product.parentName = product.title;
    }

    product.isProduct = isErpProduct;
    if (isErpProduct) {
      product.erpProduct = name;
    }

    if (!Utilities.isNullOrUndefined(icon)) {
      product.icon = icon.icon;
    }

    product.products = [];

    return product;
  }
  //#endregion

  //#region getFilterProducts
  public static getFilterProducts(rowNode: any): ProductCatalog[] {
    // children
    const filter: ProductCatalog[] = [];
    try {
      if (rowNode && rowNode !== undefined && rowNode.products && rowNode.products !== undefined && rowNode.products.length > 0) {
        for (const p of rowNode.products) {
          const products = this.getFilterParentProducts(p);
          if (products) {
            for (const _p of products) {
              const existe = filter.find(f => f.name === _p.name && f.idCatalogsCategories === _p.idCatalogsCategories);
              if (Utilities.isNullOrUndefined(existe)) {
                filter.push(_p);
              }
              else {
                console.log('getFilterProducts::existe::' + existe.name + ' ' + existe.idCatalogsCategories);
              }
            }
          }
        }
      }
      else if (rowNode && rowNode !== undefined) {
        filter.push(rowNode);
      }
    }
    catch (e) {
      console.log('getFilterProducts::' + e);
    }
    return filter;
  }

  private static getFilterParentProducts(product: ProductCatalog): ProductCatalog[] {
    const row: ProductCatalog[] = [];
    if (!product.isProduct && product.products && product.products !== undefined && product.products.length > 0) {
      for (const p of product.products) {
        try {
          const products = this.getFilterParentProducts(p);
          if (products) {
            for (const _p of products) {
              row.push(_p);
            }
          }
        }
        catch (e) { }
      }
    }
    else {
      row.push(product);
    }
    return row;
  }
  //#endregion

  //#region addNewTreeNode
  public static addNewTreeNode(treeName: string, id: number, level: ProductCatalog, parent: ProductCatalog, dataSource: ProductCatalog[], defaultImage: Image): [ProductCatalog[], number] {
    const product: ProductCatalog = new ProductCatalog();
    const lastId: number = id + 1;

    try {
      const treeInstance = this.instance(treeName);
      const items = treeInstance.getDataSource().items();
      dataSource = [...items];

      Object.assign(product, level);
      product.id = lastId.toString();
      product.maxIndex = lastId;
      product.rowIndex = lastId;
      product.index = lastId;

      if (Utilities.isNullOrUndefined(product.icon) && defaultImage) {
        product.idImage = defaultImage.idImage;
        product.icon = defaultImage.imgPath;
      }

      if (parent) {
        product.idParent = parent.id;
        const toNode = this.getNodeById(treeName, parent.id);
        if (toNode) {
          toNode.itemData.products.splice(toNode.itemData.products.length, 0, product);
        }
      }
      else {
        dataSource.push(product);
        items.push(product);
      }
      this.reload(treeInstance, true);

      dataSource = [...items];
    }
    catch (e) {
      console.log('addNewTreeNode::' + e);
    }
    return [dataSource, lastId];
  }
  //#endregion

  //#region removeTreeNode
  public static removeTreeNode(treeName: string, parent: ProductCatalog, dataSource: ProductCatalog[]): ProductCatalog[] {
    const product: ProductCatalog = new ProductCatalog();

    try {
      const treeInstance = this.instance(treeName);
      const items = treeInstance.getDataSource().items();
      dataSource = [...items];

      if (parent) {
        product.idParent = parent.id;
        const toNode = this.getNodeById(treeName, parent.id);
        if (toNode) {
          if (toNode.parent) {
            const index = (toNode.parent.itemData.products as any[]).findIndex(f => f.id === toNode.itemData.id);
            if (index > -1) {
              (toNode.parent.itemData.products as any[]).splice(index, 1);
            }
          }
          else {
            const index = items.findIndex(f => f.id === toNode.itemData.id);
            if (index > -1) {
              items.splice(index, 1);
            }
          }
        }
      }
      this.reload(treeInstance, true);

      dataSource = [...items];
    }
    catch (e) {
      console.log('removeTreeNode::' + e);
    }
    return dataSource;
  }
  //#endregion

  //#region MenuItemCatalog
  ///
  // Menu
  ///
  public static MenuCatalog(gT: any): MenuItem[] {
    return [
      { id: 'addlevel', text: gT('catalog.contextmenu.AddLevel'), icon: 'icon dx-icon-add' },
      { id: 'removelevel', text: gT('catalog.contextmenu.RemoveLevel'), icon: 'icon dx-icon-remove' },
      { id: 'expand', text: gT('catalog.contextmenu.Expand'), icon: 'icon dx-icon-expand' },
      { id: 'collapse', text: gT('catalog.contextmenu.Collapse'), icon: 'icon dx-icon-collapse' },
      { id: 'expandall', text: gT('catalog.contextmenu.ExpandAll'), icon: 'icon dx-icon-expand' },
      { id: 'collapseall', text: gT('catalog.contextmenu.CollapseAll'), icon: 'icon dx-icon-collapse' },
      { id: 'move', text: gT('catalog.contextmenu.DraggedDropped'), icon: 'icon dx-icon-movetofolder' },
      { id: 'delete', text: gT('catalog.contextmenu.DeleteImage'), icon: 'icon dx-icon-remove' },
      { id: 'image', text: gT('catalog.contextmenu.Image'), icon: 'icon dx-icon-image' }
    ];
  }
  //#endregion
}
