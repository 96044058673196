import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LogPortal } from '../../models';
import { AuthService, ConfigurationService, ApiService, ApiBaseService } from '..';

@Injectable()
export class LogService extends ApiBaseService {

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService, private apiService: ApiService) {
    super(configurations, http, authService);
  }

  //#region get
  getLog(id: number): Observable<LogPortal> {
    return this.getById<LogPortal>(this.controllerApi, id);
  }
  //#endregion

  newLog(entity: any): Observable<LogPortal> {
    return this.post<LogPortal>(this.controllerApi, entity);
  }

  addLog(code: string, from: string, entityData?: any, entityError?: any): Observable<LogPortal> {

    const entity: LogPortal = { IdCompany: this.userAtualErpPermissions.companyId, codeRequest: code, endPoint: from, isError: true };
    if (entityData) {
      entity.data = JSON.stringify(entityData);
    }
    if (entityError) {
      entity.dataError = JSON.stringify(entityError);
    }
    return this.post<LogPortal>(this.controllerApi, entity);
  }

  updateLog(entity: any): Observable<LogPortal> {
    return this.put<LogPortal>(this.controllerApi, entity);
  }

  deleteLog(id: any): Observable<LogPortal> {
    return this.delete<LogPortal>(this.controllerApi, id);
  }
}
