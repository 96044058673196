export class LogPortal {
  idLog?: number;
  IdCompany?: number;
  codeRequest?: string;
  endPoint?: string;

  data?: string;
  dataError?: string;
  isError?: boolean;
}
