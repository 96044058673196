export class PurchaseStatus
{
  constructor( orderType?: number, status?: string, description?: string )
  {
    this.orderType = orderType;
    this.status = status;
    this.description = description;
    this.visible = true;
  }

  orderType?: number;
  status?: string;
  description?: string;
  isDefault?: boolean;
  visible?: boolean;
}
