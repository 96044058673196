<nb-layout windowMode>
  <nb-layout-header fixed responsive>
    <app-header></app-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>

  <nb-layout-column responsive>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>

  <nb-layout-footer fixed>
    <app-footer></app-footer>
  </nb-layout-footer>
</nb-layout>
