import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { AuthService } from '../auth';
import { Utilities } from '../../utils/utilities';

@Injectable()
export class PageMenu {

  constructor(private authService: AuthService) { }

  getMenu(): Observable<NbMenuItem[]> {
    const menu: NbMenuItem[] = [
      {
        title: 'Home',
        icon: 'shopping-cart-outline',
        link: '/pages/home',
        home: true,
      },
      {
        title: 'Profile',
        icon: 'home-outline',
        link: '/pages/profile',
        hidden: this.isHidden('users.view'),
      },
      {
        title: 'FEATURES',
        group: true,
      },
      {
        title: 'Tasks',
        icon: 'edit-2-outline',
        link: '/pages/tasks',
      },
      {
        title: 'FEATURES12',
        group: true,
      },
      {
        title: 'Profile2',
        icon: 'shopping-cart-outline',
        link: '/pages/users/preference',
        expanded: false,
        children: [
          {
            title: 'Preferences',
            link: '/pages/users/preference',
            hidden: this.isHidden('users.view')
          },
          {
            title: 'Users',
            link: '/pages/users/info',
            hidden: this.isHidden('users.view')
          }
        ],
      },
    ];

    return of([...menu]);
  }

  isHidden(name?: string): boolean {
    if (Utilities.isNullOrUndefined(name)) {
      return true;
    }
    else {
      return !this.authService.userPermissions.some(p => p == name);
    }
  }
}
