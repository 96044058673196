import { Directive, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appValidateNif]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NifValidator, multi: true }
  ]
})
export class NifValidator implements Validator {
  constructor(@Attribute('appValidateNif') public validateNif: string, @Attribute('country') public country: string) {
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (!this.country) {
      return null;
    }
    return this.validaContribuinte(this.country, c.value) ? null : { validateNif: true };
  }

  //#region nif
  private validaContribuinte(pais: string, contribuinte: string): boolean {
    let result = false;
    // algoritmo de validação do NIF de acordo com
    // http://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal

    if (pais === 'PT') {
      let temErro = 0;
      if (
        contribuinte.substr(0, 1) !== '1' && // pessoa singular
        contribuinte.substr(0, 1) !== '2' && // pessoa singular
        contribuinte.substr(0, 1) !== '3' && // pessoa singular
        contribuinte.substr(0, 2) !== '45' && // pessoa singular não residente
        contribuinte.substr(0, 1) !== '5' && // pessoa colectiva
        contribuinte.substr(0, 1) !== '6' && // administração pública
        contribuinte.substr(0, 2) !== '70' && // herança indivisa
        contribuinte.substr(0, 2) !== '71' && // pessoa colectiva não residente
        contribuinte.substr(0, 2) !== '72' && // fundos de investimento
        contribuinte.substr(0, 2) !== '77' && // atribuição oficiosa
        contribuinte.substr(0, 2) !== '79' && // regime excepcional
        contribuinte.substr(0, 1) !== '8' && // empresário em nome individual (extinto)
        contribuinte.substr(0, 2) !== '90' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) !== '91' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) !== '98' && // não residentes
        contribuinte.substr(0, 2) !== '99' // sociedades civis
      ) { temErro = 1; }

      const check1 = Number.parseFloat(contribuinte.substr(0, 1)) * 9;
      const check2 = Number.parseFloat(contribuinte.substr(1, 1)) * 8;
      const check3 = Number.parseFloat(contribuinte.substr(2, 1)) * 7;
      const check4 = Number.parseFloat(contribuinte.substr(3, 1)) * 6;
      const check5 = Number.parseFloat(contribuinte.substr(4, 1)) * 5;
      const check6 = Number.parseFloat(contribuinte.substr(5, 1)) * 4;
      const check7 = Number.parseFloat(contribuinte.substr(6, 1)) * 3;
      const check8 = Number.parseFloat(contribuinte.substr(7, 1)) * 2;

      const total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
      const divisao = total / 11;
      const modulo11 = total - Number.parseInt(divisao.toString(), null) * 11;
      let comparador = 0;
      if (modulo11 === 1 || modulo11 === 0) {
        comparador = 0;
      } else {
        comparador = 11 - modulo11;
      }


      const ultimoDigito = Number.parseInt(contribuinte.substr(8, 1), null) * 1;
      if (ultimoDigito !== comparador) { temErro = 1; }

      if (temErro === 1) {
        result = false;
      } else {
        result = true;
      }
    } else {
      return true;
    }
    return result;
  }
  //#endregion
}
