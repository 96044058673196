export * from './area-atividade.model';
export * from './auditable-entity.model';
export * from './attachament.model';
export * from './cargo.model';
export * from './classificao.model';
export * from './comunicacao-marketing.model';
export * from './cond-pagamento.model';
export * from './country.model';
export * from './modo-pagamento.model';
export * from './relacao-pep.model';
export * from './situacao-prof.model';
export * from './tipos-id.model';
export * from './pep.model';
export * from './motivos-estado-submetido.model';
export * from './tipos-doc-attach.model';
export * from './email.model';
export * from './portal-config.model';
