import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Observable, Subject, of, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStoreManager,  ConfigurationService, DBkeys, JwtHelper, OidcHelperService } from '../conf';
import { Utilities } from '../../utils/utilities';
import { AccessToken, LoginResponse } from '../../models/login';
import { PermissionValues } from '../../models/role';
import { User, UserErpPermission } from '../../models/user';
import { UserCompanies } from '../../models';

const defaultPath = '/';

@Injectable()
export class AuthService {
  public get loginUrl() { return this.configurations.loginUrl; }
  public get homeUrl() { return this.configurations.homeUrl; }

  public loginRedirectUrl: string;
  public logoutRedirectUrl: string;

  public reLoginDelegate: () => void;

  private previousIsLoggedInCheck = false;
  private loginStatus = new Subject<boolean>();

  private loginChangedSubject = new Subject<boolean>();
  loginChanged = this.loginChangedSubject.asObservable();

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(
    private router: Router,
    private oidcHelperService: OidcHelperService,
    private configurations: ConfigurationService,
    private localStorage: LocalStoreManager) {

    this.initializeLoginStatus();
  }

  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }

  gotoPage(page: string, preserveParams = true) {

    const navigationExtras: NavigationExtras = {
      queryParamsHandling: preserveParams ? 'merge' : '', preserveFragment: preserveParams
    };

    this.router.navigate([page], navigationExtras);
  }

  gotoHomePage() {
    this.router.navigate([this.homeUrl]);
  }

  redirectLoginUser() {
    const redirect = this.loginRedirectUrl && this.loginRedirectUrl !== '/' && this.loginRedirectUrl !== ConfigurationService.defaultHomeUrl ? this.loginRedirectUrl : this.homeUrl;
    this.loginRedirectUrl = null;

    const urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
    const urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, '?');

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: 'merge'
    };

    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }

  redirectLogoutUser() {
    const redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : this.loginUrl;
    this.logoutRedirectUrl = null;

    this.router.navigate([redirect]);
  }

  redirectForLogin() {
    this.loginRedirectUrl = this.router.url;
    this.router.navigate([this.loginUrl]);
  }

  reLogin() {
    if (this.reLoginDelegate) {
      this.reLoginDelegate();
    } else {
      this.redirectForLogin();
    }
  }

  refreshLogin() {
    return this.oidcHelperService.refreshLogin()
      .pipe(map(resp => this.processLoginResponse(resp, this.rememberMe)));
  }

  loginWithPassword(userName: string, password: string, rememberMe?: boolean): Observable<User> {
    if (this.isLoggedIn) {
      this.logout();
    }
    return this.oidcHelperService.loginWithPassword(userName, password)
      .pipe(map(resp => this.processLoginResponse(resp, rememberMe)));
  }

  async logIn(email: string, password: string) {

    try {
      // Send request
      // console.log(email, password);
      const _user = await lastValueFrom(this.loginWithPassword(email, password, false));
      //const _user = await this.loginWithPassword(email, password, false).toPromise();
      // this._user = { ...defaultUser, email };
      if (this._lastAuthenticatedPath === 'logout') {
        this.lastAuthenticatedPath = '/';
      }

      this.router.navigate([this._lastAuthenticatedPath]);

      return {
        isOk: true,
        data: _user
      };
    } catch {
      return {
        isOk: false,
        message: 'Authentication failed'
      };
    }
  }

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      this.router.navigate(['/create-account']);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: 'Failed to create account'
      };
    }
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: 'Failed to change password'
      };
    }
  }

  async resetPassword(email: string) {
    try {
      // Send request
      // console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: 'Failed to reset password'
      };
    }
  }

  async logOut() {
    this.logout();
    this.router.navigate(['/login-form']);
  }

  private processLoginResponse(response: LoginResponse, rememberMe?: boolean) {
    const accessToken = response.access_token;

    if (accessToken == null) {
      throw new Error('accessToken cannot be null');
    }

    rememberMe = rememberMe || this.rememberMe;

    const refreshToken = response.refresh_token || this.refreshToken;
    const expiresIn = response.expires_in;
    const tokenExpiryDate = new Date();
    // tokenExpiryDate.setUTCMinutes(1);
    tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);
    const accessTokenExpiry = tokenExpiryDate;
    const jwtHelper = new JwtHelper();
    const decodedAccessToken = jwtHelper.decodeToken(accessToken) as AccessToken;

    const permissions: PermissionValues[] = Array.isArray(decodedAccessToken.permission) ? decodedAccessToken.permission : [decodedAccessToken.permission];

    if (!this.isLoggedIn) {
      this.configurations.import(decodedAccessToken.configuration);
    }

    const companies: UserCompanies[] = (decodedAccessToken.companies ? (Array.isArray(decodedAccessToken.companies) ? decodedAccessToken.companies : JSON.parse(decodedAccessToken.companies)) : []);
    const userErpPermission: UserErpPermission[] = (decodedAccessToken.userErpPermission ? (Array.isArray(decodedAccessToken.userErpPermission) ? decodedAccessToken.userErpPermission : JSON.parse(decodedAccessToken.userErpPermission)) : []);

    const user = new User(
      decodedAccessToken.sub,
      decodedAccessToken.name,
      decodedAccessToken.fullname,
      decodedAccessToken.email,
      decodedAccessToken.jobtitle,
      decodedAccessToken.phone_number,
      Array.isArray(decodedAccessToken.role) ? decodedAccessToken.role : [decodedAccessToken.role], companies, userErpPermission);
    user.isEnabled = true;

    this.saveUserDetails(user, permissions, accessToken, refreshToken, accessTokenExpiry, rememberMe, userErpPermission);

    this.reevaluateLoginStatus(user);

    return user;
  }

  private saveUserDetails(user: User, permissions: PermissionValues[], accessToken: string, refreshToken: string, expiresIn: Date, rememberMe: boolean, userErpPermission: UserErpPermission[]) {
    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.savePermanentData(refreshToken, DBkeys.REFRESH_TOKEN);
      this.localStorage.savePermanentData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
      this.localStorage.savePermanentData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.savePermanentData(user, DBkeys.CURRENT_USER);
      this.localStorage.savePermanentData(userErpPermission, DBkeys.USER_ERP_PERMISSIONS);

      if (userErpPermission && userErpPermission.length > 0) {
        this.localStorage.savePermanentData(userErpPermission[0], DBkeys.USER_ATUAL_ERP_PERMISSION);
      }
    } else {
      this.localStorage.saveSyncedSessionData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(refreshToken, DBkeys.REFRESH_TOKEN);
      this.localStorage.saveSyncedSessionData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
      this.localStorage.saveSyncedSessionData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
      this.localStorage.saveSyncedSessionData(userErpPermission, DBkeys.USER_ERP_PERMISSIONS);
      if (userErpPermission && userErpPermission.length > 0) {
        this.localStorage.saveSyncedSessionData(userErpPermission[0], DBkeys.USER_ATUAL_ERP_PERMISSION);
      }
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
  }

  logout(): void {

    this.localStorage.deleteData(DBkeys.ACCESS_TOKEN);
    this.localStorage.deleteData(DBkeys.REFRESH_TOKEN);
    this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);
    this.localStorage.deleteData(DBkeys.USER_PERMISSIONS);
    this.localStorage.deleteData(DBkeys.CURRENT_USER);
    this.localStorage.deleteData(DBkeys.LAST_PURCHASE);
    this.localStorage.deleteData(DBkeys.LAST_MANAGER_PURCHASES);

    this.localStorage.deleteData(DBkeys.ORDER_ID);
    this.localStorage.deleteData(DBkeys.ORDER_ITEM_COUNT);

    this.configurations.clearLocalChanges();

    this.reevaluateLoginStatus();
  }

  private reevaluateLoginStatus(currentUser?: User) {
    const user = currentUser || this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    const isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck !== isLoggedIn) {
      setTimeout(() => {
        this.loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this.loginStatus.asObservable();
  }

  // isLoggedIn2(): Promise<Boolean> {
  //  return this.currentUsers()
  //    .then(user => {
  //      const userCurrent = !!user && !user.expired; //if not NULL and not Expired

  //      if (this._user != user) {
  //        this.loginChangedSubject.next(userCurrent);
  //      }

  //      this._user = user;
  //      return userCurrent;
  //    });
  // }

  // private currentUsers(): Promise<User> {
  //  const user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
  //  this.reevaluateLoginStatus(user);

  //  return user;
  // }

  onTokenChange(): Observable<string> {
    return of(this.oidcHelperService.accessToken);
  }

  onAuthenticationChange(): Observable<boolean> {
    return this.onTokenChange()
      .pipe(map((m) => !Utilities.isNullOrUndefined(m)));
  }


  get currentUser(): User {

    const user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    this.reevaluateLoginStatus(user);

    return user;
  }

  async getUser() {
    try {
      // Send request
      const _user = this.currentUser;
      return {
        isOk: true,
        data: _user
      };
    } catch {
      return {
        isOk: false
      };
    }
  }

  get currentLanguage(): string {
    const language = this.localStorage.getDataObject<string>(DBkeys.LANGUAGE);
    return language;
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    const _permission = this.userPermissions;
    return _permission.some(p => p === permissionValue);
  }

  get userPermissions(): PermissionValues[] {
    return this.localStorage.getDataObject<PermissionValues[]>(DBkeys.USER_PERMISSIONS) || [];
  }

  get userErpPermissions(): UserErpPermission[] {
    return this.localStorage.getDataObject<UserErpPermission[]>(DBkeys.USER_ERP_PERMISSIONS) || [];
  }

  get userAtualErpPermission(): UserErpPermission {
    return this.localStorage.getDataObject<UserErpPermission>(DBkeys.USER_ATUAL_ERP_PERMISSION) || null;
  }

  set userAtualErpPermission(use: UserErpPermission) {
    if (this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME)) {
      this.localStorage.savePermanentData(use, DBkeys.USER_ATUAL_ERP_PERMISSION);
    } else {
      this.localStorage.saveSyncedSessionData(use, DBkeys.USER_ATUAL_ERP_PERMISSION);
    }
  }

  get accessToken(): string {
    return this.oidcHelperService.accessToken;
  }

  get accessTokenExpiryDate(): Date {
    return this.oidcHelperService.accessTokenExpiryDate;
  }

  get refreshToken(): string {
    return this.oidcHelperService.refreshToken;
  }

  get isSessionExpired(): boolean {
    return this.oidcHelperService.isSessionExpired;
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME) === true;
  }
}
