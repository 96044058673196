import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/services';
import { getDeepFromObject } from '../../../@core/utils';


@Component({
  selector: 'app-logout',
  styleUrls: ['./logout.component.scss'],
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  redirectDelay: number = 0;
  strategy: string = '';

  options: any = {
    forms: {
      logout: {
        redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
        strategy: 'email',  // provider id key. If you have multiple strategies, or what to use your own
        rememberMe: false,   // whether to show or not the `rememberMe` checkbox
        showMessages: {     // show/not show success/error messages
          success: true,
          error: true,
        }
      }
    },
  };

  constructor(protected service: AuthService, protected router: Router, private changeDedectionRef: ChangeDetectorRef) {
    this.redirectDelay = this.getConfigValue('forms.logout.redirectDelay');
    this.strategy = this.getConfigValue('forms.logout.strategy');
  }

  ngOnInit(): void {
    this.logout();
  }

  logout(): void {
    this.service.logout();
    this.router.navigate(['/auth/login']);
    this.changeDedectionRef.detectChanges();

    // this.service.logout(strategy).subscribe((result: NbAuthResult) => {
    //  const redirect = result.getRedirect();
    //  if (redirect) {
    //    setTimeout(() => {
    //      return this.router.navigateByUrl(redirect);
    //    }, this.redirectDelay);
    //  }
    // });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
