export type PermissionNames =
  'View Users' | 'Manage Users' | 'View User Preferences' | 'Manage User' |
  'View Roles' | 'Manage Roles' | 'Assign Roles' |
  'View Companies' | 'Manage Companies' | 'Assign Companies' |
  'View Purchases' | 'Manage Purchases' | 'Assign Purchases' | 'Cancel Purchases' | 'Admin Purchases' | 'Admin Does Not Save Purchases' | 'View Purchases Product Catalog' |
  'View Purchases Draft' | 'Manage Purchases Draft' | 'Assign Purchases Draft' | 'Delete Purchases Draft' | 'Admin Purchases Draft' | 'Admin Purchases Draft N-1' | 'Admin Does Not Save Purchases Draft' | 'Admin Does Not Save Purchases Draft N-1' | 'View Purchases Draft Product Catalog' |
  'View AppPortal' | 'Manage AppPortal' | 'Assign AppPortal' |
  'View Product Catalog' |
  'View Audits' | 'Manage Audits' | 'Assign Audits';

export type PermissionValues =
  'users.view' | 'users.manage' | 'user.preferences' | 'user.manage' |
  'roles.view' | 'roles.manage' | 'roles.assign' |
  'companies.view' | 'companies.manage' | 'companies.assign' |
  'purchases.view' | 'purchases.manage' | 'purchases.assign' | 'purchases.cancel' | 'purchases.admin' | 'purchases.save.admin' | 'purchases.product.catalogs.view' |
  'purchases.draft.view' | 'purchases.draft.manage' | 'purchases.draft.assign' | 'purchases.draft.delete' | 'purchases.draft.admin' | 'purchases.draft.n.admin' | 'purchases.save.draft.admin' | 'purchases.save.draft.n.admin' | 'purchases.draft.product.catalogs.view' |
  'app.portal.view' | 'app.portal.manage' | 'app.portal.assign' |
  'product.catalogs.view' |
  'audits.view' | 'audits.manage' | 'audits.assign';

export class Permission {
  public static readonly viewUsersPermission: PermissionValues = 'users.view';
  public static readonly manageUsersPermission: PermissionValues = 'users.manage';
  public static readonly viewUserPreferencesPermission: PermissionValues = 'user.preferences';
  public static readonly manageUserPermission: PermissionValues = 'user.manage';

  public static readonly viewRolesPermission: PermissionValues = 'roles.view';
  public static readonly manageRolesPermission: PermissionValues = 'roles.manage';
  public static readonly assignRolesPermission: PermissionValues = 'roles.assign';

  public static readonly viewCompaniesPermission: PermissionValues = 'companies.view';
  public static readonly manageCompaniesPermission: PermissionValues = 'companies.manage';
  public static readonly assignCompaniesPermission: PermissionValues = 'companies.assign';
  
  //#region Purchases
  public static readonly viewPurchasesPermission: PermissionValues = 'purchases.view';
  public static readonly managePurchasesPermission: PermissionValues = 'purchases.manage';
  public static readonly assignPurchasesPermission: PermissionValues = 'purchases.assign';
  public static readonly cancePurchasesPermission: PermissionValues = 'purchases.cancel';
  public static readonly adminPurchasesPermission: PermissionValues = 'purchases.admin';
  public static readonly adminSavePurchasesPermission: PermissionValues = 'purchases.save.admin';
  public static readonly viewPurchasesProductCatalogPermission: PermissionValues = 'purchases.product.catalogs.view';

  public static readonly viewPurchasesDraftPermission: PermissionValues = 'purchases.draft.view';
  public static readonly managePurchasesDraftPermission: PermissionValues = 'purchases.draft.manage';
  public static readonly assignPurchasesDraftPermission: PermissionValues = 'purchases.draft.assign';
  public static readonly deletePurchasesDraftPermission: PermissionValues = 'purchases.draft.delete';
  public static readonly adminPurchasesDraftPermission: PermissionValues = 'purchases.draft.admin';
  public static readonly adminPurchasesDraftNPermission: PermissionValues = 'purchases.draft.n.admin';
  public static readonly adminSavePurchasesDraftPermission: PermissionValues = 'purchases.save.draft.admin';
  public static readonly adminSavePurchasesDraftNPermission: PermissionValues = 'purchases.save.draft.n.admin';
  public static readonly viewPurchasesDraftProductCatalogPermission: PermissionValues = 'purchases.draft.product.catalogs.view';
  //#endregion
 
  public static readonly viewAppPortalPermission: PermissionValues = 'app.portal.view';
  public static readonly manageAppPortalPermission: PermissionValues = 'app.portal.manage';
  public static readonly assignAppPortalPermission: PermissionValues = 'app.portal.assign';
 
  public static readonly viewProductCatalogPermission: PermissionValues = 'product.catalogs.view';

  //#region Audits
  public static readonly viewAuditsPermission: PermissionValues = 'audits.view';
  public static readonly manageAuditsPermission: PermissionValues = 'audits.manage';
  public static readonly assignAuditsPermission: PermissionValues = 'audits.assign';
  //#endregion

  constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
    this.name = name;
    this.value = value;
    this.groupName = groupName;
    this.description = description;
  }

  public name?: PermissionNames;
  public value?: PermissionValues;
  public groupName?: string;
  public description?: string;
}
