export class PurchasesType
{
  id: number;
  idCompany: number;
  title: string;
  name: string;
  visible: boolean;
  type: number;
  isReturn: boolean;
  isDraft: boolean;
  isApproval: boolean;
  isInternal: boolean;
  codeErp: number;
}
