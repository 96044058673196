import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { Entidade, MoradasAlternativa } from '../../models';
import { AuthService, ConfigurationService, ApiService, ApiBaseService } from '..';

@Injectable()
export class ClientService extends ApiBaseService {

  private readonly apiEndPointClients: string = 'clients';
  private readonly apiEndpointMoradas: string = 'clientAddress';

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService, private apiService: ApiService) {
    super(configurations, http, authService);
  }


  //#region get
  getClients(): Observable<Entidade[]> {
    return this.apiService.getList<Entidade[]>(this.apiEndPointClients);
  }

  getClientMoradasByCompanyId(id: any): Observable<[Entidade, MoradasAlternativa[]]> {
    return forkJoin(
      this.apiService.getByIdCompanyId<Entidade>(id, this.apiEndPointClients),
      this.apiService.getListByIdCompanyId<MoradasAlternativa[]>(id, this.apiEndpointMoradas)
    );
  }
  //#endregion

  newClient(entity: any): Observable<Entidade> {
    return this.apiService.newEntity < Entidade>(entity, this.apiEndPointClients);
  }

  updateClient(entity: any): Observable<Entidade> {
    return this.apiService.updateEntity<Entidade>(entity, this.apiEndPointClients);
  }

  deleteClient(id: any): Observable<Entidade> {
    return this.apiService.deleteEntity<Entidade>(id, this.apiEndPointClients);
  }
}
