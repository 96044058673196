import { Role } from '../role/role.model';

export class Company {
  constructor(id?: number, name?: string, description?: string) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  public id?: number;
  public name?: string;
  public description?: string;
  public erpCode?: string;
  public erpPlataform?: number;
  public erpUserName?: string;
  public erpPassword?: string;
  public erpToken?: string;
  public erpUrlApi?: string;
  public erpName?: string;
  public isEnabled?: boolean;

  public logoImg?: string;
  public erpJson?: any;
  public erpReportJson?: any;
  public language?: string;
  public fields?: any;
  public roles?: Role[];
}
