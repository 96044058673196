import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { AuthService } from '../services';

const defaultPath = '/';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const url: string = state.url;
    return this.checkLogin(url, route);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {

    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  checkLogin(url: string, route?: ActivatedRouteSnapshot): boolean {
    let isAuthForm = false;

    const isLoggedIn = this.authService.isLoggedIn;
    if (route) {
      isAuthForm = [
        'login-form',
        'reset-password',
        'create-account',
        'change-password/:recoveryCode'
      ].includes(route.routeConfig.path);
    }

    const isExp = this.authService.isSessionExpired;
    if (isExp) {
     // this.router.navigate(['auth/login']);
    }

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['auth/login']);
    }

    if (isLoggedIn && route) {
      this.authService.lastAuthenticatedPath = route.routeConfig.path;
    } else if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = url;
    }

    if (route && route.data && route.data.roles && Array.isArray(route.data.roles)) {

      let canView = false;
      for (const _role of route.data.roles) {
        if (this.authService.userHasPermission(_role)) {
          canView = true;
        }
      }
      if (!canView) {
        // return canView;
      }
    }

    return isLoggedIn || isAuthForm;

    // if (this.authService.isLoggedIn) {
    //  return true;
    // }

    // this.authService.loginRedirectUrl = url;
    // this.router.navigate(['/login']);

    // return false;
  }
}
