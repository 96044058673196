export class Documents
{
  constructor( tipoDoc?: string, descricao?: string, serie?: string, defaultUnit?:string )
  {
    this.tipoDoc = tipoDoc;
    this.serie = serie;
    this.descricao = descricao;
    this.defaultUnit = defaultUnit;
  }

  tipoDoc: string;
  descricao: string;
  serie: string;
  dataInicial: Date;
  dataFinal: Date;
  seriePorDefeito: boolean;
  defaultUnit: string = "UN";
  showObs: boolean;
  visible: boolean;
  nameOrderType: string;
  approval: boolean;
  codePortal: number;
}
