import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { EndpointBase, ConfigurationService } from '../';
import { Utilities } from '../../utils/utilities';

@Injectable()
export class SaleEndpoint extends EndpointBase
{

  constructor( private configurations: ConfigurationService, http: HttpClient, authService: AuthService )
  {
    super( http, authService );
  }

  //#region Sales
  //#region Get
  getSaleByFiltersEndpoint<T>( id: any, companyId: number, salesman: string, orderType: number, internalOrderType: number, estado: string, level2: string ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/sale/${id}/${companyId}/${salesman}/${orderType}/${internalOrderType}/${estado}/${level2}`;

    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getSaleByFiltersEndpoint( id, companyId, salesman, orderType, internalOrderType, level2, estado ) );
      } ) );
  }

  getSalesByFiltersEndpoint<T>( companyId: number, salesman: string, orderType: number, estado: string, level2: string, dateFrom?: Date, dateTo?: Date ): Observable<T>
  {
    let endpointUrl = `${this.configurations.baseUrl}/api/order/sales/${companyId}/${salesman}/${orderType}/${estado}/${level2}`;

    if ( !Utilities.isNullOrUndefined( dateFrom ) || !Utilities.isNullOrUndefined( dateTo ) )
    {
      endpointUrl += `/${!Utilities.isNullOrUndefined( dateFrom ) ? Utilities.DateToJson( dateFrom ) : ' ' }`;
      endpointUrl += `${!Utilities.isNullOrUndefined( dateTo ) ? '/' + Utilities.DateToJson( dateTo ) : ' ' }`;
    }

    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getSalesByFiltersEndpoint( companyId, salesman, orderType, estado, level2, dateFrom, dateTo ) );
      } ) );
  }

  getTopSalesEndpoint<T>( idCompany: number, tipoDoc: string, serie: string, client: string ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/salesTop/${idCompany}/${tipoDoc}/${serie}/${client}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getTopSalesEndpoint( idCompany, tipoDoc, serie, client ) );
      } ) );
  }

  getReportEndpoint<T>(idCompany: number, orderType: number, id: any, title: string, icon: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/report/${idCompany}/${orderType}/${id}/${title}/${icon}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getReportEndpoint(idCompany, orderType, id, title, icon));
      }));
  }

  setReportEndpoint<T>( idCompany: number, reportObject: any  ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/report/${idCompany}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( reportObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setReportEndpoint( idCompany, reportObject ) );
      } ) );
  }
  //#endregion

  //#region Patch
  setSaleUpdateEndpoint<T>( idCompany: number, orderType: number,  salesObject: any ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/sale/${idCompany}/${orderType}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSaleUpdateEndpoint( idCompany, orderType, salesObject ) );
      } ) );
  }

  setSalesUpdateEndpoint<T>( idCompany: number, orderType: number, salesObject: any[] ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/sales/${idCompany}/${orderType}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSaleUpdateEndpoint( idCompany, orderType, salesObject ) );
      } ) );
  }

  setSalePrintEndpoint<T>( idCompany: number, orderType: number, salesObject: any ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/salePrint/${idCompany}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSalePrintEndpoint( idCompany, orderType, salesObject ) );
      } ) );
  }
  //#endregion

  //#endregion

  //#region Get
  getGenericEndpoint<T>( endPoint: string, id: number ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/${endPoint}/${id}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getGenericEndpoint( endPoint, id ) );
      } ) );
  }

  getGenericsEndpoint<T>( endPoint: string, page?: number, pageSize?: number ): Observable<T>
  {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/order/${endPoint}/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/order/${endPoint}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getGenericsEndpoint( endPoint, page, pageSize ) );
      } ) );
  }

  getByCompanydIdGenericEndpoint<T>( endPoint: string, companyId: number, page?: number, pageSize?: number ): Observable<T>
  {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/order/${endPoint}/${companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/order/${endPoint}/${companyId}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getByCompanydIdGenericEndpoint( endPoint, companyId, page, pageSize ) );
      } ) );
  }


  //#endregion

  //#region Cancel Sales
  getCancelSalesEndpoint<T>(idCompany: number ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/cancelSales/${idCompany}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getCancelSalesEndpoint( idCompany ) );
      } ) );
  }

  setSaleUpdateCancelEndpoint<T>(idCompany: number, salesObject: any ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/saleCancel/${idCompany}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSaleUpdateCancelEndpoint( idCompany, salesObject ) );
      } ) );
  }
  //#endregion

  //#region Moradas
  getMoradaAlternativaByEntidadeVendedorEndpoint<T>(idCompany: number, entidade: string, vendedor?: string ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/moradaalternativa/${idCompany}/${entidade}` + ( vendedor ? `/${vendedor}` : '' );
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getMoradaAlternativaByEntidadeVendedorEndpoint( idCompany, entidade, vendedor ) );
      } ) );
  }
  //#endregion

  //#region Artigos
  getArtigoCatalogsEndpoint<T>(idCompany: number ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/artigosCatalog/${idCompany}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getArtigoCatalogsEndpoint( idCompany ) );
      } ) );
  }

  getArtigosByClientEndpoint<T>(idCompany: number, client: string, moradaAlternativa: string, unidade: string ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/artigoClient/${idCompany}/${client}/${moradaAlternativa}/${unidade}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getArtigosByClientEndpoint( idCompany, client, moradaAlternativa, unidade ) );
      } ) );
  }
  //#endregion

  getErpCompaniesEndPoint<T>(): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/companies`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getErpCompaniesEndPoint() );
      } ) );
  }

  getReferenceEndPoint<T>(idCompany: number, client: string, moradaAlternativa: string, ano: string, referencia: string ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/referencia/${idCompany}/${client}/${moradaAlternativa}/${ano}/${referencia}`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getReferenceEndPoint( idCompany, client, moradaAlternativa, ano, referencia ) );
      } ) );
  }

  //#region Sales Draft
  ////
  // Sales Draft
  ////
  setSaleDraftDeleteEndpoint<T>(idCompany: number, salesObject: any ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/saleDelete/${idCompany}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSaleDraftDeleteEndpoint( idCompany, salesObject ) );
      } ) );
  }

  setSalesDraftDeleteEndpoint<T>(idCompany: number, salesObject: any[] ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/salesDeletes/${idCompany}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSalesDraftDeleteEndpoint( idCompany, salesObject ) );
      } ) );
  }

  setSaleDraftErpUpdateEndpoint<T>(idCompany: number, salesObject: any ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/saleErp/${idCompany}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSaleDraftErpUpdateEndpoint( idCompany, salesObject ) );
      } ) );
  }

  setSalesDraftErpUpdateEndpoint<T>(idCompany: number, salesObject: any[] ): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/salesErp/${idCompany}`;
    return this.http.patch<T>( endpointUrl, JSON.stringify( salesObject ), this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.setSalesDraftErpUpdateEndpoint( idCompany, salesObject ) );
      } ) );
  }
  //#endregion

  getCurrentUserPermission<T>(): Observable<T>
  {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/users/mepermssion`;
    return this.http.get<T>( endpointUrl, this.requestHeaders ).pipe<T>(
      catchError( error =>
      {
        return this.handleError( error, () => this.getCurrentUserPermission() );
      } ) );
  }
}
