import { ArtigoUnidades } from '../artigos';

export class SalesLines {
  idOrderLine?: number;
  idOrder?: number;
  idDoc?: string;
  id?: string;
  numLine?: number;
  artigo?: string;
  descricao?: string;
  tipoLinha?: string = '20';
  quantidade?: number;
  precUnit?: number;
  boxPrice?: number;
  pricePerUnit?: number;
  desconto?: number;
  desconto2?: number;
  desconto3?: number;
  precoLiquido?: number;
  unidade?: string;
  armazem?: string;
  localizacao?: string;
  vendedor?: string;
  totalPriceTax?: number;
  totalPrice?: number;
  descontoFinanceiro?: number;
  taxaIva?: number;
  allowPriceUnit?: boolean;

  skuCode?: string;
  imageUrl?: string;
  name?: string;
  quantity?: number;
  skuId?: number;
  formattedTotalAmount?: string;
  formattedUnitAmount?: string;


  unidades?: ArtigoUnidades[];
}
