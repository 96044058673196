import { Injectable } from '@angular/core';
import { HttpResponseBase, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class Utilities {
  public static readonly captionAndMessageSeparator = ':';
  public static readonly noNetworkMessageCaption = 'No Network';
  public static readonly noNetworkMessageDetail = 'The server cannot be reached';
  public static readonly accessDeniedMessageCaption = 'Access Denied!';
  public static readonly accessDeniedMessageDetail = '';
  public static readonly notFoundMessageCaption = 'Not Found';
  public static readonly notFoundMessageDetail = 'The target resource cannot be found';

  //#region Cookies
  public static cookies =
    {
      getItem: (sKey) => {
        return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
      },
      setItem: (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
          return false;
        }

        let sExpires = '';

        if (vEnd) {
          switch (vEnd.constructor) {
            case Number:
              sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd;
              break;
            case String:
              sExpires = '; expires=' + vEnd;
              break;
            case Date:
              sExpires = '; expires=' + vEnd.toUTCString();
              break;
          }
        }

        document.cookie = encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '');
        return true;
      },
      removeItem: (sKey, sPath, sDomain) => {
        if (!sKey) {
          return false;
        }
        document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '');
        return true;
      },
      hasItem: (sKey) => {
        return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=')).test(document.cookie);
      },
      keys: () => {
        const aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:\=[^;]*)?;\s*/);
        for (let nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
        return aKeys;
      }
    };
  //#endregion

  //#region Responses
  public static getHttpResponseMessages(data: HttpResponseBase | any): string[] {
    const responses: string[] = [];

    if (data instanceof HttpResponseBase) {
      if (this.checkNoNetwork(data)) {
        responses.push(`${this.noNetworkMessageCaption}${this.captionAndMessageSeparator} ${this.noNetworkMessageDetail}`);
      } else {
        const responseObject = this.getResponseBody(data);

        if (responseObject && (typeof responseObject === 'object' || responseObject instanceof Object)) {

          for (const key in responseObject) {
            if (key) {
              responses.push(`${key}${this.captionAndMessageSeparator} ${responseObject[key]}`);
            } else if (responseObject[key]) {
              responses.push(responseObject[key].toString());
            }
          }
        }
      }

      if (!responses.length) {
        if ((data as any).body) {
          responses.push(`body: ${(data as any).body}`);
        }

        if ((data as any).error) {
          responses.push(`error: ${(data as any).error}`);
        }
      }
    }

    if (!responses.length) {
      if (this.getResponseBody(data)) {
        responses.push(this.getResponseBody(data).toString());
      } else {
        responses.push(data.toString());
      }
    }

    if (this.checkAccessDenied(data)) {
      responses.splice(0, 0, `${this.accessDeniedMessageCaption}${this.captionAndMessageSeparator} ${this.accessDeniedMessageDetail}`);
    }

    if (this.checkNotFound(data)) {
      let message = `${this.notFoundMessageCaption}${this.captionAndMessageSeparator} ${this.notFoundMessageDetail}`;
      if (data.url) {
        message += `. ${data.url}`;
      }

      responses.splice(0, 0, message);
    }

    return responses;
  }

  public static getHttpResponseMessage(data: HttpResponseBase | any): string {
    const httpMessage =
      Utilities.findHttpResponseMessage(Utilities.noNetworkMessageCaption, data) ||
      Utilities.findHttpResponseMessage(Utilities.notFoundMessageCaption, data) ||
      Utilities.findHttpResponseMessage('error_description', data) ||
      Utilities.findHttpResponseMessage('error', data) ||
      Utilities.getHttpResponseMessages(data).join();

    return httpMessage;
  }

  public static findHttpResponseMessage(messageToFind: string, data: HttpResponse<any> | any, seachInCaptionOnly = true, includeCaptionInResult = false): string {
    const searchString = messageToFind.toLowerCase();
    const httpMessages = this.getHttpResponseMessages(data);

    for (const message of httpMessages) {
      const fullMessage = Utilities.splitInTwo(message, this.captionAndMessageSeparator);

      if (fullMessage.firstPart && fullMessage.firstPart.toLowerCase().indexOf(searchString) !== -1) {
        return includeCaptionInResult ? message : fullMessage.secondPart || fullMessage.firstPart;
      }
    }

    if (!seachInCaptionOnly) {
      for (const message of httpMessages) {

        if (message.toLowerCase().indexOf(searchString) !== -1) {
          if (includeCaptionInResult) {
            return message;
          } else {
            const fullMessage = Utilities.splitInTwo(message, this.captionAndMessageSeparator);
            return fullMessage.secondPart || fullMessage.firstPart;
          }
        }
      }
    }

    return null;
  }

  public static getResponseBody(response: HttpResponseBase) {
    if (response instanceof HttpResponse) {
      return response.body;
    }

    if (response instanceof HttpErrorResponse) {
      return response.error || response.message || response.statusText;
    }
  }
  //#endregion

  //#region Check
  public static checkNoNetwork(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 0;
    }

    return false;
  }

  public static checkAccessDenied(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 403;
    }

    return false;
  }

  public static checkNotFound(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 404;
    }

    return false;
  }

  public static checkIsLocalHost(url: string, base?: string) {
    if (url) {
      const location = new URL(url, base);
      return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    }

    return false;
  }
  //#endregion

  public static getQueryParamsFromString(paramString: string) {
    if (!paramString) {
      return null;
    }

    const params: { [key: string]: string } = {};

    for (const param of paramString.split('&')) {
      const keyValue = Utilities.splitInTwo(param, '=');
      params[keyValue.firstPart] = keyValue.secondPart;
    }

    return params;
  }

  public static splitInTwo(text: string, separator: string): { firstPart: string, secondPart: string } {
    const separatorIndex = text.indexOf(separator);

    if (separatorIndex === -1) {
      return { firstPart: text, secondPart: null };
    }

    const part1 = text.substr(0, separatorIndex).trim();
    const part2 = text.substr(separatorIndex + 1).trim();

    return { firstPart: part1, secondPart: part2 };
  }

  public static safeStringify(object) {

    let result: string;

    try {
      result = JSON.stringify(object);
      return result;
    } catch (error) {

    }

    const simpleObject = {};

    for (const prop in object) {
      if (!object.hasOwnProperty(prop)) {
        continue;
      }
      if (typeof (object[prop]) === 'object') {
        continue;
      }
      if (typeof (object[prop]) === 'function') {
        continue;
      }
      simpleObject[prop] = object[prop];
    }

    result = '[***Sanitized Object***]: ' + JSON.stringify(simpleObject);

    return result;
  }

  public static JsonTryParse(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      if (value === 'undefined') {
        return void 0;
      }
      return value;
    }
  }

  public static GetObjectWithLoweredPropertyNames(obj: any) {
    const loweredObj = Object.keys(obj).reduce((newObj, k) => {
      newObj[k.toLowerCase()] = obj[k];
      return newObj;
    }, {});

    return loweredObj;
  }

  //#region Test
  public static TestIsObjectEmpty(obj: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  public static TestIsUndefined(value: any) {
    return typeof value === 'undefined';
    // return value === undefined;
  }

  public static TestIsString(value: any) {
    return typeof value === 'string' || value instanceof String;
  }
  //#endregion

  //#region String
  public static capitalizeFirstLetter(text: string) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    } else {
      return text;
    }
  }

  public static toTitleCase(text: string) {
    return text.replace(/\w\S*/g, (subString) => {
      return subString.charAt(0).toUpperCase() + subString.substr(1).toLowerCase();
    });
  }

  public static toLowerCase(items: string);
  public static toLowerCase(items: string[]);
  public static toLowerCase(items: any): string | string[] {

    if (items instanceof Array) {
      const loweredRoles: string[] = [];

      for (let i = 0; i < items.length; i++) {
        loweredRoles[i] = items[i].toLowerCase();
      }

      return loweredRoles;
    } else if (typeof items === 'string' || items instanceof String) {
      return items.toLowerCase();
    }
  }

  public static expandCamelCase(text: string) {

    if (!text) {
      return text;
    }

    return text.replace(/([A-Z][a-z]+)/g, ' $1')
      .replace(/([A-Z][A-Z]+)/g, ' $1')
      .replace(/([^A-Za-z ]+)/g, ' $1');
  }
  //#endregion

  public static uniqueId() {
    return this.randomNumber(1000000, 9000000).toString();
  }

  public static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static baseUrl() {
    let base = '';

    if (window.location.origin) {
      base = window.location.origin;
    } else {
      base = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    return base.replace(/\/$/, '');
  }

  public static testIsAbsoluteUrl(url: string) {

    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
  }

  public static convertToAbsoluteUrl(url: string) {

    return Utilities.testIsAbsoluteUrl(url) ? url : '//' + url;
  }

  //#region Date
  public static printDateOnly(date: Date) {

    date = new Date(date);

    const dayNames = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
    const monthNames = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');

    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    let sup = '';
    const month = date.getMonth();
    const year = date.getFullYear();

    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      sup = 'st';
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
      sup = 'nd';
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
      sup = 'rd';
    } else {
      sup = 'th';
    }

    const dateString = dayNames[dayOfWeek] + ', ' + dayOfMonth + sup + ' ' + monthNames[month] + ' ' + year;

    return dateString;
  }

  public static printTimeOnly(date: Date) {

    date = new Date(date);

    let period = '';
    let minute = date.getMinutes().toString();
    let hour = date.getHours();

    period = hour < 12 ? 'AM' : 'PM';

    if (hour === 0) {
      hour = 12;
    }
    if (hour > 12) {
      hour = hour - 12;
    }

    if (minute.length === 1) {
      minute = '0' + minute;
    }

    const timeString = hour + ':' + minute + ' ' + period;


    return timeString;
  }

  public static printDate(date: Date, separator = 'at') {
    return `${Utilities.printDateOnly(date)} ${separator} ${Utilities.printTimeOnly(date)}`;
  }

  public static printFriendlyDate(date: Date, separator = '-') {
    const today = new Date(); today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today); yesterday.setDate(yesterday.getDate() - 1);
    const test = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    if (test.toDateString() === today.toDateString()) {
      return `Today ${separator} ${Utilities.printTimeOnly(date)}`;
    }
    if (test.toDateString() === yesterday.toDateString()) {
      return `Yesterday ${separator} ${Utilities.printTimeOnly(date)}`;
    } else {
      return Utilities.printDate(date, separator);
    }
  }

  public static printShortDate(date: Date, separator = '/', dateTimeSeparator = '-') {

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    if (day.length === 1) {
      day = '0' + day;
    }

    if (month.length === 1) {
      month = '0' + month;
    }

    return `${month}${separator}${day}${separator}${year} ${dateTimeSeparator} ${Utilities.printTimeOnly(date)}`;
  }

  public static parseDate(date) {

    if (date) {

      if (date instanceof Date) {
        return date;
      }

      if (typeof date === 'string' || date instanceof String) {
        if (date.search(/[a-su-z+]/i) === -1) {
          date = date + 'Z';
        }

        return new Date(date);
      }

      if (typeof date === 'number' || date instanceof Number) {
        return new Date(date as any);
      }
    }
  }

  public static printDuration(start: Date, end: Date) {

    start = new Date(start);
    end = new Date(end);

    // get total seconds between the times
    let delta = Math.abs(start.valueOf() - end.valueOf()) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = delta % 60;  // in theory the modulus is not required


    let printedDays = '';

    if (days) {
      printedDays = `${days} days`;
    }

    if (hours) {
      printedDays += printedDays ? `, ${hours} hours` : `${hours} hours`;
    }

    if (minutes) {
      printedDays += printedDays ? `, ${minutes} minutes` : `${minutes} minutes`;
    }

    if (seconds) {
      printedDays += printedDays ? ` and ${seconds} seconds` : `${seconds} seconds`;
    }


    if (!printedDays) {
      printedDays = '0';
    }

    return printedDays;
  }

  public static getAge(birthDate, otherDate) {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);

    let years = (otherDate.getFullYear() - birthDate.getFullYear());

    if (otherDate.getMonth() < birthDate.getMonth() ||
      otherDate.getMonth() === birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
      years--;
    }

    if (1900 > birthDate.getFullYear()) {
      years = 0;
    }

    return years;
  }

  public static dateNow() {
    const date = new Date();
    return date;
  }

  public static getdate(date: Date) {
    const _d = this.parseDate(date);
    const _date = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate());
    return _date;
  }

  public static dateNowByMonth(month: number): Date {
    const date = new Date();
    date.setMonth(date.getMonth() + month);
    return date;
  }

  public static dateNowByDay(day: number): Date {
    const date = new Date();
    const _date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    date.setDate(date.getDate() + day);
    return date;
  }

  public static getYear(date: any) {
    if (date instanceof Date) {
      return (date as Date).getFullYear().toString();
    } else if (date && date as string) {
      const _d = this.parseDate(date);
      if (_d) {
        return _d.getFullYear().toString();
      } else {
        return this.dateNow().getFullYear().toString();
      }
    } else {
      return this.dateNow().getFullYear().toString();
    }
  }

  public static DateJson(date: any): string {
    if (!this.isNullOrUndefined(date) && date instanceof Date) {
      return date.toJSON();
    } else {
      return null;
    }
  }

  public static DateToJson(date: any): any {
    if (!this.isNullOrUndefined(date) && date instanceof Date) {
      return date.toJSON().slice(0, -5);
    } else {
      return null;
    }
  }

  public static DateToJsonDate(date: any,): any {
    if (!this.isNullOrUndefined(date) && date instanceof Date) {
      return date.toJSON().substring(0, 10);
    } else {
      return null;
    }
  }

  public static CompareDayToNow(dateA: any): boolean {
    return Utilities.parseDate(dateA).getDate() === this.dateNow().getDate();
  }
  //#endregion

  //#region Array
  public static searchArray(searchTerm: string, caseSensitive: boolean, ...values: any[]) {
    if (!searchTerm) {
      return true;
    }

    let filter = searchTerm.trim();
    let data = values.join();

    if (!caseSensitive) {
      filter = filter.toLowerCase();
      data = data.toLowerCase();
    }

    return data.indexOf(filter) !== -1;
  }

  public static moveArrayItem(array: any[], oldIndex, newIndex) {

    if (oldIndex < 0) {
      return;
    }

    if (newIndex < 0) {
      newIndex += array.length;
    }

    if (newIndex >= array.length) {
      let k = newIndex - array.length;
      while ((k--) + 1) {
        array.push(undefined);
      }
    }

    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }
  //#endregion

  public static removeNulls(obj) {
    const isArray = obj instanceof Array;

    for (const k in obj) {
      if (!obj.hasOwnProperty(k)) {
        continue;
      }

      if (obj[k] === null) {
        isArray ? obj.splice(Number.parseInt(k), 1) : delete obj[k];
      } else if (typeof obj[k] === 'object') {
        Utilities.removeNulls(obj[k]);
      }

      if (isArray && obj.length === +k) {
        Utilities.removeNulls(obj);
      }
    }

    return obj;
  }

  public static debounce(func: (...args) => any, wait: number, immediate?: boolean) {
    let timeout;

    return () => {
      const context = this;
      const args = arguments;

      const later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  //#region Round Numbers
  public static roundNumber(value: number, dp: number) {
    let finalValue = 0;
    if (arguments.length !== 2) { throw new Error('2 arguments required'); }

    const num: string = value.toString();
    if (num.indexOf('e+') !== -1) {
      // Can't round numbers this large because their string representation
      // contains an exponent, like 9.99e+37
      // throw new Error( "num too large" );
      return Number.parseFloat(num);
    }
    if (num.indexOf('.') === -1) {
      // Nothing to do
      return Number.parseFloat(num);
    }

    const parts = num.split('.');
    const beforePoint = parts[0];
    let afterPoint = parts[1];
    const shouldRoundUp = Number.parseFloat(afterPoint[dp]) >= 5;
    let finalNumber;

    afterPoint = afterPoint.slice(0, dp);
    if (!shouldRoundUp) {
      finalNumber = beforePoint + '.' + afterPoint;
    } else if (/^9+$/.test(afterPoint)) {
      // If we need to round up a number like 1.9999, increment the integer
      // before the decimal point and discard the fractional part.
      finalNumber = Number(beforePoint) + 1;
    } else {
      // Starting from the last digit, increment digits until we find one
      // that is not 9, then stop
      let i = dp - 1;
      while (true) {
        if (afterPoint[i] === '9') {
          afterPoint = afterPoint.substr(0, i) +
            '0' +
            afterPoint.substr(i + 1);
          i--;
        } else {
          afterPoint = afterPoint.substr(0, i) +
            (Number(afterPoint[i]) + 1) +
            afterPoint.substr(i + 1);
          break;
        }
      }

      finalNumber = beforePoint + '.' + afterPoint;
    }

    try {
      finalValue = Number.parseFloat(finalNumber.replace(/0+$/, ''));
    } catch (e) {
      console.log('roundNumber::' + e);
      finalValue = this.round(value, dp);
    }

    // Remove trailing zeroes from fractional part before returning
    return finalValue;
  }

  public static round(value: number, decimals?: number): number {
    let result: number = value;
    try {
      result = Number(value.toFixed(decimals));
    } catch {
    }
    return result;
  }

  

  public static decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // If the value is negative...
    if (value < 0) {
      return -this.decimalAdjust(type, -value, exp);
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  public static CalculateDiscount(value: number, discount: number, discount2?: number, discount3?: number, discount4?: number) {
    return this.CalculateDiscountRound(false, value, discount, discount2, discount3, discount4);
  }

  public static CalculateDiscountRound(round: boolean, dp: number, value: number, discount: number, discount2?: number, discount3?: number, discount4?: number) {
    let roundValue = 0;
    try {
      roundValue = Utilities.roundNumber(value, 4);
    } catch {
      roundValue = value;
    }

    let v: number = roundValue;
    let d: number = (discount / 100);

    v = roundValue - (roundValue * d);

    if (discount2 && !Number.isNaN(discount2)) {
      if (discount + discount2 > 100) {
        d = 1;
      } else {
        d = (discount2 / 100);
      }
      v = v - (v * d);
    }

    if (discount3 && !Number.isNaN(discount3)) {
      if (discount + discount2 + discount3 > 100) {
        d = 1;
      } else {
        d = (discount3 / 100);
      }
      v = v - (v * d);
    } else {
      if (discount + discount2 + 0 > 100) {
        d = 1;
      } else {
        d = (0 / 100);
      }
      v = v - (v * d);
    }

    if (discount4 && !Number.isNaN(discount4)) {
      if (discount + discount2 + discount3 + discount4 > 100) {
        d = 1;
      } else {
        d = (discount4 / 100);
      }
      v = v - (v * d);
    } else {
      if (discount + discount2 + discount3 + 0 > 100) {
        d = 1;
      } else {
        d = (0 / 100);
      }
      v = v - (v * d);
    }

    try {
      if (round) {
        return this.RoundFormat(dp, v); //this.roundNumber(v, dp); // Number.parseFloat( this.decimalAdjust( "round", v, ( dp * -1 ) ) );
        // this.roundNumber( v, ( dp * -1 ) );
      } else {
        return v;
      }
    } catch (e) {
      console.log('CalculateDiscountRound::' + e);
      return v;
    }
  }

  public static RoundFormat(dp: number, value: number): number {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: dp,
      minimumFractionDigits: dp,
    })
    return this.ConvertNumberFloat(formatter.format(value).replace(",", ""));
  }

  public static ConvertNumberFloat(value: any): number {
    let result: number = 0;
    if (!this.isNullOrUndefined(value)) {
      try {
        result = Number.parseFloat(value);
      } catch { }
    }
    return result;
  }

  public static ConvertNumberInt(value: any): number {
    let result: number = 0;
    if (!this.isNullOrUndefined(value)) {
      try {
        result = Number.parseInt(value);
      } catch { }
    }
    return result;
  }
  //#endregion

  //#region Image
  public static compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = error => rej(error);
    });
  }

  public static compressImage2(src) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = img.width / 4;
        elem.height = img.height / 4;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, elem.width, elem.height);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = error => rej(error);
    });
  }

  public static compressImage3(src): any {
    const img = new Image();
    img.src = src;
    const elem = document.createElement('canvas');
    elem.width = 200;
    elem.height = 200;
    const ctx = elem.getContext('2d');
    ctx.drawImage(img, 0, 0, 200, 200);
    const data = ctx.canvas.toDataURL();
    if (data) {
      console.log('Size::' + this.getSize(this.calculateImageSize(data)));
    }
    return data;
  }
  public static getSize(size: number): string {
    let _size: string;
    // const size = event.srcElement.files[0].size;
    if (size < 1000) {
      _size = `${size} "bytes"`;
    } else if (size < 1000 * 1000) {
      _size = `${size / 1000} "kb"`;
    } else if (size < 1000 * 1000 * 1000) {
      _size = `${size / 1000 / 1000} "mb"`;
    } else {
      _size = `${size / 1000 / 1000 / 1000} "gb"`;
    }
    return _size;
  }

  public static calculateImageSize(base64String): number {
    let padding;
    let inBytes;
    let base64StringLength;

    if (base64String.endsWith('==')) { padding = 2; } else if (base64String.endsWith('=')) { padding = 1; } else { padding = 0; }

    base64StringLength = base64String.length;
    inBytes = (base64StringLength / 4) * 3 - padding;
    const kbytes = inBytes / 1000;
    return kbytes;
  }
  //#endregion

  //#region isNullOrUndefined
  public static isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === 'undefined' || obj === null;
  }
  // public static isNullOrEmpty<T>(obj: T | null | undefined): obj is null | undefined {
  //  return (typeof obj === 'undefined' || obj === null) || ((typeof obj === 'undefined' && obj === null) && obj.str
  // }
  //#endregion

  //#region Delay
  private static _delay = ms => new Promise(res => setTimeout(res, ms));
  public static async delay(time: number): Promise<any> {
    await this._delay(time);
  }
  //#endregion

  //#region validation data
  //#region nif
  public static validaContribuinte(pais: string, contribuinte: string): boolean {
    let result = false;
    // algoritmo de validação do NIF de acordo com
    // http://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal

    if (pais === 'PT') {
      let temErro = 0;
      if (
        contribuinte.substr(0, 1) !== '1' && // pessoa singular
        contribuinte.substr(0, 1) !== '2' && // pessoa singular
        contribuinte.substr(0, 1) !== '3' && // pessoa singular
        contribuinte.substr(0, 2) !== '45' && // pessoa singular não residente
        contribuinte.substr(0, 1) !== '5' && // pessoa colectiva
        contribuinte.substr(0, 1) !== '6' && // administração pública
        contribuinte.substr(0, 2) !== '70' && // herança indivisa
        contribuinte.substr(0, 2) !== '71' && // pessoa colectiva não residente
        contribuinte.substr(0, 2) !== '72' && // fundos de investimento
        contribuinte.substr(0, 2) !== '77' && // atribuição oficiosa
        contribuinte.substr(0, 2) !== '79' && // regime excepcional
        contribuinte.substr(0, 1) !== '8' && // empresário em nome individual (extinto)
        contribuinte.substr(0, 2) !== '90' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) !== '91' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) !== '98' && // não residentes
        contribuinte.substr(0, 2) !== '99' // sociedades civis
      ) { temErro = 1; }

      const check1 = Number.parseFloat(contribuinte.substr(0, 1)) * 9;
      const check2 = Number.parseFloat(contribuinte.substr(1, 1)) * 8;
      const check3 = Number.parseFloat(contribuinte.substr(2, 1)) * 7;
      const check4 = Number.parseFloat(contribuinte.substr(3, 1)) * 6;
      const check5 = Number.parseFloat(contribuinte.substr(4, 1)) * 5;
      const check6 = Number.parseFloat(contribuinte.substr(5, 1)) * 4;
      const check7 = Number.parseFloat(contribuinte.substr(6, 1)) * 3;
      const check8 = Number.parseFloat(contribuinte.substr(7, 1)) * 2;

      const total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
      const divisao = total / 11;
      const modulo11 = total - Number.parseInt(divisao.toString(), null) * 11;
      let comparador = 0;
      if (modulo11 === 1 || modulo11 === 0) {
        comparador = 0;
      } else {
        comparador = 11 - modulo11;
      }


      const ultimoDigito = Number.parseInt(contribuinte.substr(8, 1), null) * 1;
      if (ultimoDigito !== comparador) { temErro = 1; }

      if (temErro === 1) {
        result = false;
      } else {
        result = true;
      }
    } else {
      return true;
    }
    return result;
  }
  //#endregion

  public static CartaoCidadao(pais: string, cc: string): boolean {
    if (pais === 'PT') {
      const letter = { A: 10, B: 11, C: 12, D: 13, E: 14, F: 15, G: 16, H: 17, I: 18, J: 19, K: 20, L: 21, M: 22, N: 23, O: 24, P: 25, Q: 26, R: 27, S: 28, T: 29, U: 30, V: 31, W: 32, X: 33, Y: 34, Z: 35 };
      let ccNumber = cc.replace(/-|\s/g, ''); // remove space and -
      ccNumber = ccNumber.toUpperCase();
      let ccNumberA = [...ccNumber];
      ccNumberA = ccNumberA.reverse();
      ccNumberA[1] = letter[ccNumberA[1]];
      ccNumberA[2] = letter[ccNumberA[2]];
      let sum = 0;
      let dum = 0;

      ccNumberA.forEach((v, k, s) => {
        if (k % 2 === 0) {
          dum = parseInt(v, null);
        } else {
          dum = parseInt(v, null) * 2;
          if (dum >= 10) {
            dum -= 9;
          }
        }
        sum += dum;
        console.log('k : ' + k + ' | sum : ' + sum);
      });

      return (sum % 10 === 0) ? true : false;
    } else {
      return true;
    }
  }

  public static bi(pais: string, bi: string, check: string): boolean {
    if (pais === 'PT') {
      const checkBi = parseInt(check, null);
      // convert string to array
      let numberBi = [...bi];
      // reverse the number array
      numberBi = numberBi.reverse();

      let value = 0;
      numberBi.forEach((v, k) => {
        value += (k + 2) * parseInt(v, null);
      });

      return ((value + checkBi) % 11 === 0) || (checkBi === 0 && (value + 10) % 11 === 0);
    } else {
      return true;
    }
  }

  /*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
  public static isValidIBANNumber(pais: string, input: string): boolean {
    if (pais === 'PT') {
      const codeLengths = {
        AD: 24, AE: 23, AL: 28, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, CH: 21, CR: 21, CY: 28, CZ: 24,
        DE: 22, DK: 18, DO: 28, EE: 20, ES: 24, LC: 30, FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28,
        HR: 21, HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21, LT: 20, LU: 20, LV: 21,
        MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
      };

      const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
      const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
      // check syntax and length
      if (!code || iban.length !== codeLengths[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => String(letter.charCodeAt(0) - 55));
      // final check
      return this.mod97(digits) === 1 ? true : false;
    } else {
      return true;
    }
  }

  private static mod97(digital: number | string) {
    digital = digital.toString();
    let checksum: number | string = digital.slice(0, 2);
    let fragment = '';
    for (let offset = 2; offset < digital.length; offset += 7) {
      fragment = checksum + digital.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }


  // private static countryAndSize =
  //  {
  //    GB: { size: 22, name: 'UK', regex: /[A-Z]{4}\d{14}/ },
  //    IE: { size: 22, name: 'IRELAND', regex: /[A-Z]{4}\d{14}/ },
  //    PT: { size: 25, name: 'PORTUGAL', regex: /[A-Z]{4}\d{14}/ }
  //  };



  private static modulo(aNumStr: string, aDiv: number) {
    let tmp: any = '';
    let r;

    for (let i = 0; i < aNumStr.length; i++) {
      tmp += aNumStr.charAt(i);
      r = tmp % aDiv;
      tmp = r.toString();
    }
    return tmp / 1;
  }



  public static isIBAN(pais: string, iban: string) {
    if (pais === 'PT') {
      // Move front 4 digits to the end
      const rearrange = iban.substring(4, iban.length) + iban.substring(0, 4);

      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      const alphaMap = {};
      const numberV = [];

      alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
      });

      rearrange.split('').forEach((value, index) => {
        numberV[index] = alphaMap[value] || value;
      });

      return this.modulo(numberV.join('').toString(), 97) === 1;
    } else {
      return true;
    }
  }
  //#endregion
}
