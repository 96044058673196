import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';

import { PurchaseEndpoint } from './purchase-endpoint.service';
import { CustomerEndpoint } from '../customers/customer-endpoint.service';
import { Utilities } from '../../utils';
import { AuthService } from '../auth/auth.service';
import { Purchase, Entidade, Artigos, MoradasAlternativa, Documents, Unidades, UserErpPermission, ErpCompany, CancelPurchase, Image, PaisesEncomendas, ProductCatalog, Salesman, Company, PurchaseStatus, PurchasesType, TTEntidades, Report, ApprovalWorkflow } from '../../models';


@Injectable()
export class PurchaseService {

  constructor(private authService: AuthService, private customerEndpoint: CustomerEndpoint, private purchaseEndpoint: PurchaseEndpoint) { }

  get UserAtualErpPermissions(): UserErpPermission {
    return this.purchaseEndpoint.userAtualErpPermissions;
  }

  get currentUserErpPermissions() {
    return this.authService.userErpPermissions;
  }

  //#region Purchases
  //#region Get
  getPurchaseByFilters(id: any, companyId: number, salesman: string, estado: string) {
    return this.purchaseEndpoint.getPurchaseByFiltersEndpoint<Purchase>(id, companyId, salesman, 0, 0, estado);
  }

  getPurchasesByFilters(companyId: number, salesman: string, purchaseType: number, estado: string, dateFrom?: Date, dateTo?: Date) {
    return this.purchaseEndpoint.getPurchasesByFiltersEndpoint<Purchase[]>(companyId, salesman, purchaseType, estado, dateFrom, dateTo);
  }

  getPurchasesLoadData(idCompany: number) {
    return forkJoin(
      this.customerEndpoint.getCustomerByIdEndpoint<Unidades[]>('document', 'unidades', idCompany),
      this.customerEndpoint.getCustomerByIdEndpoint<PaisesEncomendas[]>('document', 'paisesEncomendas', idCompany),
      this.customerEndpoint.getCustomerByIdEndpoint<ApprovalWorkflow[]>('customer', 'approvalWorkflowsByCompanyId', idCompany),
      this.purchaseEndpoint.getListByEndPointCompanyId<Entidade[]>('api/api/generics', 'providers', idCompany)
    );
  }

  getPurchasesData(id: any, companyId: number, salesman: string): Observable<[Purchase, Documents[], Entidade[]]> {
    if ((!companyId || (companyId && companyId === 0)) && this.purchaseEndpoint.userAtualErpPermissions) {
      companyId = this.purchaseEndpoint.userAtualErpPermissions.companyId;
    }

    return forkJoin(
      id ? this.purchaseEndpoint.getPurchaseByFiltersEndpoint<Purchase>(id, companyId, salesman, 0, 0, 'All') : of(null),
      this.purchaseEndpoint.getListByEndPointCompanyId<Documents[]>('api/api/generics', 'documents', companyId),
      this.purchaseEndpoint.getListByEndPointCompanyId<Entidade[]>('api/api/generics', 'providers', companyId)
    );
  }

  getTopPurchases(idCompany: number, tipoDoc: string, serie: string, provider: string) {
    return this.purchaseEndpoint.getTopPurchasesEndpoint<Purchase[]>(idCompany, tipoDoc, serie, provider);
  }

  getPurchaseReport(idCompany: number, purchaseType: number, id: any, title: string) {
    return this.purchaseEndpoint.getReportEndpoint<Report>(idCompany, purchaseType, id, title, 'b');
  }

  async getPurchaseReportAsync(idCompany: number, purchaseType: number, id: any, title: string): Promise<Report> {
    return await this.purchaseEndpoint.getReportEndpoint<Report>(idCompany, purchaseType, id, title, 'b').toPromise();
  }

  async getApprovalWorkflowsAsync(idCompany: number): Promise<ApprovalWorkflow[]> {
    return await this.customerEndpoint.getCustomerByIdEndpoint<ApprovalWorkflow[]>('customer', 'approvalWorkflowsByCompanyId', idCompany).toPromise();
  }

  setPurchaseReport(idCompany: number, reportObject: any) {
    return this.purchaseEndpoint.setReportEndpoint<Report>(idCompany, reportObject);
  }

  async setPurchaseReportAsync(idCompany: number, reportObject: any): Promise<Report> {
    return await this.purchaseEndpoint.setReportEndpoint<Report>(idCompany, reportObject).toPromise();
  }
  //#endregion

  //#region Set
  setPurchaseUpdate(idCompany: number, purchaseType: number, purchasesObject: any) {
    return this.purchaseEndpoint.setPurchaseUpdateEndpoint<Purchase>(idCompany, purchaseType, purchasesObject);
  }

  setPurchasesUpdate(idCompany: number, purchaseType: number, purchasesObject: any[]) {
    return this.purchaseEndpoint.setPurchasesUpdateEndpoint<Purchase>(idCompany, purchaseType, purchasesObject);
  }

  setPurchaseUpdateCancel(idCompany: number, purchasesObject: any) {
    return this.purchaseEndpoint.setPurchaseUpdateCancelEndpoint<Purchase>(idCompany, purchasesObject);
  }

  setPurchasePrint(idCompany: number, purchaseType: number, purchasesObject: any) {
    return this.purchaseEndpoint.setPurchasePrintEndpoint<Purchase>(idCompany, purchaseType, purchasesObject);
  }
  //#endregion

  //#endregion

  //#region Erp

  //#region Cancel Purchases
  getCancelPurchases(idCompany: number) {
    return this.purchaseEndpoint.getCancelPurchasesEndpoint<CancelPurchase[]>(idCompany);
  }
  //#endregion

  //#region Moradas
  getMoradaAlternativaByEntidade(idCompany: number, entidade: string, vendedor?: string) {
    return this.purchaseEndpoint.getMoradaAlternativaByEntidadeVendedorEndpoint<MoradasAlternativa[]>(idCompany, entidade, vendedor);
  }
  //#endregion

  //#region Artigos
  getArtigosCatalog(idCompany: number) {
    return this.purchaseEndpoint.getArtigoCatalogsEndpoint<Artigos[]>(idCompany);
  }
  getArtigosCatalogImage(idCompany: number, companyId: number) {
    return forkJoin(
      this.purchaseEndpoint.getArtigoCatalogsEndpoint<Artigos[]>(idCompany),
      this.purchaseEndpoint.getGenericEndpoint<ProductCatalog[]>('artigosCatalogs', companyId),
      this.customerEndpoint.getByCompanydIdGenericEndpoint<Image[]>('images', companyId));
  }
  getArtigosByClient(idCompany: number, client: string, moradaAlternativa: string, unidade: string) {
    return this.purchaseEndpoint.getArtigosByClientEndpoint<Artigos[]>(idCompany, client, moradaAlternativa, unidade);
  }
  //#endregion

  //#region Purchasesman
  getPurchasesmansAndCompanies() {
    return forkJoin(
      this.customerEndpoint.getCustomerEndpoint<Salesman[]>('document', 'salesman'),
      this.customerEndpoint.getCustomerEndpoint<Company[]>('customer', 'companies')
    );
  }
  //#endregion

  getErpCompanies() {
    return this.purchaseEndpoint.getErpCompaniesEndPoint<ErpCompany[]>();
  }

  async getReference(idCompany: number, provider: string, moradaAlternativa: string, ano: string, referencia: string): Promise<Purchase[]> {
    return await this.purchaseEndpoint.getReferenceEndPoint<Purchase[]>(idCompany, provider, moradaAlternativa, ano, referencia).toPromise();
  }

  //#endregion

  //#region Purchases Draft
  ////
  // Purchases Draft
  ////
  setPurchaseDraftDelete(idCompany: number, purchasesObject: any) {
    return this.purchaseEndpoint.setPurchaseDraftDeleteEndpoint<Purchase>(idCompany, purchasesObject);
  }

  async setPurchaseDraftDeleteAsync(idCompany: number, purchasesObject: any): Promise<Purchase> {
    return this.purchaseEndpoint.setPurchaseDraftDeleteEndpoint<Purchase>(idCompany, purchasesObject).toPromise();
  }

  setPurchaseDraftErpUpdate(idCompany: number, purchasesObject: any) {
    return this.purchaseEndpoint.setPurchaseDraftErpUpdateEndpoint<Purchase>(idCompany, purchasesObject);
  }

  async setPurchaseDraftErpUpdateAsync(idCompany: number, purchasesObject: any): Promise<Purchase> {
    return this.purchaseEndpoint.setPurchaseDraftErpUpdateEndpoint<Purchase>(idCompany, purchasesObject).toPromise();
  }

  setPurchasesDraftErpUpdate(idCompany: number, purchasesObject: any[]) {
    return this.purchaseEndpoint.setPurchasesDraftErpUpdateEndpoint<Purchase>(idCompany, purchasesObject);
  }

  async setPurchasesDraftErpUpdateAsync(idCompany: number, purchasesObject: any[]): Promise<Purchase[]> {
    return this.purchaseEndpoint.setPurchasesDraftErpUpdateEndpoint<Purchase[]>(idCompany, purchasesObject).toPromise();
  }

  setPurchasesDraftDelete(idCompany: number, purchasesObject: any[]) {
    return this.purchaseEndpoint.setPurchasesDraftDeleteEndpoint<Purchase>(idCompany, purchasesObject);
  }
  //#endregion

  getPurchasesStatus(purchasesType: PurchasesType): PurchaseStatus[] {
    let dataEstados: PurchaseStatus[];
    if (!Utilities.isNullOrUndefined(purchasesType)) {
      if (!purchasesType.isDraft && !purchasesType.isReturn) {
        dataEstados = [new PurchaseStatus(0, 'All', 'All'), new PurchaseStatus(0, 'APR', 'Aprove'), new PurchaseStatus(0, 'A', 'Cancel'), new PurchaseStatus(0, 'E', 'Pending Approval')];
      } else if (purchasesType.isReturn) {
        dataEstados = [new PurchaseStatus(2, 'E', 'Pending Approval'), new PurchaseStatus(2, 'APR', 'Aprove')];
      }
    }
    // switch ( !salesType.isDraft )
    // {
    //  case 0: dataEstados = [new PurchasesStatus( 'All', 'All' ), new PurchasesStatus( 'APR', 'Aprove' ), new PurchasesStatus( 'A', 'Cancel' ), new PurchasesStatus( 'E', 'Editing' )]; break;
    //  case 2: case 3: dataEstados = [new PurchasesStatus( 'E', 'Editing' )]; break;
    // }
    // dataEstados = [{ estado: 'All', name: 'All' }, { estado: 'APR', name: 'Aprove' }, { estado: 'A', name: 'Cancel' }, { estado: 'E', name: 'Editing' }];

    return dataEstados;
  }


  getCurrentUserPermission() {
    return this.purchaseEndpoint.getCurrentUserPermission<UserErpPermission[]>();
  }

  get currentUser() {
    return this.authService.currentUser;
  }
}
