export class ArtigoUnidades {
  constructor(unidade?: string, precUnit?: number, conversao?: number) {
    this.unidade = unidade;
    this.precUnit = precUnit;
    this.conversao = conversao;
  }

  unidade?: string;
  precUnit?: number;
  conversao?: number;
  qtMin?: number;
  qtMax?: number;
}
