export class TiposDocAttach {
  tipoDoc?: string;
  description?: string;
  descriptionEn?: string;
  descriptionDe?: string;
  ruleCliente?: boolean;
  visibel?: boolean;
  required?: boolean;
  totalFromRequired?: number;
  totalToRequired?: number;
  modoPag?: string;

  docRequired?: boolean;
  noteDoc?: string;
}
