import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

@Injectable()
export class AppVersionService {

  private config: { version: string };

  private onLanguageChanged = new Subject<string>();
  languageChanged$ = this.onLanguageChanged.asObservable();

  constructor(private httpClient: HttpClient) {
    // this.addLanguages(['en', 'fr', 'de', 'pt', 'ar', 'ko']);
    // this.setDefaultLanguage('en');
  }

  getVersion(): string {
    // this.config = of(require('../../../../assets/version/version.json'));

    console.log(this.config.version);

    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.httpClient
      .get<{ version: string }>('../assets/version/version.json', { headers })
      .subscribe(config => {
        if (config.version !== this.config.version) {
          this.httpClient
            .get('', { headers, responseType: 'text' })
            .subscribe(() => location.reload());
        }
      });

    return '';
  }

}
