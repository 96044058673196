<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-body>
        <app-auth-block>
          <router-outlet></router-outlet>
        </app-auth-block>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>
