import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { AuthService } from '../auth';
import { ConfigurationService } from '../conf';
import { ApiBaseService } from '../api/api-base.service';
import { Company, Documents, Entidade, User, UserCompanies } from '../../models';
import { ApiService } from '..';

@Injectable()
export class UsersService extends ApiBaseService {

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService, private apiService: ApiService) {
    super(configurations, http, authService);
    //this.company = this.userAtualErpPermissions;
  }

  //#region User
  //#region Get
  getCurrentUser(userId?: string): Observable<User> {
    if (userId) {
      return this.getById<User>(`${this.controllerAccount}/users`, userId);
    }
    else {
      return this.get<User>(`${this.controllerAccount}/users/me`);
    }
  }

  getUsersByCompany(companyId?: number): Observable<User[]> {
    if (companyId) {
      return this.getListByCompanyId<User[]>(`${this.controllerAccount}/users/company`, companyId);
    }
    else {
      return this.getListByCompanyId<User[]>(`${this.controllerAccount}/users/company`, this.userAtualErpPermissions.companyId);
    }
  }
  //#endregion
  //#endregion

  //#region UserCompany
  //#region Get
  getUserCompanyData(companyId?: number): Observable<[Documents[], Entidade[]]> {
    if (!companyId) {
      companyId = this.userAtualErpPermissions.companyId;
    }
    return forkJoin([
      this.getListByEndPointCompanyId<Documents[]>(`${this.controllerApi}/generics`, 'purchaseDocuments', companyId),
      this.getListByEndPointCompanyId<Entidade[]>(`${this.controllerApi}/generics`, 'providers', companyId)
    ]);
  }

  getUserCompany(id: number): Observable<UserCompanies> {
    return this.getById<UserCompanies>(`${this.controllerAccount}/userCompany`, id);
  }

  getUserCompanies(userId: string): Observable<UserCompanies[]> {
    return this.getById<UserCompanies[]>(`${this.controllerAccount}/userCompanies`, userId);
  }

  getUserCompaniesByCompany(userId: string): Observable<UserCompanies[]> {
    return this.getByCompanyId<UserCompanies[]>(`${this.controllerAccount}/userCompanies`, this.userAtualErpPermissions.companyId, userId);
  }

  getUserCompaniesAndCompanies(userId: string): Observable<[UserCompanies[], Company[]]> {
    return forkJoin([
      this.getById<UserCompanies[]>(`${this.controllerAccount}/userCompanies`, userId),
      this.getList<Company[]>(`${this.controllerCustomer}/${this.endPointCompany}`)
    ]);
  }
  //#endregion

  //#region Post
  newUserCompany(entity: UserCompanies): Observable<UserCompanies> {
    return this.post<UserCompanies>(`${this.controllerAccount}/userCompany`, entity);
  }
  //#endregion

  //#region Put
  updateUserCompany(entity: UserCompanies): Observable<UserCompanies> {
    return this.put<UserCompanies>(`${this.controllerAccount}/userCompany/${entity.id}`, entity);
  }
  //#endregion

  //#region Delete
  deleteUserCompany(id: number): Observable<UserCompanies> {
    return this.delete<UserCompanies>(`${this.controllerAccount}/userCompany`, id);
  }
  //#endregion
  //#endregion
}
