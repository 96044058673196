export class Attachament {
  public id?: number;
  public companyId?: number;
  public orderId?: number;
  public purchaseId?: number;
  public numLine?: number;
  public tiposDocAttach?: string;

  public idAttach?: string;
  public key?: string;

  public name?: string;
  public fileName?: string;
  public data?: string;
  public extension?: string;
  public fileBase64?: string;
  public typeFile?: string;

  public fileData?: string;
  public idCompra?: string;
  public idSale?: string;

  public indexRows?: number;
}
