import { PermissionValues } from '../role/permission.model';
import { UserCompanies } from '../companies/user-companies.model';
import { UserErpPermission } from '../user/user-erp-permission.model';

export interface AccessToken
{
  nbf: number;
  exp: number;
  iss: string;
  aud: string | string[];
  client_id: string;
  sub: string;
  auth_time: number;
  idp: string;
  role: string | string[];
  companies: string | UserCompanies[];
  permission: PermissionValues | PermissionValues[];
  userErpPermission: string | UserErpPermission[];
  name: string;
  email: string;
  phone_number: string;
  fullname: string;
  jobtitle: string;
  configuration: string;
  scope: string | string[];
  amr: string[];
}
