import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-block',
  styleUrls: ['./auth-block.component.scss'],
  templateUrl: './auth-block.component.html',
})
export class AuthBlockComponent implements OnInit {

  ngOnInit() {
  }
}
