import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
  UtilitiesImage,
} from './utils';

import {
  AuthService, AppInfoService, PageMenu, CartService, ApiService,
  AppErrorHandler, AppTitleService, AppTranslationService, ConfigurationService, AlertService, ThemeManager,
  LocalStoreManager, OidcHelperService, NotificationService, NotificationEndpoint, AccountService, AccountEndpoint,
  TranslateLanguageLoader, CustomerEndpoint, CustomerService, SaleService, SaleEndpoint, ClientService,
  OrderService, PurchaseService, PurchaseEndpoint, PurchasesService,
  ProductService, ReportService, UsersService,AuditService,LogService
} from './services';

import { AuthGuard, AuthPermissions, EmptyCartGuard } from './guards';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxImageCompressService } from 'ngx-image-compress';

const Services = [
  AnalyticsService, PlayerService, SeoService, StateService, CartService, ApiService,
  AuthService, AuthGuard, AuthPermissions, EmptyCartGuard, AppInfoService, AppTitleService, AppTranslationService, ConfigurationService,
  AlertService, ThemeManager, LocalStoreManager, OidcHelperService, NotificationService, NotificationEndpoint,
  AccountService, AccountEndpoint, CustomerService, CustomerEndpoint, SaleService, SaleEndpoint,
  LayoutService, PageMenu, ClientService, UsersService, PurchaseService, PurchaseEndpoint, PurchasesService,
  OrderService, ProductService, ReportService, UtilitiesImage, NgxImageCompressService, AuditService, LogService
];

const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },
];

const DATA_SERVICES = [
  { provide: ErrorHandler, useClass: AppErrorHandler },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  NgxDatatableModule, NgSelectModule,
  ...Services,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    OAuthModule.forRoot()
  ],
  exports: [
    TranslateModule, OAuthModule
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
