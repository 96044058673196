import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth';
import { ConfigurationService } from '../conf';
import { ApiBaseService } from '../api/api-base.service';
import { Sale, SalesLines, UserErpPermission } from '../../models';

@Injectable()
export class OrderService extends ApiBaseService {

  private endpoint: string = `api/order/salesDraft`;
  private endpointLine: string = `api/order/salesLineDraft`;
  private apiEndpoint: string = `orders`;
  //private company: UserErpPermission;

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService) {
    super(configurations, http, authService);
    //this.company = this.userAtualErpPermissions;
  }


  //#region get
  getOrderByCompanyId<T>(companyId: number, id: any): Observable<T> {
    return this.getByCompanyId<T>(this.endpoint, companyId, id);
  }

  getOrdersByCompanyId<T>(companyId: number): Observable<T> {
    return this.getListByCompanyId<T>(this.endpoint, companyId);
  }

  getListByCompanyId<T>(endPoint: string, companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/${endPoint}/${companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/${endPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getListByCompanyId(endPoint, companyId, page, pageSize));
      }));
  }
  //#endregion

  //#region order
  getOrder(id: number): Observable<Sale> {
    return this.getByCompanyId<Sale>(this.endpoint, this.userAtualErpPermissions.companyId, id);
  }

  createOrder(order?: Sale): Observable<Sale> {
    if (!order) {
      order = new Sale();
    }
    order.idCompany = this.userAtualErpPermissions.companyId;
    return this.postByCompanyId<Sale>(this.endpoint, this.userAtualErpPermissions.companyId, order);
  }

  updateOrder(order: Sale): Observable<Sale> {
    order.idCompany = this.userAtualErpPermissions.companyId;
    return this.patchByCompanyId<Sale>(this.endpoint, this.userAtualErpPermissions.companyId, order);
  }

  //#endregion


  //#region line
  createLineItem(lineItem: SalesLines): Observable<SalesLines> {
    return this.postByCompanyId<SalesLines>(this.endpointLine, this.userAtualErpPermissions.companyId, lineItem);
  }

  getLineItem(id: any): Observable<SalesLines> {
    return this.getByCompanyId<SalesLines>(this.endpointLine, this.userAtualErpPermissions.companyId, id);
  }

  updateLineItem(id: number, quantity: number): Observable<SalesLines> {
    let _line: SalesLines = new SalesLines();
    _line.idOrderLine = id;
    _line.quantidade = quantity;

    return this.patchByCompanyId<SalesLines>(this.endpoint, this.userAtualErpPermissions.companyId, _line);
  }

  deleteLineItem(id: number): Observable<SalesLines> {
    return this.deleteByCompanyId<SalesLines>(this.endpoint, this.userAtualErpPermissions.companyId, id);
  }
  //#endregion


  newEntity<T>(companyId: number, entity: any): Observable<T> {
    return this.postByCompanyId<T>(this.endpoint, companyId, JSON.stringify(entity));
  }

  updateEntity<T>(companyId: number, entity: any): Observable<T> {
    return this.putByCompanyId<T>(this.endpoint, companyId, JSON.stringify(entity));
  }

  deleteEntity<T>(companyId: number, id : any): Observable<T> {
    return this.deleteByCompanyId(this.endpoint, companyId, id);
  }


  //#region post
  post<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.post(endPoint, entity));
      }));
  }

  postByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postByCompanyId(endPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region put
  put<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.put(endPoint, entity));
      }));
  }

  putByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putByCompanyId(endPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region patch
  patch<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patch(endPoint, entity));
      }));
  }

  patchByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchByCompanyId(endPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region delete
  delete<T>(endPoint: string, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.delete(endPoint, id));
      }));
  }

  deleteByCompanyId<T>(endPoint: string, companyId: number, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteByCompanyId(endPoint, companyId, id));
      }));
  }
  //#endregion
}
