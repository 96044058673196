import { Company } from '../companies/company.model';
import { Image } from '../images/image.model';
import { CatalogCategory } from './catalog-category.model';

export class CategoryEntension
{
  idImage: number;
  image: Image;
  imageTitle: string;

  idCompany: number;
  company: Company;
  companyName: string;
  idCatalogsCategories: number;
  catalogsCategory: CatalogCategory;
  name: string;
  title: string;
  enabled: boolean;
  data: string;
  imageBase64: string;
  imageData: string;
  imgPath: string;
  erpProduct: string;
  isProduct: boolean;
  icon: string;
  itemIndex: number;
}
