import { CategoryEntension } from '../catalog/catalog-extension.model';
import { Catalog } from '../catalog/catalog.model';

export class ProductCatalog extends CategoryEntension
{
  idProductCatalog: number;
  id: string;
  idCatalog: number;
  catalogs: Catalog;
  idParent: string;
  rowIndex: number;
  index: number;
  maxIndex: number;
  indexCatalog: string;
  isDirectory: boolean;
  expanded: boolean;
  groupName: string;
  isErpProducts: boolean;
  parentName: string;
  products: ProductCatalog[];
}
