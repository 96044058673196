import { MoradasAlternativa } from '../entidades/moradaalternativa.model';
import { ApprovedPurchase, CancelPurchase, PurchaseLines } from '.';
import { Approval } from '../approval/approval.model';
import { Attachament, AuditableEntity } from '../commons';
import { RevisionPurchase } from './revision.purchase.model';

export class Purchase extends AuditableEntity {
  isSelect?: boolean;
  idPurchase?: number;
  idCompany?: number;
  idPurchasesType?: number;
  id?: string;
  tipoDoc?: string;
  tipoDocNome?: string;
  serie?: string;
  numDoc?: number;
  filial?: string;
  data?: Date;
  entidade?: string;
  tipoEntidade?: string = 'F';
  modoPag?: string;
  condPag?: string;
  entidadeDescription?: string;
  entidadeAssociadosDescription?: string;
  nome?: string;
  morada?: string;
  morada2?: string;
  morada3?: string;
  localidade?: string;
  distrito?: string;
  codigoPostal?: string;
  localidadeCodPostal?: string;
  pais?: string;
  nif?: string;
  telefone?: string;
  email?: string;
  comMKT?: string;
  nacionalidade?: string;
  paisEmissaoId?: string;
  tipoId?: string;
  numBicc?: string;
  dataEmissaoId?: Date;
  dataValidadeId?: Date;
  birthDate?: Date;
  iban?: string;
  codigoLocalizacao?: string;
  pep?: boolean;
  cargo?: string;
  relacaoPep?: string;
  situacaoProf?: string;
  areaAtividade?: string;
  entidadePat?: string;
  numClienteTrader?: string;
  origemPecas?: string;
  propositoTrans?: string;
  entidadeEntrega?: string;
  tipoEntidadeEntrega?: string = 'F';
  moradaAltEntrega?: string;
  moradaAltEntregaDescription?: string;
  descontoFinanceiro?: number;
  totalDocumento?: number;
  totalMerc?: number;
  totalDesc?: number;
  totalLiquido?: number;
  estado?: string;
  viewEstado?: string;
  referencia?: string;
  dataHoraDescarga?: Date;
  vendedor?: string;
  vendedorNome?: string;
  observacoes?: string;
  paisEncomenda?: string;
  purchaseType?: number;
  tteEntidade?: string;
  level2?: string;
  lines?: PurchaseLines[];
  moradasAlternativas?: MoradasAlternativa[];
  cancelPurchase?: CancelPurchase;
  revisionPurchase?: RevisionPurchase;
  approvedPurchase?: ApprovedPurchase;
  approvals?: Approval[];
  attachaments?: Attachament[];
  pdf?: string;
  templateReport?: any;
  isPrint?: boolean;
  printCount?: number;
  giftCardCode?: string;
  couponCode?: string;
  isSaving?: boolean;
}
