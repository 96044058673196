import { Company } from '../companies/company.model';
import { RoleApprovalWorkflow } from '../role/role-approval-workflow.model';

export class ApprovalWorkflow
{
  id: number;
  idCompany: number;
  company: Company;
  name: string;
  description: string;
  enabled: boolean;
  fields: Array<any>;
  roleApproval: RoleApprovalWorkflow[];
}
