export class UserCompanies {
  constructor(userId?: string, companyId?: number, key?: string, keyName?: string) {
    this.userId = userId;
    this.companyId = companyId;
    this.key = key;
    this.keyName = keyName;
  }

  public id?: number;
  public userId?: string;
  public userName?: string;

  public companyId?: number;
  public companyName?: string;

  public key?: string;
  public keyName?: string;

  public provider?: string;
  public providerCompany?: string;

  public ordersTypeId?: number;

  public tipoDoc?: string;
  public tipoDocName?: string;

  public serie?: string;
  public serieName?: string;
}
