import { TiposDocAttach } from '../commons';

export class ClientRules {
  id?: number;
  companyId?: number;
  nif?: string;
  valorAcumulado?: number;
  locked?: boolean;
  lockedReason?: string;
  alert?: boolean;
  alertReason?: string;
  docsRequired?: TiposDocAttach[];
}
