import { Company } from '../companies/company.model';
import { Sale } from '../sales/sale.model';
import { User } from '../user/user.model';
import { RoleApproval } from '../role/role-approval.model';

export class Approval
{
  id: number;
  idCompany: number;
  company: Company;
  idOrder: number;
  order: Sale;
  idOrderErp: string;
  idUser: string;
  user: User;
  orderApproval: number;
  rejectionReason: string;
  estado: string;
  date: Date;

  roleApproval: RoleApproval[];
}
