import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth';
import { ConfigurationService } from '../conf';
import { ApiBaseService } from '../api/api-base.service';
import { Artigos, UserErpPermission, ProductCategory } from '../../models';

@Injectable()
export class ProductService extends ApiBaseService {

  private endpoint: string = `api/Api/generic`;
  private apiEndpoint: string = `products`;
  private apiCategoryEndpoint: string = `productsCategories`;
  //private company: UserErpPermission;

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService) {
    super(configurations, http, authService);
    //this.company = this.userAtualErpPermissions;
  }

  //#region get
  getProduct(id: any): Observable<Artigos> {
    return this.getByEndPointCompanyId<Artigos>(this.endpoint, this.apiEndpoint, this.userAtualErpPermissions.companyId, id);
  }

  getProductsPage(page: number, pageSize: number): Observable<Artigos[]> {
    return this.getListByEndPointCompanyId<Artigos[]>(this.endpoint, this.apiEndpoint, this.userAtualErpPermissions.companyId, page, pageSize);
  }

  getProducts(): Observable<Artigos[]> {
    return this.getListByEndPointCompanyId<Artigos[]>(this.endpoint, this.apiEndpoint, this.userAtualErpPermissions.companyId);
  }
  //#endregion


  getCategories(): Observable<ProductCategory[]> {
    return this.getListByEndPointCompanyId<ProductCategory[]>(this.endpoint, this.apiCategoryEndpoint, this.userAtualErpPermissions.companyId);
  }
}
