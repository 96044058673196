import { RoleFilters } from './role-filters.model';
import { RoleApproval } from './role-approval.model';

export class RoleApprovalWorkflow
{
  id: number;
  idParent: number;
  title: string;
  category: string;
  type: string;
  filter: RoleFilters;
  approval: RoleApproval;
}
