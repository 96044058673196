import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth';
import { ConfigurationService } from '../conf';
import { ApiBaseService } from '../api/api-base.service';
import { Entidade, MoradasAlternativa, UserErpPermission } from '../../models';

@Injectable()
export class ApiService extends ApiBaseService {

  private readonly endPoints: string = 'generics';
  private readonly endPoint: string = 'generics';
  //private company: UserErpPermission;

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService) {
    super(configurations, http, authService);
    //this.company = this.userAtualErpPermissions;
  }


  //#region get
  getByIdCompanyId<T>(id: any, apiEndPoint: string): Observable<T> {
    return this.getByEndPointCompanyId<T>(`${this.controllerApi}/${this.endPoint}`, apiEndPoint, this.userAtualErpPermissions.companyId, id);
  }

  getListByIdCompanyId<T>(id: any, apiEndPoint: string): Observable<T> {
    return this.getByEndPointCompanyId<T>(`${this.controllerApi}/${this.endPoints}`, apiEndPoint, this.userAtualErpPermissions.companyId, id);
  }

  getList<T>(apiEndPoint: string, page?: number, pageSize?: number): Observable<T> {
    return this.getListByEndPointCompanyId(`${this.controllerApi}/${this.endPoints}`, apiEndPoint, this.userAtualErpPermissions.companyId, page, pageSize);
  }
  getListByCompanyId<T>(endPoint: string, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/${endPoint}/${this.userAtualErpPermissions.companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/${endPoint}/${this.userAtualErpPermissions.companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getListByCompanyId(endPoint, page, pageSize));
      }));
  }
  //#endregion

  newEntity<T>(entity: any, apiEndPoint: string): Observable<T> {
    return this.postByEndPointCompanyId<T>(`${this.controllerApi}/generic`, apiEndPoint, this.userAtualErpPermissions.companyId, entity);
  }

  updateEntity<T>(entity: any, apiEndPoint: string): Observable<T> {
    return this.putByEndPointCompanyId<T>(`${this.controllerApi}/generic`, apiEndPoint, this.userAtualErpPermissions.companyId, entity);
  }

  deleteEntity<T>(id: any, apiEndPoint: string): Observable<T> {
    return this.deleteByEndPointCompanyId(`${this.controllerApi}/generic`, apiEndPoint, this.userAtualErpPermissions.companyId, id);
  }


  //#region post
  post<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.post(endPoint, entity));
      }));
  }

  postByCompanyId<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${this.userAtualErpPermissions.companyId}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postByCompanyId(endPoint, entity));
      }));
  }
  //#endregion

  //#region put
  put<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.put(endPoint, entity));
      }));
  }

  putByCompanyId<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${this.userAtualErpPermissions.companyId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putByCompanyId(endPoint, entity));
      }));
  }
  //#endregion

  //#region patch
  patch<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patch(endPoint, entity));
      }));
  }

  patchByCompanyId<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${this.userAtualErpPermissions.companyId}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchByCompanyId(endPoint, entity));
      }));
  }
  //#endregion

  //#region delete
  delete<T>(endPoint: string, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.delete(endPoint, id));
      }));
  }

  deleteByCompanyId<T>(endPoint: string, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${this.userAtualErpPermissions.companyId}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteByCompanyId(endPoint, id));
      }));
  }
  //#endregion
}
