import { UnidadesConversao } from './unidades-conversao.model';

export class Unidades
{
  constructor( unidade?: string, descricao?: string, arredondamento?: number, unidadesConversao?: UnidadesConversao[] )
  {
    this.unidade = unidade;
    this.descricao = descricao;
    this.arredondamento = arredondamento;
    this.unidadesConversao = unidadesConversao;
  }

  unidade: string;
  descricao: string;
  descricaoEn?: string;
  descricaoDe?: string;
  arredondamento: number;
  unidadesConversao: UnidadesConversao[];
}
