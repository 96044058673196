import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { CustomerEndpoint } from './customer-endpoint.service';
import { User, Role, Salesman, UserErpPermission, Company, ErpCompany, Catalog, AppPortal, MenuItem, Image, CatalogCategory, ApprovalWorkflow, Entidade } from '../../models';
import { ApiBaseService, ConfigurationService, AuthService } from '..';


export type CompaniesChangedOperation = 'add' | 'delete' | 'modify';
export interface CompaniesChangedEventArg { companies: Company[] | number[]; operation: CompaniesChangedOperation; }

export type CatalogsChangedOperation = 'add' | 'delete' | 'modify';
export interface CatalogsChangedEventArg { catalogs: Catalog[] | number[]; operation: CatalogsChangedOperation; }

export type GenericChangedOperation = 'add' | 'delete' | 'modify';
export interface GenericChangedEventArg { entities: any[] | number[]; operation: GenericChangedOperation; }

export type AppPortalChangedOperation = 'add' | 'delete' | 'modify';
export interface AppPortalChangedEventArg { appPortals: AppPortal[] | number[]; operation: AppPortalChangedOperation; }


export type CustomerChangedOperation = 'add' | 'delete' | 'modify';
export interface CustomerChangedEventArg { entities: any[] | number[]; operation: CustomerChangedOperation; }


@Injectable()
export class CustomerService {

  public static readonly companyAddedOperation: CompaniesChangedOperation = 'add';
  public static readonly companyDeletedOperation: CompaniesChangedOperation = 'delete';
  public static readonly companyModifiedOperation: CompaniesChangedOperation = 'modify';

  public static readonly catalogAddedOperation: CatalogsChangedOperation = 'add';
  public static readonly catalogDeletedOperation: CatalogsChangedOperation = 'delete';
  public static readonly catalogModifiedOperation: CatalogsChangedOperation = 'modify';

  public static readonly genericAddedOperation: GenericChangedOperation = 'add';
  public static readonly genericDeletedOperation: GenericChangedOperation = 'delete';
  public static readonly genericModifiedOperation: GenericChangedOperation = 'modify';

  public static readonly appPortalAddedOperation: AppPortalChangedOperation = 'add';
  public static readonly appPortalDeletedOperation: AppPortalChangedOperation = 'delete';
  public static readonly appPortalModifiedOperation: AppPortalChangedOperation = 'modify';

  public static readonly customerAddedOperation: CustomerChangedOperation = 'add';
  public static readonly customerDeletedOperation: CustomerChangedOperation = 'delete';
  public static readonly customerModifiedOperation: CustomerChangedOperation = 'modify';

  private _companiesChanged = new Subject<CompaniesChangedEventArg>();
  private _catalogsChanged = new Subject<CatalogsChangedEventArg>();
  private _genericChanged = new Subject<GenericChangedEventArg>();
  private _appPortalChanged = new Subject<AppPortalChangedEventArg>();
  private _customerChanged = new Subject<CustomerChangedEventArg>();

  private company: UserErpPermission;
  private readonly endPointCompany: string = 'companies';

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService, private customerEndpoint: CustomerEndpoint) {
    // super(configurations, http, authService);

    this.company = this.customerEndpoint.userAtualErpPermissions;
  }

  //#region Company
  ///
  // Company
  ///
  getCompany(id: number): Observable<Company> {
    return this.customerEndpoint.getById<Company>(`${this.customerEndpoint.controllerCustomer}/${this.endPointCompany}`, id);
  }

  getCompanies(page?: number, pageSize?: number): Observable<Company[]> {
    return this.customerEndpoint.getList<Company[]>(`${this.customerEndpoint.controllerCustomer}/${this.endPointCompany}`, page, pageSize);
  }

  getCompaniesAndRoles(page?: number, pageSize?: number) {
    return forkJoin(
      this.customerEndpoint.getCustomersEndpoint<Company[]>('customer', 'companies', page, pageSize)
    );
  }

  patchByUrlByKey<T>(entity: any): Observable<T> {
    return this.customerEndpoint.patchGenericByUrlByKey<T>(`api/api/generic`, entity);
  }

  updateComapany(company: Company) {
    return this.updateCustomerByTag<Company>('customer', 'companies', company, company.id, 'companies/name', company.name);
  }

  newComapany(company: Company) {
    return this.newCustomer<Company>('customer', 'companies', company);
  }

  deleteComapany(companyOrCompanyId: number | Company): Observable<Company> {
    if (typeof companyOrCompanyId === 'number' || companyOrCompanyId instanceof Number) {
      return this.customerEndpoint.getDeleteCompanyEndpoint<Company>(companyOrCompanyId as number).pipe<Company>(
        tap(data => this.onCompaniesChanged([data], CustomerService.companyDeletedOperation)));
    } else {

      if (companyOrCompanyId.id) {
        return this.deleteComapany(companyOrCompanyId.id);
      } else {
        return this.customerEndpoint.getCompanyByCompanyNameEndpoint<Company>(companyOrCompanyId.name).pipe<Company>(
          mergeMap(campany => this.deleteComapany(campany.id)));
      }
    }
  }

  private onCompaniesChanged(companies: Company[] | number[], op: CompaniesChangedOperation) {
    this._companiesChanged.next({ companies, operation: op });
  }

  onCompanyRoleCountChanged(companies: Company[] | number[]) {
    return this.onCompaniesChanged(companies, CustomerService.companyModifiedOperation);
  }

  getCompaniesChangedEvent(): Observable<CompaniesChangedEventArg> {
    return this._companiesChanged.asObservable();
  }
  //#endregion

  //#region ApprovalWorflow
  getApprovalWorflowFields(companyId: number) {
    return this.customerEndpoint.getCustomerByIdEndpoint<any[]>('customer', 'fieldsApprovalWorkflowsByCompanyId', companyId);
  }

  getApprovalWorkFlowUsersRoles(companyId: number) {
    return forkJoin(
      this.customerEndpoint.getCustomersByCompanydIdEndpoint<User[]>('account', 'users/company', companyId),
      this.customerEndpoint.getCustomersByCompanydIdEndpoint<Role[]>('account', 'roles/company', companyId)
    );
  }
  getApprovalWorflow(approvalId: number) {
    return this.customerEndpoint.getCustomerByIdEndpoint<ApprovalWorkflow>('customer', 'approvalWorkflow', approvalId);
  }

  getApprovalWorflows(page?: number, pageSize?: number) {
    return forkJoin(
      this.customerEndpoint.getCustomersEndpoint<ApprovalWorkflow[]>('customer', 'approvalWorkflows', page, pageSize),
      this.customerEndpoint.getCompaniesEndpoint<Company[]>(page, pageSize));
  }

  updateApprovalWorflow(approval: ApprovalWorkflow) {
    return this.updateCustomerByTag<ApprovalWorkflow>('customer', 'approvalWorkflow', approval, approval.id, 'approvalWorkflow/name', approval.name);
  }

  newApprovalWorflow(approval: ApprovalWorkflow) {
    return this.newCustomer<ApprovalWorkflow>('customer', 'approvalWorkflow', approval);
  }

  deleteApprovalWorflow(approval: ApprovalWorkflow): Observable<ApprovalWorkflow> {
    return this.deleteCustomerByTag<ApprovalWorkflow>('customer', 'approvalWorkflow', approval, approval.id, 'approvalWorkflow/name', approval.name);
  }
  //#endregion

  //#region Erp Company
  ///
  // Erp Company
  ///
  getAllErpCompanies() {
    return this.customerEndpoint.getAllErpCompaniesEndpoint<ErpCompany[]>();
  }

  getAllErpCompaniesByUrl(apiEndPoint: string, token?: string) {
    return this.customerEndpoint.getAllErpCompaniesByUrlEndpoint<ErpCompany[]>(apiEndPoint, token);
  }
  //#endregion

  //#region Catalog

  //#region Catalog
  ///
  // Catalog
  ///

  //#region Get
  getCatalog(catalogId: number) {
    return this.customerEndpoint.getCatalogEndpoint<Catalog>(catalogId);
  }

  getCatalogs(page?: number, pageSize?: number) {
    return forkJoin(
      this.customerEndpoint.getCatalogsEndpoint<Catalog[]>(page, pageSize),
      this.customerEndpoint.getCompaniesEndpoint<Company[]>(page, pageSize));
  }

  getCatalogsByCompanydId(companyId: number, page?: number, pageSize?: number) {
    return this.customerEndpoint.getCatalogsByCompanydIdEndpoint<Catalog[]>(companyId, page, pageSize);
  }

  getCatalogsByDate(companyId: number, dateFrom?: Date, dateTo?: Date) {
    return this.customerEndpoint.getCatalogsByDateEndpoint<Catalog[]>(companyId, dateFrom, dateTo);
  }

  //#endregion

  //#region Add
  newCatalog(catalog: Catalog) {
    return this.customerEndpoint.postNewCatalogEndpoint<Catalog>(catalog).pipe<Catalog>(
      tap(data => this.onCatalogsChanged([catalog], CustomerService.catalogAddedOperation)));
  }
  //#endregion

  //#region Update
  updateCatalog(catalog: Catalog) {
    if (catalog.idCatalog) {
      return this.customerEndpoint.putUpdateCatalogEndpoint(catalog, catalog.idCatalog).pipe(
        tap(data => this.onCatalogsChanged([catalog], CustomerService.catalogModifiedOperation)));
    }
    else {
      return this.customerEndpoint.getCatalogEndpoint<Catalog>(catalog.idCatalog).pipe(
        mergeMap(foundRole => {
          catalog.idCatalog = foundRole.idCatalog;
          return this.customerEndpoint.getUpdateCompanyEndpoint(catalog, catalog.idCatalog);
        }),
        tap(data => this.onCatalogsChanged([catalog], CustomerService.catalogModifiedOperation)));
    }
  }
  //#endregion

  //#region Delete
  deleteCatalog(catalogOrCatalogId: number | Catalog): Observable<Catalog> {
    if (typeof catalogOrCatalogId === 'number' || catalogOrCatalogId instanceof Number) {
      return this.customerEndpoint.deleteCatalogEndpoint<Catalog>(catalogOrCatalogId as number).pipe<Catalog>(
        tap(data => this.onCatalogsChanged([data], CustomerService.catalogDeletedOperation)));
    }
    else {
      if (catalogOrCatalogId.idCatalog) {
        return this.deleteCatalog(catalogOrCatalogId.idCatalog);
      }
      else {
        return this.customerEndpoint.getCatalogEndpoint<Catalog>(catalogOrCatalogId.idCatalog).pipe<Catalog>(
          mergeMap(catalog => this.deleteCatalog(catalog.idCatalog)));
      }
    }
  }
  //#endregion

  //#region events
  private onCatalogsChanged(catalogs: Catalog[] | number[], op: CatalogsChangedOperation) {
    this._catalogsChanged.next({ catalogs, operation: op });
  }

  onCatalogRoleCountChanged(catalogs: Catalog[] | number[]) {
    return this.onCatalogsChanged(catalogs, CustomerService.catalogModifiedOperation);
  }

  getCatalogsChangedEvent(): Observable<CatalogsChangedEventArg> {
    return this._catalogsChanged.asObservable();
  }
  //#endregion
  //#endregion

  //#region Catalog Generic
  ///
  // Catalog
  ///
  getProductCatalogImage(companyId: number, idCatalog: number) {
    return forkJoin(
      this.customerEndpoint.getGenericEndpoint<Catalog>('catalog', idCatalog),
      this.customerEndpoint.getByCompanydIdGenericEndpoint<Image[]>('images', companyId),
    );
  }

  getProductCatalogImageByCompanyId(companyId: number) {
    return forkJoin(
      this.customerEndpoint.getGenericEndpoint<Catalog>('catalogProduct', companyId),
      this.customerEndpoint.getByCompanydIdGenericEndpoint<Image[]>('images', companyId),
    );
  }

  getCatalogCategories() {
    return this.customerEndpoint.getGenericsEndpoint<CatalogCategory[]>('catalogsCategories');
  }

  getCatalogCategoriesImage(companyId: number) {
    return forkJoin(
      this.customerEndpoint.getGenericsEndpoint<CatalogCategory[]>('catalogsCategories'),
      this.customerEndpoint.getByCompanydIdGenericEndpoint<Image[]>('images', companyId)
    );
  }
  //#endregion

  //#endregion

  //#region generic
  //#region Get
  getGeneric<T>(endPoint: string, id: number) {
    return this.customerEndpoint.getGenericEndpoint<T>(endPoint, id);
  }

  getGenerics<T>(endPoint: string, page?: number, pageSize?: number) {
    return this.customerEndpoint.getGenericsEndpoint<T[]>(endPoint, page, pageSize);
  }

  getGenericsWithCompanies<T>(endPoint: string, page?: number, pageSize?: number) {
    return forkJoin(
      this.customerEndpoint.getGenericsEndpoint<T[]>(endPoint, page, pageSize),
      this.customerEndpoint.getCompaniesEndpoint<Company[]>(page, pageSize));
  }

  getByCompanydIdGeneric<T>(endPoint: string, companyId: number, page?: number, pageSize?: number) {
    return this.customerEndpoint.getByCompanydIdGenericEndpoint<T[]>(endPoint, companyId, page, pageSize);
  }

  getByCompanydIdDateGeneric<T>(endPoint: string, companyId: number, dateFrom?: Date, dateTo?: Date) {
    return this.customerEndpoint.getByCompanydIdDateGenericEndpoint<T[]>(endPoint, companyId, dateFrom, dateTo);
  }
  //#endregion

  //#region Add
  newGeneric<T>(endPoint: string, entityObject: any) {
    return this.customerEndpoint.postNewGenericEndpoint<T>(endPoint, entityObject).pipe<T>(
      tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericAddedOperation)));
  }

  newGenericFile<T>(endPoint: string, entityObject: any) {
    return this.customerEndpoint.postNewGenericFileEndpoint<T>(endPoint, entityObject).pipe<T>(
      tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericAddedOperation)));
  }
  //#endregion

  //#region Update
  updateGeneric<T>(endPoint: string, entityObject: any, id: number) {
    if (id) {
      return this.customerEndpoint.putUpdateGenericEndpoint<T>(endPoint, entityObject, id).pipe<T>(
        tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericModifiedOperation)));
    }
    else {
      return this.customerEndpoint.getGenericEndpoint<T>(endPoint, id).pipe(
        mergeMap(foundRole => {
          return this.customerEndpoint.putUpdateGenericEndpoint<T>(endPoint, entityObject, id);
        }),
        tap<T>(d => this.onGenericChanged([entityObject], CustomerService.genericModifiedOperation)));
    }
  }
  //#endregion

  //#region Delete
  deleteGeneric<T>(endPoint: string, entityOrenttyId: number | any): Observable<T> {
    if (typeof entityOrenttyId === 'number' || entityOrenttyId instanceof Number) {
      return this.customerEndpoint.deleteGenericEndpoint<T>(endPoint, entityOrenttyId as number).pipe<T>(
        tap(data => this.onGenericChanged([data], CustomerService.genericDeletedOperation)));
    }
    // else
    // {
    //  if ( catalogOrCatalogId.idCatalog )
    //  {
    //    return this.deleteCatalogGeneric( catalogOrCatalogId.idCatalog );
    //  }
    //  else
    //  {
    //    return this.customerEndpoint.getCatalogEndpoint<Catalog>( catalogOrCatalogId.idCatalog ).pipe<Catalog>(
    //      mergeMap( catalog => this.deleteCatalogGeneric( catalog.idCatalog ) ) );
    //  }
    // }
  }
  //#endregion

  //#region events
  private onGenericChanged(entities: any[] | number[], op: GenericChangedOperation) {
    this._genericChanged.next({ entities, operation: op });
  }

  onGenericCountChanged(entities: any[] | number[]) {
    return this.onGenericChanged(entities, CustomerService.genericModifiedOperation);
  }

  getGenericChangedEvent(): Observable<GenericChangedEventArg> {
    return this._genericChanged.asObservable();
  }
  //#endregion
  //#endregion

  //#region AppPortal
  ///
  // AppPortal
  ///

  //#region Get
  getAppPortal(appPortalId: number) {
    return this.customerEndpoint.getAppPortalEndpoint<AppPortal>(appPortalId);
  }

  getAppPortals(page?: number, pageSize?: number) {
    return this.customerEndpoint.getAppPortalsEndpoint<AppPortal[]>(page, pageSize);
  }
  //#endregion

  //#region Add
  newAppPortal(appPortal: AppPortal) {
    return this.customerEndpoint.postNewAppPortalEndpoint<AppPortal>(appPortal).pipe<AppPortal>(
      tap(data => this.onAppPortalChanged([appPortal], CustomerService.appPortalAddedOperation)));
  }
  //#endregion

  //#region Update
  updateAppPortal(appPortal: AppPortal) {
    if (appPortal.IdPortal) {
      return this.customerEndpoint.putUpdateAppPortalEndpoint(appPortal, appPortal.IdPortal).pipe(
        tap(data => this.onAppPortalChanged([appPortal], CustomerService.appPortalModifiedOperation)));
    }
    else {
      return this.customerEndpoint.getAppPortalEndpoint<AppPortal>(appPortal.IdPortal).pipe(
        mergeMap(foundRole => {
          appPortal.IdPortal = foundRole.IdPortal;
          return this.customerEndpoint.putUpdateAppPortalEndpoint(appPortal, appPortal.IdPortal);
        }),
        tap(data => this.onAppPortalChanged([appPortal], CustomerService.appPortalModifiedOperation)));
    }
  }
  //#endregion

  //#region Delete
  deleteAppPortal(appPortalOrAppPortalId: number | AppPortal): Observable<AppPortal> {
    if (typeof appPortalOrAppPortalId === 'number' || appPortalOrAppPortalId instanceof Number) {
      return this.customerEndpoint.deleteAppPortalEndpoint<AppPortal>(appPortalOrAppPortalId as number).pipe<AppPortal>(
        tap(data => this.onAppPortalChanged([data], CustomerService.appPortalDeletedOperation)));
    }
    else {
      if (appPortalOrAppPortalId.IdPortal) {
        return this.deleteAppPortal(appPortalOrAppPortalId.IdPortal);
      }
      else {
        return this.customerEndpoint.getAppPortalEndpoint<AppPortal>(appPortalOrAppPortalId.IdPortal).pipe<AppPortal>(
          mergeMap(appPortal => this.deleteAppPortal(appPortal.IdPortal)));
      }
    }
  }
  //#endregion

  //#region events
  private onAppPortalChanged(appPortals: AppPortal[] | number[], op: AppPortalChangedOperation) {
    this._appPortalChanged.next({ appPortals, operation: op });
  }

  onAppPortalCountChanged(appPortals: AppPortal[] | number[]) {
    return this.onAppPortalChanged(appPortals, CustomerService.appPortalModifiedOperation);
  }

  getAppPortalChangedEvent(): Observable<AppPortalChangedEventArg> {
    return this._appPortalChanged.asObservable();
  }
  //#endregion
  //#endregion

  //#region Salesman
  getSalesmansAndCompanies() {
    return forkJoin(
      this.customerEndpoint.getCustomerEndpoint<Salesman[]>('order', 'salesman'),
      this.customerEndpoint.getCustomerEndpoint<Company[]>('customer', 'companies')
    );
  }
  //#endregion

  //#region Provider
  getProvidersAndCompanies() {
    return forkJoin(
      this.customerEndpoint.getListByEndPoint<Entidade[]>('api/api/generics', 'providers'),
      this.customerEndpoint.getCustomerEndpoint<Company[]>('customer', 'companies')
    );
  }
  //#endregion

  //#region Customer
  //#region Get
  getCustomer<T>(controller: string, endPoint: string) {
    return this.customerEndpoint.getCustomerEndpoint<T>(controller, endPoint);
  }
  async getCustomerAsync<T>(controller: string, endPoint: string): Promise<T> {
    return await this.getCustomer<T>(controller, endPoint).toPromise();
  }

  getCustomerById<T>(controller: string, endPoint: string, id: number, idCompany?: number) {
    if (idCompany) {
      return this.customerEndpoint.getCustomerByIdCompanyIdEndpoint<T>(controller, endPoint, id, idCompany);
    }
    else {
      return this.customerEndpoint.getCustomerByIdEndpoint<T>(controller, endPoint, id);
    }
  }
  async getCustomerByIdAsync<T>(controller: string, endPoint: string, id: number, idCompany?: number) {
    return await this.getCustomerById<T>(controller, endPoint, id, idCompany).toPromise();
  }

  getCustomerByIdByCompanyId<T>(controller: string, endPoint: string, id: number, idCompany: number) {
    return this.customerEndpoint.getCustomerByIdCompanyIdEndpoint<T>(controller, endPoint, id, idCompany);
  }

  getCustomers<T>(controller: string, endPoint: string, page?: number, pageSize?: number) {
    return this.customerEndpoint.getCustomersEndpoint<T[]>(controller, endPoint, page, pageSize);
  }

  getCustomersByCompanydId<T>(controller: string, endPoint: string, companyId: number, page?: number, pageSize?: number) {
    return this.customerEndpoint.getCustomersByCompanydIdEndpoint<T[]>(controller, endPoint, companyId, page, pageSize);
  }
  async getCustomersByCompanydIdAsync<T>(controller: string, endPoint: string, companyId: number, page?: number, pageSize?: number) {
    return await this.getCustomersByCompanydId<T>(controller, endPoint, companyId, page, pageSize).toPromise();
  }

  getLevel2ByCompanyid<T>(companyId: number) {
    return this.getCustomersByCompanydId<T>('order', 'level2', companyId);
  }

  getCustomersByCompanydIdSalesman<T>(controller: string, endPoint: string, companyId: number, salesman: string, page?: number, pageSize?: number) {
    return this.customerEndpoint.getCustomersByCompanydIdSalesmanEndpoint<T[]>(controller, endPoint, companyId, salesman, page, pageSize);
  }
  getCustomersByCompanydIdSalesmanDate<T>(controller: string, endPoint: string, companyId: number, salesman: string, dateFrom?: Date, dateTo?: Date) {
    return this.customerEndpoint.getCustomersByCompanydIdSalesmanDateEndpoint<T[]>(controller, endPoint, companyId, salesman, dateFrom, dateTo);
  }
  getCustomersByCompanydIdSalesmanOrderTypeDate<T>(controller: string, endPoint: string, id: any, companyId: number, salesman: string, orderType: number, estado: string, dateFrom?: Date, dateTo?: Date) {
    return this.customerEndpoint.getCustomersByCompanydIdSalesmanOrderTypeDateEndpoint<T[]>(controller, endPoint, id, companyId, salesman, orderType, estado, dateFrom, dateTo);
  }
  //#endregion

  //#region Add
  newCustomer<T>(controller: string, endPoint: string, entityObject: any) {
    return this.customerEndpoint.postNewCustomerEndpoint<T>(controller, endPoint, entityObject).pipe<T>(
      tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericAddedOperation)));
  }

  async newCustomerAsync<T>(controller: string, endPoint: string, entityObject: any): Promise<T> {
    return await this.customerEndpoint.postNewCustomerEndpoint<T>(controller, endPoint, entityObject).pipe<T>(
      tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericAddedOperation))).toPromise();
  }
  //#endregion

  //#region Update
  updateCustomer<T>(controller: string, endPoint: string, entityObject: any, id: number) {
    return this.updateCustomerByTag<T>(controller, endPoint, entityObject, id, '', '');
  }

  updateCustomerByTag<T>(controller: string, endPoint: string, entityObject: any, id: number, endPointTag: string, tag: any) {
    if (id) {
      return this.customerEndpoint.putUpdateCustomerEndpoint<T>(controller, endPoint, entityObject, id).pipe<T>(
        tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericModifiedOperation)));
    }
    else {
      return this.customerEndpoint.getCustomerByTagEndpoint<T>(controller, endPointTag, tag).pipe(
        mergeMap(foundRole => {
          const _value: any = foundRole;
          id = _value.id;
          return this.customerEndpoint.putUpdateCustomerEndpoint<T>(controller, endPoint, entityObject, id);
        }),
        tap<T>(d => this.onGenericChanged([entityObject], CustomerService.genericModifiedOperation)));
    }
  }

  async updateCustomerAsync<T>(controller: string, endPoint: string, entityObject: any, id: number): Promise<T> {
    return this.updateCustomerByTag<T>(controller, endPoint, entityObject, id, '', '').toPromise();
  }

  updateCustomerByCompanyId<T>(controller: string, endPoint: string, entityObject: any, id: number, companyId: number) {
    if (id) {
      return this.customerEndpoint.putUpdateCustomerByCompanyIdEndpoint<T>(controller, endPoint, entityObject, id, companyId).pipe<T>(
        tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericModifiedOperation)));
    } else {
      return this.customerEndpoint.getCustomerByIdCompanyIdEndpoint<T>(controller, endPoint, id, companyId).pipe(
        mergeMap(foundRole => {
          return this.customerEndpoint.putUpdateCustomerByCompanyIdEndpoint<T>(controller, endPoint, entityObject, id, companyId);
        }),
        tap<T>(d => this.onGenericChanged([entityObject], CustomerService.genericModifiedOperation)));
    }
  }

  async updateCustomerByCompanyIdAsync<T>(controller: string, endPoint: string, entityObject: any, id: number, companyId: number): Promise<T> {
    return this.updateCustomerByCompanyId<T>(controller, endPoint, entityObject, id, companyId).toPromise();
  }
  //#endregion

  //#region Delete
  deleteCustomer<T>(controller: string, endPoint: string, entityOrenttyId: number | any): Observable<T> {
    if (typeof entityOrenttyId === 'number' || entityOrenttyId instanceof Number) {
      return this.customerEndpoint.deleteCustomerEndpoint<T>(controller, endPoint, entityOrenttyId as number).pipe<T>(
        tap(data => this.onGenericChanged([data], CustomerService.genericDeletedOperation)));
    }
    // else
    // {
    //  if ( catalogOrCatalogId.idCatalog )
    //  {
    //    return this.deleteCatalogGeneric( catalogOrCatalogId.idCatalog );
    //  }
    //  else
    //  {
    //    return this.customerEndpoint.getCatalogEndpoint<Catalog>( catalogOrCatalogId.idCatalog ).pipe<Catalog>(
    //      mergeMap( catalog => this.deleteCatalogGeneric( catalog.idCatalog ) ) );
    //  }
    // }
  }
  deleteCustomerByTag<T>(controller: string, endPoint: string, entityObject: any, id: number, endPointTag: string, tag: any) {
    if (id) {
      return this.customerEndpoint.deleteCustomerEndpoint<T>(controller, endPoint, id).pipe<T>(
        tap<T>(data => this.onGenericChanged([entityObject], CustomerService.genericDeletedOperation)));
    } else {
      return this.customerEndpoint.getCustomerByTagEndpoint<T>(controller, endPointTag, tag).pipe(
        mergeMap(foundRole => {
          const _value: any = foundRole;
          id = _value.id;
          return this.customerEndpoint.deleteCustomerEndpoint<T>(controller, endPoint, id);
        }),
        tap<T>(d => this.onGenericChanged([entityObject], CustomerService.genericDeletedOperation)));
    }
  }
  //#endregion

  //#region events
  private onCustomerChanged(entities: any[] | number[], op: CustomerChangedOperation) {
    this._customerChanged.next({ entities, operation: op });
  }

  onCustomerCountChanged(entities: any[] | number[]) {
    return this.onCustomerChanged(entities, CustomerService.customerModifiedOperation);
  }

  getCustomerChangedEvent(): Observable<CustomerChangedEventArg> {
    return this._customerChanged.asObservable();
  }
  //#endregion
  //#endregion

  get currentUser() {
    return this.authService.currentUser;
  }

  get currentUserErpPermissions() {
    return this.authService.userErpPermissions;
  }

  //#region MenuItemCatalog
  public MenuCatalog(): MenuItem[] {
    return [
      { id: 'addlevel', text: 'Add Level' },
      { id: 'removelevel', text: 'Remove Level' },
      { id: 'expand', text: 'Expand' },
      { id: 'collapse', text: 'Collapse' },
      { id: 'expandall', text: 'Expand All' },
      { id: 'collapseall', text: 'Collapse All' },
      { id: 'move', text: 'Dragged and Dropped' },
      { id: 'delete', text: 'Delete Image' },
      { id: 'image', text: 'Image' }
    ];
  }
  //#endregion

  getFields(): Array<any> {
    return [
      {
        dataField: 'Name'
      },
      {
        dataField: 'Price',
        dataType: 'number',
        format: 'currency'
      },
      {
        dataField: 'Current_Inventory',
        dataType: 'number',
        caption: 'Inventory'
      },
      {
        dataField: 'Category',
        filterOperations: ['=', 'anyof'],
        lookup: {
          dataSource: this.getCategories()
        }
      }
    ];
  }

  getCategories(): string[] {
    return [
      'Video Players',
      'Televisions',
      'Monitors',
      'Projectors',
      'Automation'
    ];
  }
}
