import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';

import { SaleEndpoint } from './sale-endpoint.service';
import { CustomerEndpoint } from '../';
import { Utilities } from '../../utils';
import { AuthService } from '../auth/auth.service';
import { Sale, Entidade, Artigos, MoradasAlternativa, Documents, Unidades, UserErpPermission, ErpCompany, CancelSale, Image, PaisesEncomendas, ProductCatalog, Salesman, Company, SalesStatus, SalesType, TTEntidades, Report, ApprovalWorkflow } from '../../models';


@Injectable()
export class SaleService {

  constructor(private authService: AuthService, private saleEndpoint: SaleEndpoint, private customerEndpoint: CustomerEndpoint) { }

  //#region Sales
  //#region Get
  getSaleByFilters(id: any, companyId: number, salesman: string, orderType: number, internalOrderType: number, estado: string, level2: string) {
    return this.saleEndpoint.getSaleByFiltersEndpoint<Sale>(id, companyId, salesman, orderType, internalOrderType, estado, level2);
  }

  getSalesByFilters(companyId: number, salesman: string, orderType: number, estado: string, level2: string, dateFrom?: Date, dateTo?: Date) {
    return this.saleEndpoint.getSalesByFiltersEndpoint<Sale[]>(companyId, salesman, orderType, estado, level2, dateFrom, dateTo);
  }

  getSalesLoadData(idCompany: number) {
    return forkJoin(
      this.customerEndpoint.getCustomerByIdEndpoint<Unidades[]>('order', 'unidades', idCompany),
      this.customerEndpoint.getCustomerByIdEndpoint<PaisesEncomendas[]>('order', 'paisesEncomendas', idCompany),
      this.customerEndpoint.getCustomerByIdEndpoint<ApprovalWorkflow[]>('customer', 'approvalWorkflowsByCompanyId', idCompany)
    );
  }

  getSalesData(id: any, companyId: number, salesman: string, orderType: number, internalOrderType: number): Observable<[Sale, Documents[], Entidade[]]> {
    return forkJoin(
      id ? this.saleEndpoint.getSaleByFiltersEndpoint<Sale>(id, companyId, salesman, orderType, internalOrderType, 'All', 'All') : of(null),
      this.customerEndpoint.getCustomerByIdEndpoint<Documents[]>('order', 'documents', companyId),
      this.customerEndpoint.getCustomersByCompanydIdSalesmanEndpoint<Entidade[]>('order', 'entidadeBySaleman', companyId, salesman)
    );
  }

  getTopSales(idCompany: number, tipoDoc: string, serie: string, client: string) {
    return this.saleEndpoint.getTopSalesEndpoint<Sale[]>(idCompany, tipoDoc, serie, client);
  }

  getSaleReport(idCompany: number, orderType: number, id: any, title: string) {
    return this.saleEndpoint.getReportEndpoint<Report>(idCompany, orderType, id, title, 'b');
  }

  async getSaleReportAsync(idCompany: number, orderType: number, id: any, title: string): Promise<Report> {
    return await this.saleEndpoint.getReportEndpoint<Report>(idCompany, orderType, id, title, 'b').toPromise();
  }

  async getApprovalWorkflowsAsync(idCompany: number): Promise<ApprovalWorkflow[]> {
    return await this.customerEndpoint.getCustomerByIdEndpoint<ApprovalWorkflow[]>('customer', 'approvalWorkflowsByCompanyId', idCompany).toPromise();
  }

  setSaleReport(idCompany: number, reportObject: any) {
    return this.saleEndpoint.setReportEndpoint<Report>(idCompany, reportObject);
  }

  async setSaleReportAsync(idCompany: number, reportObject: any): Promise<Report> {
    return await this.saleEndpoint.setReportEndpoint<Report>(idCompany, reportObject).toPromise();
  }
  //#endregion

  //#region Set
  setSaleUpdate(idCompany: number, orderType: number, salesObject: any) {
    return this.saleEndpoint.setSaleUpdateEndpoint<Sale>(idCompany, orderType, salesObject);
  }

  setSalesUpdate(idCompany: number, orderType: number, salesObject: any[]) {
    return this.saleEndpoint.setSalesUpdateEndpoint<Sale>(idCompany, orderType, salesObject);
  }

  setSaleUpdateCancel(idCompany: number, salesObject: any) {
    return this.saleEndpoint.setSaleUpdateCancelEndpoint<Sale>(idCompany, salesObject);
  }

  setSalePrint(idCompany: number, orderType: number, salesObject: any) {
    return this.saleEndpoint.setSalePrintEndpoint<Sale>(idCompany, orderType, salesObject);
  }
  //#endregion

  //#endregion

  //#region Erp

  //#region Cancel Sales
  getCancelSales(idCompany: number) {
    return this.saleEndpoint.getCancelSalesEndpoint<CancelSale[]>(idCompany);
  }
  //#endregion

  //#region Moradas
  getMoradaAlternativaByEntidade(idCompany: number, entidade: string, vendedor?: string) {
    return this.saleEndpoint.getMoradaAlternativaByEntidadeVendedorEndpoint<MoradasAlternativa[]>(idCompany, entidade, vendedor);
  }
  //#endregion

  //#region Artigos
  getArtigosCatalog(idCompany: number) {
    return this.saleEndpoint.getArtigoCatalogsEndpoint<Artigos[]>(idCompany);
  }
  getArtigosCatalogImage(idCompany: number, companyId: number) {
    return forkJoin(
      this.saleEndpoint.getArtigoCatalogsEndpoint<Artigos[]>(idCompany),
      this.saleEndpoint.getGenericEndpoint<ProductCatalog[]>('artigosCatalogs', companyId),
      this.customerEndpoint.getByCompanydIdGenericEndpoint<Image[]>('images', companyId));
  }
  getArtigosByClient(idCompany: number, client: string, moradaAlternativa: string, unidade: string) {
    return this.saleEndpoint.getArtigosByClientEndpoint<Artigos[]>(idCompany, client, moradaAlternativa, unidade);
  }
  //#endregion

  //#region Salesman
  getSalesmansAndCompanies() {
    return forkJoin(
      this.customerEndpoint.getCustomerEndpoint<Salesman[]>('order', 'salesman'),
      this.customerEndpoint.getCustomerEndpoint<Company[]>('customer', 'companies')
    );
  }
  //#endregion

  getErpCompanies() {
    return this.saleEndpoint.getErpCompaniesEndPoint<ErpCompany[]>();
  }

  async getReference(idCompany: number, client: string, moradaAlternativa: string, ano: string, referencia: string): Promise<Sale[]> {
    return await this.saleEndpoint.getReferenceEndPoint<Sale[]>(idCompany, client, moradaAlternativa, ano, referencia).toPromise();
  }

  //#endregion

  //#region Sales Draft
  ////
  // Sales Draft
  ////
  setSaleDraftDelete(idCompany: number, salesObject: any) {
    return this.saleEndpoint.setSaleDraftDeleteEndpoint<Sale>(idCompany, salesObject);
  }

  async setSaleDraftDeleteAsync(idCompany: number, salesObject: any): Promise<Sale> {
    return this.saleEndpoint.setSaleDraftDeleteEndpoint<Sale>(idCompany, salesObject).toPromise();
  }

  setSaleDraftErpUpdate(idCompany: number, salesObject: any) {
    return this.saleEndpoint.setSaleDraftErpUpdateEndpoint<Sale>(idCompany, salesObject);
  }

  async setSaleDraftErpUpdateAsync(idCompany: number, salesObject: any): Promise<Sale> {
    return this.saleEndpoint.setSaleDraftErpUpdateEndpoint<Sale>(idCompany, salesObject).toPromise();
  }

  setSalesDraftErpUpdate(idCompany: number, salesObject: any[]) {
    return this.saleEndpoint.setSalesDraftErpUpdateEndpoint<Sale>(idCompany, salesObject);
  }

  async setSalesDraftErpUpdateAsync(idCompany: number, salesObject: any[]): Promise<Sale[]> {
    return this.saleEndpoint.setSalesDraftErpUpdateEndpoint<Sale[]>(idCompany, salesObject).toPromise();
  }

  setSalesDraftDelete(idCompany: number, salesObject: any[]) {
    return this.saleEndpoint.setSalesDraftDeleteEndpoint<Sale>(idCompany, salesObject);
  }
  //#endregion

  getOrdesStatus(salesType: SalesType): SalesStatus[] {
    let dataEstados: SalesStatus[];
    if (!Utilities.isNullOrUndefined(salesType)) {
      if (!salesType.isDraft && !salesType.isReturn) {
        dataEstados = [new SalesStatus(0, 'All', 'All'), new SalesStatus(0, 'APR', 'Aprove'), new SalesStatus(0, 'A', 'Cancel'), new SalesStatus(0, 'E', 'Pending Approval')];
      }
      else if (salesType.isReturn) {
        dataEstados = [new SalesStatus(2, 'E', 'Pending Approval'), new SalesStatus(2, 'APR', 'Aprove')];
      }
    }
    // switch ( !salesType.isDraft )
    // {
    //  case 0: dataEstados = [new SalesStatus( 'All', 'All' ), new SalesStatus( 'APR', 'Aprove' ), new SalesStatus( 'A', 'Cancel' ), new SalesStatus( 'E', 'Editing' )]; break;
    //  case 2: case 3: dataEstados = [new SalesStatus( 'E', 'Editing' )]; break;
    // }
    // dataEstados = [{ estado: 'All', name: 'All' }, { estado: 'APR', name: 'Aprove' }, { estado: 'A', name: 'Cancel' }, { estado: 'E', name: 'Editing' }];

    return dataEstados;
  }


  getCurrentUserPermission() {
    return this.saleEndpoint.getCurrentUserPermission<UserErpPermission[]>();
  }

  get currentUser() {
    return this.authService.currentUser;
  }
}
