import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { LocalStoreManager } from './local-store-manager.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys';
import { LoginResponse } from '../../models/login';

@Injectable()
export class OidcHelperService {

  private get baseUrl() { return this.configurations.baseUrl; }
  private clientId = 'coreapp_spa';
  private scope = 'openid email phone profile offline_access roles coreapp_api';

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private configurations: ConfigurationService,
    private localStorage: LocalStoreManager) { }


  loginWithPassword(userName: string, password: string): Observable<LoginResponse> {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = new HttpParams()
      .append('username', userName)
      .append('password', password)
      .append('client_id', this.clientId)
      .append('grant_type', 'password')
      .append('scope', this.scope);

    this.oauthService.issuer = this.baseUrl;
    this.oauthService.requireHttps = this.baseUrl ? this.baseUrl.toLocaleUpperCase().startsWith('HTTPS') : true;

    return from(this.oauthService.loadDiscoveryDocument())
      .pipe(mergeMap(() => {
        return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
      }));
  }

  refreshLogin(): Observable<LoginResponse> {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = new HttpParams()
      .append('refresh_token', this.refreshToken)
      .append('client_id', this.clientId)
      .append('grant_type', 'refresh_token');

    this.oauthService.issuer = this.baseUrl;
    this.oauthService.requireHttps = this.baseUrl ? this.baseUrl.toLocaleUpperCase().startsWith('HTTPS') : true;

    return from(this.oauthService.loadDiscoveryDocument())
      .pipe(mergeMap(() => {
        return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
      }));
  }

  get accessToken(): string {
    return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
  }

  get accessTokenExpiryDate(): Date {
    return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
  }

  get refreshToken(): string {
    return this.localStorage.getData(DBkeys.REFRESH_TOKEN);
  }

  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }
    // uper.isValid() && (!this.getTokenExpDate() || new Date() < this.getTokenExpDate());
    return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
  }

  // getTokenExpDate(): Date {
  //  if (this.accessTokenExpiryDate == null) {
  //    return null;
  //  }
  //  return new Date(this.createdAt.getTime() + Number(this.token.expires_in) * 1000);
  // }
}
