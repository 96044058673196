export class ErpCompany {
  constructor(empresa?: string, name?: string) {
    this.name = name;
    this.empresa = empresa;
  }

  public empresa?: string;
  public name?: string;
  public nome?: string;
  public erpJson?: any;
  public erpReportJson?: any;
  public empresaJson?: string;
}
