import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbToastrModule,
  NbGlobalPhysicalPosition
} from '@nebular/theme';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { ToastaModule } from 'ngx-toasta';

import {
  FooterComponent,
  HeaderComponent,
  LayoutComponent,
  LayoutAuthComponent,
  SearchBoxComponent,
  ItemQuantityComponent,
  SimplePageComponent,
  TitleComponent, ManagementComponent, InfoComponent
} from './components';

import { EqualValidator, LastElementDirective, AutofocusDirective, BootstrapTabDirective, BootstrapToggleDirective, NifValidator,IbanValidator } from './directives';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  GroupByPipe,
  WordWrapPipe
} from './pipes';

import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbToastrModule.forRoot({ position: NbGlobalPhysicalPosition.TOP_RIGHT, duration: 3000, destroyByClick: true, preventDuplicates: true }),
  MatButtonModule,
  MatIconModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatMenuModule,
  MatBadgeModule,
  MatInputModule,
  MatFormFieldModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  LayoutComponent,
  LayoutAuthComponent,
  SearchBoxComponent,
  ItemQuantityComponent,
  SimplePageComponent,
  TitleComponent, ManagementComponent, InfoComponent
];

const DIRECTIVES = [EqualValidator, LastElementDirective, AutofocusDirective, BootstrapTabDirective, BootstrapToggleDirective, NifValidator, IbanValidator];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  GroupByPipe, WordWrapPipe
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, ToastaModule.forRoot()],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ToastaModule],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers
      ],
    };
  }
}
