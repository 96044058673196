import { MoradasAlternativa } from '../entidades/moradaalternativa.model';
import { CancelSale } from './cancel.sale.model';
import { ApprovedSale } from './approved-sale.model';
import { Approval } from '../approval/approval.model';
import { ArtigoUnidades } from '../artigos';

export class SaleFilterValidation {
  idOrder: number;
  idCompany: number;
  idOrdersType: number;
  id: string;
  tipoDoc: string;
  tipoDocNome: string;
  serie: string;
  entidade: string;
  modoPag?: string;
  condPag?: string;
  nome: string;
  pais: string;
  nif: string;
  entidadeEntrega: string;
  moradaAltEntrega: string;
  descontoFinanceiro: number;
  totalDocumento: number;
  totalMerc: number;
  totalDesc: number;
  totalLiquido: number;
  estado: string;
  viewEstado: string;
  referencia: string;
  dataHoraDescarga: Date;
  vendedor: string;
  vendedorNome: string;
  paisEncomenda: string;
  orderType: number;
  tteEntidade: string;
  level2: string;
  moradasAlternativas: MoradasAlternativa[];

  idOrderLine: number;
  idDoc: string;
  idLine: string;
  numLine: number;
  artigo: string;
  descricao: string;
  tipoLinha: string = '20';
  quantidade: number;
  precUnit: number;
  boxPrice: number;
  pricePerUnit: number;
  desconto: number;
  desconto2: number;
  desconto3: number;
  precoLiquido: number;
  unidade: string;
  armazem: string;
  localizacao: string;
  totalPriceTax: number;
  totalPrice: number;
  taxaIva: number;
  allowPriceUnit: boolean;
  unidades: ArtigoUnidades[];
  cancelSales: CancelSale;
  approvedSales: ApprovedSale;
  approvals: Approval[];
}
