export class Salesman
{
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor( userId?: string, companyId?: number, company?: string, salesman?: string, nome?: string, salesmanCompany?: string )
  {
    this.userId = userId;
    this.companyId = companyId;
    this.company = company;
    this.salesman = salesman;
    this.nome = nome;
    this.salesmanCompany = salesmanCompany;
  }

  public userId: string;
  public companyId: number;
  public company: string;
  public salesman: string;
  public nome: string;
  public salesmanCompany: string;
}
