import { Component, OnInit } from '@angular/core';
import { AuthService, LocalStoreManager } from './@core/services';
import { AnalyticsService } from './@core/utils/analytics.service';
import { SeoService } from './@core/utils/seo.service';
import { NbIconLibraries } from '@nebular/theme';
import { ToastaConfig } from 'ngx-toasta';
import config from "devextreme/core/config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  removePrebootScreen: boolean;
  shouldShowLoginModal: boolean;

  constructor(private analytics: AnalyticsService, private seoService: SeoService, private authService: AuthService, private storageManager: LocalStoreManager, private iconLibraries: NbIconLibraries, private toastaConfig: ToastaConfig) {
    this.storageManager.initialiseStorageSyncListener();
    this.iconLibraries.registerFontPack('font-awesome', { iconClassPrefix: 'fa' });
    this.iconLibraries.setDefaultPack('font-awesome');

    this.toastaConfig.theme = 'material';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = true;
    this.toastaConfig.timeout = 3000;
    config({ defaultCurrency: 'EUR', forceIsoDateParsing: true });
  }

  ngOnInit(): void {
    // location.reload();
    this.isUserLoggedIn = this.authService.isLoggedIn;
    // 0.5 extra sec to display preboot/loader information. Preboot screen is removed 0.5 sec later
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 1000);

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          console.log('LogOut');
          // this.alertService.showMessage('Session Ended!', '', MessageSeverity.default);
        }
      }, 500);
    });

    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
  }

  isAuthenticated() {
    return this.authService.isLoggedIn;
  }
}
