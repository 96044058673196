import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

import { ConfigurationService, CustomerService } from '../services';
import { Utilities } from './utilities';
import { ProductCatalog, Image, Artigos } from '../models';

@Injectable()
export class UtilitiesImage {
  constructor(private configurations: ConfigurationService, private customerService: CustomerService, private imageCompress: NgxImageCompressService) { }

  //#region Image

  //#region AddImage
  public addImagesArtigo(artigos: Artigos[], products: ProductCatalog[], images: Image[]): Artigos[] {
    if (products && products !== undefined && products.length > 0) {
      for (const p of products) {
        this.addImageArtigo(artigos, p, images);
      }
    }
    else if (products && products !== undefined) {
      // this.addImageArtigo( artigos, products, images );
    }
    return artigos;
  }

  private addImageArtigo(artigos: Artigos[], product: ProductCatalog, images: Image[]) {
    let imageId;
    let idCategory;
    let category: string;
    if (product.idImage !== undefined && product.idImage > 0) {
      imageId = product.idImage;
    }
    else {
      imageId = null;
    }
    if (product.idCatalogsCategories !== undefined) {
      idCategory = product.idCatalogsCategories;
    }
    else if (product.catalogsCategory !== undefined) {
      category = product.catalogsCategory.name;
    }
    else {
      idCategory = null;
    }

    const image = this.findImage(images, product.name, category, imageId, idCategory);
    if (image) {
      if (image.imgPath && image.imgPath !== undefined) {
        product.icon = image.imgPath;
      }
      else {
        product.icon = image.imageData;
      }
    }

    if (!product.isProduct && product.products && product.products !== undefined && product.products.length > 0) {
      for (const p of product.products) {
        try {
          this.addImageArtigo(artigos, p, images);
        }
        catch (e) { }
      }
    }
    else if (product.isProduct) {
      const _artigos = artigos.find(f => f.artigo === product.erpProduct);
      if (_artigos && product.icon) {
        _artigos.icon = product.icon;
      }
    }
  }
  //#endregion

  //#region AddArtigoImage
  public AddArtigoImages(artigos: Artigos[], products: ProductCatalog[]): Artigos[] {
    if (products && products !== undefined && products.length > 0) {
      for (const p of products) {
        this.AddArtigoImage(artigos, p);
      }
    }
    else if (products && products !== undefined) {
      // this.addImageArtigo( artigos, products, images );
    }
    return artigos;
  }

  private AddArtigoImage(artigos: Artigos[], product: ProductCatalog) {
    if (!product.isProduct && product.products && product.products !== undefined && product.products.length > 0) {
      for (const p of product.products) {
        try {
          this.AddArtigoImage(artigos, p);
        }
        catch (e) { }
      }
    }
    else if (product.isProduct) {
      const _artigos = artigos.find(f => f.artigo === product.erpProduct);
      if (_artigos && product.icon) {
        _artigos.icon = product.icon;
      }
    }
  }
  //#endregion

  //#region AddImage
  public addImages(products: ProductCatalog[], images: Image[]): ProductCatalog[] {
    if (products && products !== undefined && products.length > 0) {
      for (const p of products) {
        this.addImage(p, images);
      }
    }
    return products;
  }

  private addImage(product: ProductCatalog, images: Image[]) {
    let imageId;
    let idCategory;
    let category: string;
    if (product.idImage !== undefined && product.idImage > 0) {
      imageId = product.idImage;
    }
    else {
      imageId = null;
    }
    if (product.idCatalogsCategories !== undefined) {
      idCategory = product.idCatalogsCategories;
    }
    else if (product.catalogsCategory !== undefined) {
      category = product.catalogsCategory.name;
    }
    else {
      idCategory = null;
    }

    const image = this.findImage(images, product.name, category, imageId, idCategory);
    if (image) {
      if (image.imgPath && image.imgPath !== undefined) {
        product.icon = image.imgPath;
      }
      else {
        product.icon = image.imageData;
      }
    }

    if (!product.isProduct && product.products && product.products !== undefined && product.products.length > 0) {
      for (const p of product.products) {
        try {
          this.addImage(p, images);
        }
        catch (e) { }
      }
    }
  }

  private addImageIntance(row: ProductCatalog, product: ProductCatalog, images: Image[]): boolean {
    let flag = false;
    let imageId;
    let idCategory;
    let category: string;

    if (row.rowIndex === product.rowIndex && row.name === product.name) {
      product.idImage = row.idImage;
      if (product.idImage !== undefined) {
        imageId = product.idImage;
      }
      else {
        imageId = null;
      }

      if (product.idCatalog !== undefined) {
        idCategory = product.idCatalog;
      }
      else if (product.catalogsCategory !== undefined) {
        category = product.catalogsCategory.name;
      }
      else {
        idCategory = null;
      }

      const image = this.findImage(images, product.name, category, imageId, idCategory);
      if (image) {
        if (image.imgPath && image.imgPath !== undefined) {
          product.icon = image.imgPath; // `${this.configurations.baseUrl}${image.imgPath}`;
          product.imgPath = image.imgPath; // `${this.configurations.baseUrl}${image.imgPath}`;
        }
        else {
          product.icon = image.imageData;
        }
      }
    }
    else {
      if (!product.isProduct && product.products && product.products !== undefined && product.products.length > 0) {
        for (const p of product.products) {
          try {
            if (this.addImageIntance(row, p, images)) {
              flag = true;
              break;
            }
          }
          catch (e) { }
        }
      }
    }
    return flag;
  }

  public addInstanceImages(instanceSelect: any, products: ProductCatalog[], images: Image[]): ProductCatalog[] {
    if (instanceSelect && instanceSelect !== undefined && products && products !== undefined && products.length > 0) {
      if (instanceSelect.parent && instanceSelect.parent !== undefined) {
        this.getParent(instanceSelect.parent, instanceSelect, products, images);
      }
      else {
        this.getFindRow(instanceSelect.itemData, instanceSelect, products, images);
      }
    }
    return products;

  }
  //#endregion

  private getParent(parent: any, instanceSelect: any, products: ProductCatalog[], images: Image[]) {
    if (parent.parent && parent.parent !== undefined) {
      this.getParent(parent.parent, instanceSelect, products, images);
    }
    else {
      this.getFindRow(parent.itemData, instanceSelect, products, images);
    }
  }

  private getFindRow(row: any, instanceSelect: any, products: ProductCatalog[], images: Image[]): boolean {
    let flag = false;
    const p: any = products.find(f => f.rowIndex === row.rowIndex && f.name === row.name);
    if (!Utilities.isNullOrUndefined(p)) {
      flag = this.addImageIntance(instanceSelect.itemData, p, images);
    }
    return flag;
  }

  public removeImages(products: ProductCatalog[]): ProductCatalog[] {
    for (const p of products) {
      this.removeImage(p);
    }
    return products;
  }

  private removeImage(product: ProductCatalog) {
    product.icon = '';

    if (!product.isProduct && product.products && product.products !== undefined && product.products.length > 0) {
      for (const p of product.products) {
        this.removeImage(p);
      }
    }
  }

  public findImage(images: Image[], name: string, category: string, idImage?: number, idCategory?: number): Image {
    let img: Image;
    if (images && images !== undefined && images.length > 0) {
      if (idImage && idImage > 0) {
        img = images.find(f => f.idImage === idImage);
      }
      else if (idCategory && idCategory > 0) {
        img = images.find(f => f.idCatalogsCategories === idCategory && f.name === name);
      }
      else {
        img = images.find(f => f.catalogsCategory !== undefined && f.catalogsCategory.name === category && f.name === name);
        if (Utilities.isNullOrUndefined(img)) {
          img = images.find(f => f.catalogsCategory !== undefined && f.catalogsCategory.name === category && f.title === name);
        }
      }
      if (Utilities.isNullOrUndefined(img)) {
        img = images.find(f => f.catalogsCategory !== undefined && f.catalogsCategory.name === 'Default');
      }
    }
    else {
      img.imageData = 'empty.png';
    }
    return img;
  }
  //#endregion

  public compressFile(image: any, fileName: string, element: string, imageCompress: NgxImageCompressService) {
    let imgResultAfterCompress: string;
    let sizeOFCompressedImage: number;
    let imageFile: File;
    const orientation = -1;
    const sizeOfOriginalImage = imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', sizeOfOriginalImage);

    imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        imgResultAfterCompress = result;
        element = result;
        sizeOFCompressedImage = imageCompress.byteCount(result) / (1024 * 1024);
        console.warn('Size in bytes after compression:', sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(imgResultAfterCompress.split(',')[1]);
        // imageFile created below is the new compressed file which can be send to API in form data
        imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });

    // return localCompressedURl;
  }

  private dataURItoBlob(dataURI): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public SaveImage(imgData) {
    localStorage.setItem('imgData', imgData);
  }

  public async uploadImage(file: File, image: Image, compress: boolean = false) {
    try {
      const mimeType: string = file.type;
      if (mimeType.match(/image\/*/) === null) {
        return;
      }
      const result = await this.handleUpload(file);
      if (result) {
        if (compress) {
          image.imageBase64 = await this.compressFiles(result);
        }
        else {
          image.imageBase64 = result;
        }
        const _image = await this.imageUpload(image);
        if (_image && _image.imgPath) {
          image.imgPath = _image.imgPath;
          image.icon = _image.imgPath;
        }
      }
    }
    catch (e) {
      // console.error( e.message );
      image.isError = true;
      if (e.error) {
        image.messageError = Utilities.getHttpResponseMessage(e);
      }
      else {
        image.messageError = e.message;
      }
    }
    return image;
  }

  private async imageUpload(image: Image): Promise<Image> {
    let _image: Image = null;
    if (image.idImage > 0) {
      _image = await this.customerService.updateCustomerAsync<Image>('customer', 'image', image, image.idImage);
    }
    else {
      _image = await this.customerService.newCustomerAsync<Image>('customer', 'image', image);
    }
    image.imageBase64 = null;
    if (_image && _image.imgPath) {
      image.imgPath = _image.imgPath;
      image.icon = _image.imgPath;
    }
    return image;
  }

  public async compressFiles(image: any): Promise<any> {
    let result: any;
    const orientation = -1;
    await this.imageCompress.compressFile(image, orientation, 99, 60).then(r => {
      result = r;
    });

    return result;
  }

  public async getImageBase64(file: File, compress: boolean = false): Promise<any> {
    const result = await this.handleUpload(file);
    if (compress) {
      return this.compressFiles(result);
    }
    return result;
  }

  private readUploadedFileAsDataURL = (inputFile) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  }

  private handleUpload = async (file: File) => {
    let imageBase64: any = null;
    try {
      imageBase64 = await this.readUploadedFileAsDataURL(file);
    } catch (e) {
      console.warn(e.message);
    }
    return imageBase64;
  }

}
