export class UserErpPermission {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(roleId?: string, userId?: string, companyId?: number, companyName?: string, companyErp?: string, companyErpName?: string, key?: string, keyName?: string) {
    this.roleId = roleId;
    this.userId = userId;
    this.companyId = companyId;
    this.companyName = companyName;
    this.companyErp = companyErp;
    this.companyErpName = companyErpName;
    this.key = key;
    this.keyName = keyName;
  }

  public roleId?: string;
  public userId?: string;
  public companyId?: number;
  public companyName?: string;
  public companyErp?: string;
  public companyErpName?: string;
  public key?: string;
  public keyName?: string;
  public tipoDoc?: string;
  public serie?: string;
}
