import { MoradasAlternativa } from './moradaalternativa.model';

export class Entidade {
  id?: number;
  companyId?: number;
  client?: string;
  provider?: string;
  name?: string;
  address?: string;
  address1?: string;
  address2?: string;
  moradaCompleta?: string;
  city?: string;
  distrito?: string;
  postalCode?: string;
  postalCodeCity?: string;
  country?: string;
  nif?: string;
  desconto?: number;
  descricaoCliente?: string;
  descricaoClienteAssociados?: string;

  descriptionProvider?: string;
  descriptionAssociatedProvider?: string;

  moradasAlternativas?: MoradasAlternativa[];
  telephone?: string;
  email?: string;
  webPage?: string;
  condPag?: string;
  modoPag?: string;
  currency?: string;

  entidadeCompany?: string;
}
