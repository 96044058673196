import { CategoryEntension } from './catalog-extension.model';
import { ProductCatalog } from '../artigos/product-catalog.model';

export class Catalog extends CategoryEntension
{
  idCatalog: number;
  published: boolean;
  isEditing: boolean;
  dateFrom: Date;
  dateTo: Date;
  products: ProductCatalog[];
  productsErp: ProductCatalog[];
  isProductEdit: boolean;
}
