export class ApprovedSale
{
  constructor( data?: Date, user?: string, idUser?: string)
  {
    this.data = data;
    this.user = user;
    this.idUser = idUser;
  }

  data: Date;
  user: string;
  idUser: string;
}
