import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, forkJoin, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth';
import { EndpointBase, ConfigurationService } from '../conf';
import {
  AreaAtividade, Artigos, CancelPurchase, FindPurchase, Cargo, Classificacao, Company, ComunicacaoMarketing, CondPagamento, Country, ErpCompany,
  ModoPagamento, MotivosEstadoSubmetido, PermissionValues, RelacaoPep, SituacaoProf, TiposDocAttach, TiposId, UserErpPermission, LogPortal,PortalConfig, Pep
} from '../../models';
import { Utilities } from '../../utils';

@Injectable()
export class ApiBaseService extends EndpointBase {

  readonly controllerAccount: string = 'api/account';
  readonly controllerCustomer: string = 'api/customer';
  readonly controllerOrder: string = 'api/order';
  readonly controllerDocument: string = 'api/documents';
  readonly controllerApi: string = 'api/api';
  readonly controllerPurchase: string = 'api/document/purchase';
  readonly controllerPortal: string = 'api/portal';
  readonly controllerAudit: string = 'api/audit';
  readonly endPointCompany: string = 'companies';


  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService) {
    super(http, authService);
  }

  //#region get
  protected get<T>(endPoint: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.get(endPoint));
      }));
  }

  getById<T>(endPoint: string, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getById(endPoint, id));
      }));
  }

  getByIdBykey<T>(endPoint: string, id: any, key: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}/${key}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByIdBykey(endPoint, id, key));
      }));
  }

  getBykey<T>(endPoint: string, key: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${key}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBykey(endPoint, key));
      }));
  }

  getBykeyByFilters<T>(endPoint: string, key: any, dateFrom?: Date, dateTo?: Date): Observable<T> {
    let endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${key}`;
    if (!Utilities.isNullOrUndefined(dateFrom) || !Utilities.isNullOrUndefined(dateTo)) {
      endpointUrl += `/${!Utilities.isNullOrUndefined(dateFrom) ? Utilities.DateToJson(dateFrom) : ' '}`;
      endpointUrl += `${!Utilities.isNullOrUndefined(dateTo) ? '/' + Utilities.DateToJson(dateTo) : ' '}`;
    }
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBykeyByFilters(endPoint, key, dateFrom, dateTo));
      }));
  }

  getBykeyKey2<T>(endPoint: string, key: any, key2: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${key}/${key2}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBykeyKey2(endPoint, key, key2));
      }));
  }

  getBykeyKey2ByFilters<T>(endPoint: string, key: any, key2: any, dateFrom?: Date, dateTo?: Date): Observable<T> {
    let endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${key}/${key2}`;
    if (!Utilities.isNullOrUndefined(dateFrom) || !Utilities.isNullOrUndefined(dateTo)) {
      endpointUrl += `/${!Utilities.isNullOrUndefined(dateFrom) ? Utilities.DateToJson(dateFrom) : ' '}`;
      endpointUrl += `${!Utilities.isNullOrUndefined(dateTo) ? '/' + Utilities.DateToJson(dateTo) : ' '}`;
    }
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBykeyKey2ByFilters(endPoint, key, key2, dateFrom, dateTo));
      }));
  }

  protected getByCompanyId<T>(endPoint: string, companyId: number, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}/${id}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByCompanyId(endPoint, companyId, id));
      }));
  }

  protected getByEndPointCompanyId<T>(endPoint: string, apiEndPoint: string, companyId: number, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}/${id}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByEndPointCompanyId(endPoint, apiEndPoint, companyId, id));
      }));
  }
  protected getByEndPointCompanyIdKey<T>(endPoint: string, apiEndPoint: string, companyId: number, id: any, key: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}/${id}/${key}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByEndPointCompanyIdKey(endPoint, apiEndPoint, companyId, id, key));
      }));
  }

  protected getByCompanyIdByFilter<T>(endPoint: string, companyId: number, id: any, key: string, type: number, internaltype: number, estado: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}/${companyId}/${key}/${type}/${internaltype}/${estado}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByCompanyIdByFilter(endPoint, id, companyId, key, type, internaltype, estado));
      }));
  }

  protected getByCompanyIdByFilters<T>(endPoint: string, companyId: number, id: any, key: string, type: number, internaltype: number, estado: string, dateFrom?: Date, dateTo?: Date): Observable<T> {
    let endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}/${companyId}/${key}/${type}/${internaltype}/${estado}`;

    if (!Utilities.isNullOrUndefined(dateFrom) || !Utilities.isNullOrUndefined(dateTo)) {
      endpointUrl += `/${!Utilities.isNullOrUndefined(dateFrom) ? Utilities.DateToJson(dateFrom) : ' '}`;
      endpointUrl += `${!Utilities.isNullOrUndefined(dateTo) ? '/' + Utilities.DateToJson(dateTo) : ' '}`;
    }

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByCompanyIdByFilters(endPoint, id, companyId, key, type, internaltype, estado, dateFrom, dateTo));
      }));
  }

  protected getByEndPointCompany<T>(endPoint: string, apiEndPoint: string, companyId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByEndPointCompany(endPoint, apiEndPoint, companyId));
      }));
  }

  getList<T>(endPoint: string, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/${endPoint}/${page}/${pageSize}` : `${this.configurations.baseUrl}/${endPoint}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getList(endPoint, page, pageSize));
      }));
  }

  getListByCompanyId<T>(endPoint: string, companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/${endPoint}/${companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/${endPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getListByCompanyId(endPoint, companyId, page, pageSize));
      }));
  }

  getListByEndPoint<T>(endPoint: string, apiEndPoint: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getListByEndPoint(endPoint, apiEndPoint));
      }));
  }

  getListByEndPointCompanyId<T>(endPoint: string, apiEndPoint: string, companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getListByEndPointCompanyId(endPoint, apiEndPoint, companyId, page, pageSize));
      }));
  }
  //#endregion

  //#region post
  post<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.post(endPoint, entity));
      }));
  }

  postByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postByCompanyId(endPoint, companyId, entity));
      }));
  }

  postByEndPointCompanyId<T>(endPoint: string, apiEndPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}`;
    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postByEndPointCompanyId(endPoint, apiEndPoint, companyId, entity));
      }));
  }

  postByCompanyIdEndPoint<T>(endPoint: string, apiEndPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}/${apiEndPoint}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postByCompanyIdEndPoint(endPoint, apiEndPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region put
  put<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.put(endPoint, entity));
      }));
  }

  putByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putByCompanyId(endPoint, companyId, entity));
      }));
  }

  putByEndPointCompanyId<T>(endPoint: string, apiEndPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putByEndPointCompanyId(endPoint, apiEndPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region patch
  patch<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patch(endPoint, entity));
      }));
  }

  patchByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchByCompanyId(endPoint, companyId, entity));
      }));
  }

  patchByEndPointCompanyId<T>(endPoint: string, apiEndPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchByEndPointCompanyId(endPoint, apiEndPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region delete
  delete<T>(endPoint: string, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.delete(endPoint, id));
      }));
  }

  deleteByCompanyId<T>(endPoint: string, companyId: number, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteByCompanyId(endPoint, companyId, id));
      }));
  }

  deleteByEndPointCompanyId<T>(endPoint: string, apiEndPoint: string, companyId: number, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${apiEndPoint}/${companyId}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteByEndPointCompanyId(endPoint, apiEndPoint, companyId, id));
      }));
  }
  //#endregion

  //#region Commons
  getPortalConfig(companyId: number): Observable<PortalConfig> {
    return this.getByEndPointCompany<PortalConfig>(`${this.controllerApi}/generic`, 'portalConfig', companyId);
  }

  getListAreaAtividades(companyId: number): Observable<AreaAtividade[]> {
    return this.getListByEndPointCompanyId<AreaAtividade[]>(`${this.controllerApi}/generics`, 'areaAtividades', companyId);
  }

  getListCargos(companyId: number): Observable<Cargo[]> {
    return this.getListByEndPointCompanyId<Cargo[]>(`${this.controllerApi}/generics`, 'cargos', companyId);
  }

  getListComunicacaoMarketings(companyId: number): Observable<ComunicacaoMarketing[]> {
    return this.getListByEndPointCompanyId<ComunicacaoMarketing[]>(`${this.controllerApi}/generics`, 'comunicacaoMarketings', companyId);
  }

  getListPep(companyId: number): Observable<Pep[]> {
    return this.getListByEndPointCompanyId<Pep[]>(`${this.controllerApi}/generics`, 'pep', companyId);
  }

  getListCondPagamentos(companyId: number): Observable<CondPagamento[]> {
    return this.getListByEndPointCompanyId<CondPagamento[]>(`${this.controllerApi}/generics`, 'paymentTerms', companyId);
  }

  getListCountries(companyId: number): Observable<Country[]> {
    return this.getListByEndPointCompanyId<Country[]>(`${this.controllerApi}/generics`, 'countries', companyId);
  }

  getListModosPagamento(companyId: number): Observable<ModoPagamento[]> {
    return this.getListByEndPointCompanyId<ModoPagamento[]>(`${this.controllerApi}/generics`, 'paymentmode', companyId);
  }

  getListRelacoesPep(companyId: number): Observable<RelacaoPep[]> {
    return this.getListByEndPointCompanyId<RelacaoPep[]>(`${this.controllerApi}/generics`, 'relacoesPep', companyId);
  }

  getListSituacoesProf(companyId: number): Observable<SituacaoProf[]> {
    return this.getListByEndPointCompanyId<SituacaoProf[]>(`${this.controllerApi}/generics`, 'situacoesProf', companyId);
  }

  getListTiposId(companyId: number): Observable<TiposId[]> {
    return this.getListByEndPointCompanyId<TiposId[]>(`${this.controllerApi}/generics`, 'tiposId', companyId);
  }

  getListClassificacoes(companyId: number): Observable<Classificacao[]> {
    return this.getListByEndPointCompanyId<Classificacao[]>(`${this.controllerApi}/generics`, 'classicacoes', companyId);
  }

  getListMotivosEstadoSubmetido(companyId: number): Observable<MotivosEstadoSubmetido[]> {
    return this.getListByEndPointCompanyId<MotivosEstadoSubmetido[]>(`${this.controllerApi}/generics`, 'motivosEstadoSubmetido', companyId);
  }

  getListCancelPurchase(companyId: number): Observable<CancelPurchase[]> {
    return this.getListByEndPointCompanyId<CancelPurchase[]>(`${this.controllerApi}/generics`, 'cancelPurchases', companyId);
  }

  getListFindPurchase(nif?: string, ndocument?: string, key?: string): Observable<FindPurchase> {
    if (Utilities.isNullOrUndefined(nif) || (!Utilities.isNullOrUndefined(nif) && nif.trim() === '')) {
      nif = "0";
    }
    if (Utilities.isNullOrUndefined(ndocument) || (!Utilities.isNullOrUndefined(ndocument) && ndocument.trim() === '')) {
      ndocument = "0";
    }
    if (Utilities.isNullOrUndefined(key) || (!Utilities.isNullOrUndefined(key) && key.trim() === '')) {
      key = "0";
    }
    const endpointUrl = `${this.configurations.baseUrl}/${this.controllerApi}/generic/findPurchases/${this.userAtualErpPermissions.companyId}/${nif}/${ndocument}/${key}`;
    return this.http.get<FindPurchase>(endpointUrl, this.requestHeaders).pipe<FindPurchase>(
      catchError(error => {
        return this.handleError(error, () => this.getListFindPurchase(nif, ndocument, key));
      }));
  }

  getListTiposDocAttach(companyId: number): Observable<TiposDocAttach[]> {
    return this.getListByEndPointCompanyId<TiposDocAttach[]>(`${this.controllerApi}/generics`, 'tiposDocAttach', companyId);
  }

  getListProducts(companyId: number): Observable<Artigos[]> {
    return this.getListByEndPointCompanyId<Artigos[]>(`${this.controllerApi}/generics`, 'products', companyId);
  }

  getCompanies(page?: number, pageSize?: number): Observable<Company[]> {
    return this.getList<Company[]>(`${this.controllerCustomer}/${this.endPointCompany}`, page, pageSize);
  }

  getCompaniesAndErpCompanies(page?: number, pageSize?: number): Observable<[Company[], ErpCompany[]]> {
    return forkJoin([
      this.getList<Company[]>(`${this.controllerCustomer}/${this.endPointCompany}`, page, pageSize),
      this.getListByEndPoint<ErpCompany[]>(`${this.controllerApi}/genericsInst`, 'companies')
    ]);
  }
  //#endregion

  //#region log
  writeLog(companyId: number, entity: LogPortal): Observable<LogPortal> {
    entity.IdCompany = companyId;

    const endpointUrl = `${this.configurations.baseUrl}/${this.controllerPortal}/log`;
    return this.http.post<LogPortal>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<LogPortal>(
      catchError(error => {
        return this.handleError(error, () => this.writeLog(companyId, entity));
      }));
  }
  //#endregion

  //#region UserErpPermission
  getListUserErpPermission(page?: number, pageSize?: number): Observable<UserErpPermission[]> {
    return this.getList<UserErpPermission[]>(`${this.controllerOrder}/users/mepermssion`, page, pageSize);
  }

 async getListUserErpPermissionAsync(page?: number, pageSize?: number): Promise<UserErpPermission[]> {
   return await firstValueFrom(this.getListUserErpPermission(page, pageSize));//.toPromise();
  }
  //#endregion

  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }
  get userErpPermissions(): UserErpPermission[] {
    return this.authService.userErpPermissions;
  }

  get userAtualErpPermissions(): UserErpPermission {
    return this.authService.userAtualErpPermission;
  }
  set userAtualErpPermission(user: UserErpPermission) {
    this.authService.userAtualErpPermission = user;
  }


  get currentUser() {
    return this.authService.currentUser;
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p === permissionValue);
  }

  get currentLanguage() {
    return this.authService.currentLanguage;
  }
}
