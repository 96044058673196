import { Injectable } from '@angular/core';
import { AppTranslationService } from '../app/app-translation.service';
import { SaleService, PurchasesService } from '..';
import { Report } from '../../models/reports/report.model';
import { Utilities } from '../../utils';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { jsPDF } from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';


@Injectable()
export class ReportService {
  private gT: any;

  constructor(private translationService: AppTranslationService, private saleService: SaleService, private purchaseService: PurchasesService) {
    this.gT = (key: string) => this.translationService.getTranslation(key);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  public printPage(docHtml: any) {
    if (!Utilities.isNullOrUndefined(docHtml)) {
      html2canvas(docHtml).then(canvas => {
        // Few necessary setting options
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'px', 'a4'); // A4 size page of PDF
        const position = 0;
        pdf.addImage(contentDataURL, 'PNG', 20, 20, canvas.width, canvas.height);
        pdf.save('sales.pdf'); // Generated PDF
      });
    }
  }

  public openPDF(): void {
    // let DATA = this.htmlData.nativeElement;
    // let doc = new jsPDF( 'p', 'pt', 'a4' );
    // doc.fromHTML( DATA.innerHTML, 15, 15 );
    // doc.output( 'dataurlnewwindow' );
  }

  public downloadPDF(): void {
    // let DATA = this.htmlData.nativeElement;
    // let doc = new jsPDF( 'p', 'px', 'a4' );

    // let options = {
    //  pagesplit: true
    // };
    // doc.fromHTML( this.elementRef.nativeElement, 0, 0, options, () =>
    // {
    //  doc.save( 'Test.pdf' );
    // } );


    // let handleElement = {
    //  '#editor': function ( element, renderer )
    //  {
    //    return true;
    //  }
    // };
    // doc.html( DATA.innerHTML);

    // doc.fromHTML( DATA.innerHTML, 15, 15, {
    //  'width': 200,
    //  'elementHandlers': handleElement
    // } );

    // doc.save( 'angular-demo.pdf' );
  }

  public async generatePdfAsync(orderType: number, report: Report): Promise<Report> {
    let result: Report = await this.saleService.getSaleReportAsync(report.sale.idCompany, orderType, Utilities.isNullOrUndefined(report.sale.id) ? report.sale.idOrder : report.sale.id, report.title);
    if (!Utilities.isNullOrUndefined(result)) {
      if (!Utilities.isNullOrUndefined(result.reportDocJson)) {
        try {
          const documentDefinition: any = result.reportDocJson;

          if (!Utilities.isNullOrUndefined(documentDefinition.footer)) {
            const _footer: any = {};
            Object.assign(_footer, documentDefinition.footer);
            documentDefinition.footer = (currentPage, pageCount) => [{ text: `Page ${currentPage} of ${pageCount}`, style: 'page' }];
          }
          result.reportDocument = await this.generateAsync(report.action, documentDefinition);

          if (report.sendEmail) {
            result.sendEmail = report.sendEmail;
            result = await this.saleService.setSaleReportAsync(report.sale.idCompany, result);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    return result;
  }

  public async generatePdfPurchaseAsync(companyId: number, purchaseType: number, report: Report): Promise<Report> {
    const result: Report = await this.purchaseService.getReportPurchaseAsync(companyId, purchaseType, !Utilities.isNullOrUndefined(report.purchase.idPurchase) ? report.purchase.idPurchase : report.purchase.id, 'b', 'a', report.router);
    if (!Utilities.isNullOrUndefined(result)) {
      if (!Utilities.isNullOrUndefined(result.reportDocJson)) {
        try {

          if (result.reportDocJson.content && Array.isArray(result.reportDocJson.content)) {
            for (const _content of result.reportDocJson.content) {
              if (_content && _content.text && _content.text === '@@Vias@@') {
                _content.text = report.vias ? report.vias : '';
              }
            }
            // const _content: any[] = [];
            // Object.assign(_content, result.reportDocJson.content);

            // result.reportDocJson.content = [..._content];
            // result.reportDocJson.content.push({ text: '', style: 'row', pageBreak: 'before' });
            // for (const _c of _content) {
            //  result.reportDocJson.content.push(_c);
            // }
          }
          // [result.reportDocJson.content ,{ text: '', pageBreak: 'before' } , result.reportDocJson.content]
          const documentDefinition: any = {
            content: result.reportDocJson.content,
            info: result.reportDocJson.info,
            styles: result.reportDocJson.styles,
            compress: false,
            pageMargins: [40, 40, 40, 40],
            pageSize: 'A4',
            pages: 2,
            pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
              // check if signature part is completely on the last page, add pagebreak if not
              if (currentNode.id === 'pages') {
                if (previousNodesOnPage[0].startPosition.pageNumber !== followingNodesOnPage[0].pageNumbers) {
                  return true;
                }
              }
              return false;
            }
          };
          // Object.assign(documentDefinition, result.reportDocJson);
          // documentDefinition.compress = true;
          // documentDefinition.pages = 2;

          if (!Utilities.isNullOrUndefined(result.reportDocJson.footer)) {
            const _footer: any = {};
            Object.assign(_footer, result.reportDocJson.footer);
            documentDefinition.footer = (currentPage, pageCount) => [{ text: `Pág. ${currentPage} / ${pageCount}`, style: 'page' }];
          }

          // await this.generateAsync(report.action, documentDefinition);

          result.reportDocument = await this.generateAsync(report.action, documentDefinition);
          if (report.sendEmail) {
            result.sendEmail = report.sendEmail;
            // result = await this.saleService.setSaleReportAsync(report.sale.idCompany, result);
          }

        } catch (e) {
          console.log(e);
        }
      }
    }
    return result;
  }


  public async generatePdfPurchaseViasAsync(companyId: number, purchaseType: number, report: Report, vias: number): Promise<Report> {
    const result: Report = await this.purchaseService.getReportPurchaseAsync(companyId, purchaseType, !Utilities.isNullOrUndefined(report.purchase.idPurchase) ? report.purchase.idPurchase : report.purchase.id, 'b', 'a', vias);
    if (!Utilities.isNullOrUndefined(result)) {
      if (!Utilities.isNullOrUndefined(result.reportDocJson)) {
        try {

          if (result.reportDocJson.content && Array.isArray(result.reportDocJson.content)) {
            for (const _content of result.reportDocJson.content) {
              if (_content && _content.text && _content.text === '@@Vias@@') {
                _content.text = report.vias ? report.vias : '';
              }
            }
          }
          const documentDefinition: any = {
            content: result.reportDocJson.content,
            info: result.reportDocJson.info,
            styles: result.reportDocJson.styles,
            compress: false,
            pageMargins: [40, 40, 40, 40],
            pageSize: 'A4',
            pages: 2,
            pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
              // check if signature part is completely on the last page, add pagebreak if not
              if (currentNode.id === 'pages') {
                if (previousNodesOnPage[0].startPosition.pageNumber !== followingNodesOnPage[0].pageNumbers) {
                  return true;
                }
              }
              return false;
            }
          };
          if (!Utilities.isNullOrUndefined(result.reportDocJson.footer)) {
            const _footer: any = {};
            Object.assign(_footer, result.reportDocJson.footer);
            documentDefinition.footer = (currentPage, pageCount) => [{ text: `Pág. ${currentPage} / ${pageCount}`, style: 'page' }];
          }

          // await this.generateAsync(report.action, documentDefinition);

          result.reportDocument = await this.generateAsync('blob', documentDefinition);
          if (report.sendEmail) {
            result.sendEmail = report.sendEmail;
            // result = await this.saleService.setSaleReportAsync(report.sale.idCompany, result);
          }

        } catch (e) {
          console.log(e);
        }
      }
    }
    return result;
  }

  public async generatePdfByReportAsync(report: Report): Promise<Report> {
    if (!Utilities.isNullOrUndefined(report)) {
      if (!Utilities.isNullOrUndefined(report.reportDocJson)) {
        try {
          // const documentDefinition: any = report.reportDocJson;
          // documentDefinition.compress = true;

          const documentDefinition: any = {
            content: report.reportDocJson.content,
            info: report.reportDocJson.info,
            styles: report.reportDocJson.styles,
            compress: false
          };

          // if (!Utilities.isNullOrUndefined(documentDefinition.footer)) {
          //  const _footer: any = {};
          //  Object.assign(_footer, documentDefinition.footer);
          //  documentDefinition.footer = (currentPage, pageCount) => [{ text: `Pág. ${currentPage} / ${pageCount}`, style: 'page' }];
          // }
          // report.reportDocument = await this.generateAsync(report.action, documentDefinition);

        } catch (e) {
          console.log(e);
        }

      }
    }
    return report;
  }

  private async generateAsync(action: string = 'open', documentDefinition: any): Promise<any> {
    let result: any;
    switch (action) {
      case 'open': pdfMake.createPdf(documentDefinition).open(); break;
      case 'print': pdfMake.createPdf(documentDefinition).print(); break;
      case 'download': pdfMake.createPdf(documentDefinition).download('invoice.pdf'); break;
      case 'base64':
      case 'blob':
        const pdf = pdfMake.createPdf(documentDefinition);
        result = await this.getPdf(action, pdf);
        break;
      default: pdfMake.createPdf(documentDefinition).open(); break;
    }
    return result;
  }

  private getPdf = (type: string, pdf: any) => {
    return new Promise((resolve, reject) => {
      switch (type) {
        case 'base64':
          pdf.getBase64((data) => {
            resolve(data);
          });
          break;
        case 'blob':
          pdf.getBlob((data) => {
            resolve(data);
          });
          break;
        default: reject(new DOMException('Problem parsing input file.')); break;
      }
    });
  }

  public generatePdf(action: string = 'open', orderType: number, report: Report) {

    this.saleService.getSaleReport(report.sale.idCompany, orderType, report.sale.id, report.title).subscribe(r => {
      const _reports: Report = r;
      if (!Utilities.isNullOrUndefined(r.reportDocJson)) {
        try {
          const documentDefinition: any = r.reportDocJson;

          if (!Utilities.isNullOrUndefined(documentDefinition.footer)) {
            const _footer: any = {};
            Object.assign(_footer, documentDefinition.footer);
            documentDefinition.footer = (currentPage, pageCount) => [{ text: `Page ${currentPage} of ${pageCount}`, style: 'page' }];
          }
          // if ( !Utilities.isNullOrUndefined( documentDefinition.header ) )
          // {
          //  let _header: any = {};
          //  Object.assign( _header, documentDefinition.header );
          //  documentDefinition.header = function ( currentPage, pageCount, pageSize ) { return [_header]; };
          // }

          this.generate(action, documentDefinition);
        } catch (e) {
          console.log(e);
        }
      }
    });
  }

  async mergePdfs(pdfsToMerges: ArrayBuffer[]) {
    const mergedPdf = await PDFDocument.create();
    const actions = pdfsToMerges.map(async pdfBuffer => {
      const pdf = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        // console.log('page', page.getWidth(), page.getHeight());
        page.setWidth(210);
        mergedPdf.addPage(page);
      });
    });
    await Promise.all(actions);
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  }

  public generateLocalPdf(action: string = 'open', report: Report) {
    const documentDefinition = this.getReport(report);
    this.generate(action, documentDefinition);
  }

  private generate(action: string = 'open', documentDefinition: any) {
    switch (action) {
      case 'open': pdfMake.createPdf(documentDefinition).open(); break;
      case 'print': pdfMake.createPdf(documentDefinition).print(); break;
      case 'download': pdfMake.createPdf(documentDefinition).download('invoice.pdf'); break;
      case 'blob':
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBase64((data) => {
          console.log(data);
        });
        pdfDocGenerator.getBlob((blob) => {
          console.log(blob);
        });
        break;
      default: pdfMake.createPdf(documentDefinition).open(); break;
    }
  }


  private getReport(report: Report) {
    return {
      footer: (currentPage, pageCount) => [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, style: 'name' }],
      header: (currentPage, pageCount, pageSize) => [
        {
          image: report.image,
          width: 100
        }
      ],
      content: [
        {
          text: report.title,
          bold: true,
          fontSize: 14,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [
              {
                image: report.image,
                width: 100,
                alignment: 'left'
              }
            ],
            [
              {
                text: report.customer.name,
                style: 'name'
              },
              {
                text: report.customer.moradaCompleta
              },
              {
                text: report.customer.postalCode + ' ' + report.customer.postalCodeCity
              },
              {
                text: report.customer.telephone
              },
              {
                text: report.customer.email
              },
              {
                text: report.customer.webPage,
                link: report.customer.webPage,
                color: 'blue'
              }
            ],
            [
              {
                text: report.client.name,
                style: 'name'
              },
              {
                text: report.customer.moradaCompleta
              },
              {
                text: report.customer.postalCode + ' ' + report.customer.postalCodeCity
              }
            ]
          ]
        },
        {
          text: report.document,
          style: 'header'
        },
        {
          style: 'tableDetails',
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
              [{
                text: this.gT('orders.editor.Artigo'),
                style: 'tableHeader'
              },
              {
                text: this.gT('orders.editor.Description'),
                style: 'tableHeader'
              },
              {
                text: this.gT('orders.editor.Quantity'),
                style: 'tableHeader'
              },
              {
                text: this.gT('orders.editor.PrecUnit'),
                style: 'tableHeader'
              },
              {
                text: this.gT('orders.editor.Discount'),
                style: 'tableHeader'
              },
              {
                text: this.gT('orders.editor.Discount2'),
                style: 'tableHeader'
              },
              {
                text: this.gT('orders.editor.Discount3'),
                style: 'tableHeader'
              },
              {
                text: this.gT('orders.editor.NetPrice'),
                style: 'tableHeader'
              }
              ],
              ...report.sale.lines.map(ed => {
                return [ed.artigo, ed.descricao, ed.quantidade, ed.precUnit, ed.desconto, ed.desconto2, ed.desconto3, ed.totalPriceTax];
              })
            ]
          },
          layout: 'headerLineOnly'
        },
        {
          columns:
            [
              {
                qr: report.qrcode, // report.sale.entidade + ', Contact No : ' + report.sale.nome,
                fit: 100
              },
              // {
              //  text: `(${report.qrcode})`,
              //  alignment: 'right',
              // }
            ]
        }
      ],
      info: {
        title: report.sale.entidade + '_' + report.title,
        author: 'Inovflow',
        subject: report.title,
        keywords: report.title + ', ONLINE ' + report.title,
      },
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 20, 0, 10],
          decoration: 'underline'
        },
        name: {
          fontSize: 10,
          bold: true
        },
        jobTitle: {
          fontSize: 10,
          bold: true,
          italics: true
        },
        sign: {
          margin: [0, 50, 0, 10],
          alignment: 'right',
          italics: true
        },
        tableHeader: {
          fontSize: 10,
          bold: true
        },
        tableDetails: {
          fontSize: 8
        },
        defaultStyle: {
          fontSize: 8
        }
      }
    };
  }

}
