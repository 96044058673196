import { AuditableEntity } from '../commons';
import { AuditDetails } from './audit-details.model';

export class Audit extends AuditableEntity {
  id?: number;
  idTable?: string;
  idUser?: string;
  userName?: string;
  tableName?: string;
  linesCount?: number;
  createdDate?: Date;
  updatedDate?: Date;
  lines?: AuditDetails[];
}
