import { SalesLines } from './sale-lines.model';
import { MoradasAlternativa } from '../entidades/moradaalternativa.model';
import { CancelSale } from './cancel.sale.model';
import { ApprovedSale } from './approved-sale.model';
import { Approval } from '../approval/approval.model';

export class Sale {
  isSelect?: boolean;
  idOrder?: number;
  idCompany?: number;
  idOrdersType?: number;
  id?: string;
  tipoDoc?: string;
  tipoDocNome?: string;
  serie?: string;
  numDoc?: number;
  filial?: string;
  data?: Date;
  entidade?: string;
  tipoEntidade?: string = 'C';
  modoPag?: string;
  condPag?: string;
  entidadeDescription?: string;
  entidadeAssociadosDescription?: string;
  nome?: string;
  morada?: string;
  morada2?: string;
  localidade?: string;
  distrito?: string;
  codigoPostal?: string;
  localidadeCodPostal?: string;
  pais?: string;
  nif?: string;
  entidadeEntrega?: string;
  tipoEntidadeEntrega?: string = 'C';
  moradaAltEntrega?: string;
  moradaAltEntregaDescription?: string;
  descontoFinanceiro?: number;
  totalDocumento?: number;
  totalMerc?: number;
  totalDesc?: number;
  totalLiquido?: number;
  estado?: string;
  viewEstado?: string;
  referencia?: string;
  dataHoraDescarga?: Date;
  vendedor?: string;
  vendedorNome?: string;
  observacoes?: string;
  paisEncomenda?: string;
  orderType?: number;
  tteEntidade?: string;
  level2?: string;
  lines?: SalesLines[];
  moradasAlternativas?: MoradasAlternativa[];
  cancelSales?: CancelSale;
  approvedSales?: ApprovedSale;
  approvals?: Approval[];

  pdf?: string;
  templateReport?: any;

  giftCardCode?: string;
  couponCode?: string;
}
