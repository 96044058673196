import { Entidade, Sale, Purchase } from '..';

export class Report {
  idCompany?: number;
  idDoc?: string;
  entidade?: string;
  report?: any;
  reportDocJson?: any;

  image?: string;
  title?: string;
  document?: string;
  customer?: Entidade;
  client?: Entidade;
  sale?: Sale;
  purchase?: Purchase;
  qrcode?: string;
  action?: string;
  sendEmail?: boolean;

  vias?: string;
  router?: number;
  reportEmail?: any;
  reportEmailJson?: any;

  reportDocument?: any;
}
