import { Injectable } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';

@Injectable()
export class UtilitiesFilter {
  //#region formatValue
  private static formatValue(value: any, spaces: number = 0): any {
    if (value && Array.isArray(value[0])) {
      try {

        const TAB_SIZE = 4;
        spaces = spaces || TAB_SIZE;
        return '[' + UtilitiesFilter.getLineBreak(spaces) + value.map((item) => {
          return Array.isArray(item[0]) ? UtilitiesFilter.formatValue(item, spaces + TAB_SIZE) : JSON.stringify(item);
        }).join(',' + UtilitiesFilter.getLineBreak(spaces)) + UtilitiesFilter.getLineBreak(spaces - TAB_SIZE) + ']';
      }
      catch (e) {
        console.log(e);
        return JSON.stringify(value);
      }
    }
    return JSON.stringify(value);
  }
  //#endregion

  //#region getLineBreak
  private static getLineBreak(spaces: number): string {
    return '\r\n' + new Array(spaces + 1).join(' ');
  }
  //#endregion

  //#region UpdateText
  public static updateText(e): [any, any] {
    const result1: any = UtilitiesFilter.formatValue(e.component.option('value'));
    const result2: any = UtilitiesFilter.formatValue(e.component.getFilterExpression());
    return [result1, result2];
  }
  //#endregion

  //#region getAnyOfOperation
  public static getAnyOfOperation(editorTemplate: string): any[] {
    return [{
      name: 'anyof',
      caption: 'Is any of',
      icon: 'check',
      editorTemplate,
      calculateFilterExpression(filterValue: any, field: any) {
        return filterValue && filterValue.length && Array.prototype.concat.apply([], filterValue.map((value) => {
          return [[field.dataField, '=', value], 'or'];
        })).slice(0, -1);
      }
    }];
  }
  //#endregion


  public static async getData(dataSource: ArrayStore, key: any): Promise<any> {
    let result: any;
    await dataSource.byKey(key).then((dataItem) => { result = dataItem; },
      (error) => { console.log(error); });
    return result;
  }
}
