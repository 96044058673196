<h1 id="title" class="title">Login</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-userName">User Name:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.userName"
           #userName="ngModel"
           name="userName"
           id="input-userName"
           placeholder="User Name"
           fieldSize="large"
           autofocus
           [status]="userName.dirty ? (userName.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.userName.required')"
           [attr.aria-invalid]="userName.invalid && userName.touched ? true : null">

    <ng-container *ngIf="userName.invalid && userName.touched">
      <p class="caption status-danger" *ngIf="userName.errors?.required">

        Username is required!
      </p>
      <p class="caption status-danger" *ngIf="userName.errors?.pattern">
        Username should be the real one!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Password:</label>
    <nb-form-field>
      <input nbInput
             fullWidth
             [(ngModel)]="user.password"
             #password="ngModel"
             name="password"
             [type]="getInputType()"
             id="input-password"
             placeholder="Password"
             fieldSize="large"
             [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
             [required]="getConfigValue('forms.validation.password.required')"
             [minlength]="getConfigValue('forms.validation.password.minLength')"
             [maxlength]="getConfigValue('forms.validation.password.maxLength')"
             [attr.aria-invalid]="password.invalid && password.touched ? true : null">
      <button nbSuffix nbButton ghost (keydown.enter)="$event.preventDefault()" (click)="toggleShowPassword($event)">
        <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                 pack="eva"
                 [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
        </nb-icon>
      </button>
    </nb-form-field>
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contain
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
    Log In
  </button>
</form>
