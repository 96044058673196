import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth';
import { ConfigurationService } from '../conf';
import { ApiBaseService } from '../api/api-base.service';
import {
    AreaAtividade, Artigos, Attachament, Cargo, Classificacao, ComunicacaoMarketing, CondPagamento, Country, Documents, Entidade, ModoPagamento, MotivosEstadoSubmetido,
    Purchase, PurchaseLines, PurchaseStatus, RelacaoPep, Report, SituacaoProf, TiposDocAttach, TiposId, Unidades, Email,ClientRules, FindPurchase, PortalConfig, Pep
} from '../../models';
import { Utilities } from '../../utils';

@Injectable()
export class PurchasesService extends ApiBaseService {

  private endpoint: string = 'api/document/purchasesDraft';
  private endPointPurchase: string = 'api/purchase/purchase';
  private endPointPurchaseErp: string = 'api/purchase/purchaseErp';
  private endPointEamil: string = 'api/purchase/purchaseSendEmail';
  private endpointLine: string = 'api/document/purchasesLineDraft';
  private endPointAttachament: string = 'api/purchase/attachament';
  private endPointReport: string = 'api/purchase/report';

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService) {
    super(configurations, http, authService);
  }


  //#region get
  getPurchaseByCompanyId<T>(companyId: number, id: any): Observable<T> {
    return this.getByCompanyId<T>(this.endpoint, companyId, id);
  }

  getPurchasesByCompanyId<T>(companyId: number): Observable<T> {
    return this.getListByCompanyId<T>(this.endpoint, companyId);
  }

  getListByCompanyId<T>(endPoint: string, companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/${endPoint}/${companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/${endPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getListByCompanyId(endPoint, companyId, page, pageSize));
      }));
  }
  //#endregion

  //#region purchase
  getPurchaseLoadData(companyId: number, id: any, type: number, key: any): Observable<[Purchase, Documents[], Entidade[], Unidades[]]> {
    return forkJoin([
      id ? this.getByCompanyIdByFilter<Purchase>(`${this.controllerPurchase}`, companyId, id, key, type, 0, 'All') : of(null),
      this.getListByEndPointCompanyId<Documents[]>(`${this.controllerApi}/generics`, 'purchaseDocuments', companyId),
      this.getListByEndPointCompanyId<Entidade[]>(`${this.controllerApi}/generics`, 'providers', companyId),
      this.getListByEndPointCompanyId<Unidades[]>(`${this.controllerApi}/generics`, 'unidades', companyId)
    ]);
  }

  getPurchasesByFilters(companyId: number, key: string, purchaseType: number, estado: string, dateFrom?: Date, dateTo?: Date): Observable<Purchase[]> {
    let endpointUrl = `${this.configurations.baseUrl}/${this.controllerPurchase}s/${companyId}/${key}/${purchaseType}/${estado}`;

    if (!Utilities.isNullOrUndefined(dateFrom) || !Utilities.isNullOrUndefined(dateTo)) {
      endpointUrl += `/${!Utilities.isNullOrUndefined(dateFrom) ? Utilities.DateToJson(dateFrom) : ' '}`;
      endpointUrl += `${!Utilities.isNullOrUndefined(dateTo) ? '/' + Utilities.DateToJson(dateTo) : ' '}`;
    }
    return this.http.get<Purchase[]>(endpointUrl, this.requestHeaders).pipe<Purchase[]>(
      catchError(error => {
        return this.handleError(error, () => this.getPurchasesByFilters(companyId, key, purchaseType, estado, dateFrom, dateTo));
      }));
  }

  getPurchasePortalConfig(companyId: number): Observable<PortalConfig> {
    return this.getPortalConfig(companyId);
  }

  async getPurchasePortalConfigAsync(companyId: number) {
    return await firstValueFrom(this.getPortalConfig(companyId));//.toPromise();
  }
  
  getPurchaseCommonsData(companyId: number): Observable<[AreaAtividade[], Cargo[], ComunicacaoMarketing[], CondPagamento[], Country[], ModoPagamento[], RelacaoPep[], SituacaoProf[], TiposId[], Classificacao[], MotivosEstadoSubmetido[], TiposDocAttach[], Artigos[], Pep[]]> {
    return forkJoin([
      this.getListAreaAtividades(companyId),
      this.getListCargos(companyId),
      this.getListComunicacaoMarketings(companyId),
      this.getListCondPagamentos(companyId),
      this.getListCountries(companyId),
      this.getListModosPagamento(companyId),
      this.getListRelacoesPep(companyId),
      this.getListSituacoesProf(companyId),
      this.getListTiposId(companyId),
      this.getListClassificacoes(companyId),
      this.getListMotivosEstadoSubmetido(companyId),
      this.getListTiposDocAttach(companyId),
      this.getListProducts(companyId),
      this.getListPep(companyId)
    ]);
  }

  getPurchasesLoadData(companyId: number): Observable<[Documents[], Entidade[]]> {
    return forkJoin([
      this.getListByEndPointCompanyId<Documents[]>(`${this.controllerApi}/generics`, 'purchaseDocuments', companyId),
      this.getListByEndPointCompanyId<Entidade[]>(`${this.controllerApi}/generics`, 'providers', companyId)
    ]);
  }

  async getPurchasesLoadDataAsync(companyId: number): Promise<[Documents[], Entidade[]]> {
    return await firstValueFrom(this.getPurchasesLoadData(companyId));//.toPromise();
  }

  getPurchase(companyId: number, id: number): Observable<Purchase> {
    return this.getByCompanyId<Purchase>(this.endpoint, companyId, id);
  }

  getPurchaseNif(companyId: number, nif: string): Observable<Purchase> {
    return this.getByEndPointCompanyId<Purchase>(`${this.controllerApi}/generic`, 'purchaseNif', companyId, nif);
  }

  async getPurchaseNifAsync(companyId: number, nif: string) {
    return await firstValueFrom(this.getPurchaseNif(companyId, nif));//.toPromise();
  }


  getClientRules(companyId: number, nif: string, numBicc?: string): Observable<ClientRules> {
    if (Utilities.isNullOrUndefined(numBicc)) {
      return this.getByEndPointCompanyId<ClientRules>(`${this.controllerApi}/generic`, 'clientRule', companyId, nif);
    } else {
      return this.getByEndPointCompanyIdKey<ClientRules>(`${this.controllerApi}/generic`, 'clientRule', companyId, nif, numBicc);
    }
  }

  async getClientRulesAsync(companyId: number, nif: string, numBicc?: string) {
    return await firstValueFrom(this.getClientRules(companyId, nif, numBicc));//.toPromise();
  }

  createPurchase(companyId: number, purchase?: Purchase): Observable<Purchase> {
    if (!purchase) {
      purchase = new Purchase();
    }
    purchase.idCompany = companyId;
    return this.postByCompanyId<Purchase>(this.endpoint, companyId, purchase);
  }

  updateOrder(companyId: number, purchase: Purchase): Observable<Purchase> {
    purchase.idCompany = companyId;
    return this.patchByCompanyId<Purchase>(this.endpoint, companyId, purchase);
  }

  setPurchaseUpdate(companyId: number, type: number, purchase: Purchase): Observable<Purchase> {
    purchase.idCompany = companyId;
    // type = 0;
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointPurchase}/${companyId}/${type}`;

    return this.http.patch<Purchase>(endpointUrl, JSON.stringify(purchase), this.requestHeaders).pipe<Purchase>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseUpdate(companyId,type, purchase));
      }));
  }

  async setPurchaseUpdateAsync(companyId: number, type: number, purchase: Purchase) {
    return await firstValueFrom(this.setPurchaseUpdate(companyId,type, purchase));//.toPromise();
  }

  setPurchaseErpUpdate(companyId: number, purchase: Purchase): Observable<Purchase> {
    purchase.idCompany = companyId;
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointPurchaseErp}/${companyId}`;

    return this.http.patch<Purchase>(endpointUrl, JSON.stringify(purchase), this.requestHeaders).pipe<Purchase>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseErpUpdate(companyId, purchase));
      }));
  }

  setPurchaseDelete(companyId: number, purchasesObject: any) {
    const endpointUrl = `${this.configurations.baseUrl}/api/document/purchaseDelete/${companyId}`;
    return this.http.patch<Purchase>(endpointUrl, JSON.stringify(purchasesObject), this.requestHeaders).pipe<Purchase>(
      catchError(error => {
        return this.handleError(error, () => this.setPurchaseDelete(companyId, purchasesObject));
      }));
  }

  purchasePrintUpdate(companyId: number, id: any, type: number) {
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointPurchase}Print/${id}/${companyId}/${type}`;
    return this.http.get<Purchase>(endpointUrl, this.requestHeaders).pipe<Purchase>(
      catchError(error => {
        return this.handleError(error, () => this.purchasePrintUpdate(companyId, id, type));
      }));
  }

  sendEmail(companyId: number, _purchase: Purchase): Observable<Email> {
    _purchase.idCompany = companyId;
    const email: Email = { idPurchase: _purchase?.idPurchase, purchase: _purchase, username: this.currentUser.userName };
    // return this.postByEndPointCompanyId<any>(`${this.controllerApi}/generic`, 'email', this.userAtualErpPermissions.companyId, email);
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointEamil}/${companyId}`;

    return this.http.patch<Email>(endpointUrl, JSON.stringify(email), this.requestHeaders).pipe<Email>(
      catchError(error => {
        return this.handleError(error, () => this.sendEmail(companyId, _purchase));
      }));
  }
  //#endregion


  //#region line
  createLineItem(companyId: number, lineItem: PurchaseLines): Observable<PurchaseLines> {
    return this.postByCompanyId<PurchaseLines>(this.endpointLine, companyId, lineItem);
  }

  getLineItem(companyId: number, id: any): Observable<PurchaseLines> {
    return this.getByCompanyId<PurchaseLines>(this.endpointLine, companyId, id);
  }

  updateLineItem(companyId: number, id: number, quantity: number): Observable<PurchaseLines> {
    const _line: PurchaseLines = new PurchaseLines();
    _line.idPurchase = id;
    _line.quantidade = quantity;

    return this.patchByCompanyId<PurchaseLines>(this.endpoint, companyId, _line);
  }

  deleteLineItem(companyId: number, id: number): Observable<PurchaseLines> {
    return this.deleteByCompanyId<PurchaseLines>(this.endpoint, companyId, id);
  }
  //#endregion

  //#region attach
  getAttchament(companyId: number, id: any, type: number): Observable<Attachament> {
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointAttachament}/${companyId}/${id}/${type}`;
    return this.http.get<Attachament>(endpointUrl, this.requestHeaders).pipe<Attachament>(
      catchError(error => {
        return this.handleError(error, () => this.getAttchament(companyId, id, type));
      }));
  }

  getAttchaments(companyId: number, idPurchase: any, type: number): Observable<Attachament[]> {
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointAttachament}s/${companyId}/${idPurchase}/${type}`;
    return this.http.get<Attachament[]>(endpointUrl, this.requestHeaders).pipe<Attachament[]>(
      catchError(error => {
        return this.handleError(error, () => this.getAttchament(companyId, idPurchase, type));
      }));
  }

  setAttchamentUpdate(companyId: number, type: number, attachament: Attachament): Observable<Attachament> {
    attachament.companyId = companyId;
    // type = 0;
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointAttachament}/${companyId}/${type}`;
    return this.http.patch<Attachament>(endpointUrl, JSON.stringify(attachament), this.requestHeaders).pipe<Attachament>(
      catchError(error => {
        return this.handleError(error, () => this.setAttchamentUpdate(companyId, type, attachament));
      }));
  }

  deleteAttchament(companyId: number, id: any): Observable<Attachament> {
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointAttachament}/${companyId}/${id}`;
    return this.http.delete<Attachament>(endpointUrl, this.requestHeaders).pipe<Attachament>(
      catchError(error => {
        return this.handleError(error, () => this.deleteAttchament(companyId,id));
      }));
  }


  async getAttchamentAsync(companyId: number, id: any, type: number) {
    return await firstValueFrom(this.getAttchament(companyId, id, type));//.toPromise();
  }

  async getAttchamentsAsync(companyId: number, idPurchase: any, type: number) {
    return await firstValueFrom(this.getAttchaments(companyId,idPurchase, type));//.toPromise();
  }

  async setAttchamentUpdateAsync(companyId: number, type: number, attachament: Attachament) {
    return await firstValueFrom(this.setAttchamentUpdate(companyId,type, attachament));//.toPromise();
  }

  async deleteAttchamentAsync(companyId: number, id: any) {
    return await firstValueFrom(this.deleteAttchament(companyId,id));//.toPromise();
  }
  //#endregion

  //#region Revision
  setRevision(companyId: number, purchase: Purchase): Observable<Purchase> {
    purchase.idCompany = companyId;
    // type = 0;
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointPurchase}Revision/${companyId}`;

    return this.http.patch<Purchase>(endpointUrl, JSON.stringify(purchase), this.requestHeaders).pipe<Purchase>(
      catchError(error => {
        return this.handleError(error, () => this.setRevision(companyId,purchase));
      }));
  }
  //#endregion

  //#region Cancel
  setCancel(companyId: number, purchase: Purchase): Observable<Purchase> {
    purchase.idCompany = companyId;
    // type = 0;
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointPurchase}Cancel/${companyId}`;

    return this.http.patch<Purchase>(endpointUrl, JSON.stringify(purchase), this.requestHeaders).pipe<Purchase>(
      catchError(error => {
        return this.handleError(error, () => this.setCancel(companyId, purchase));
      }));
  }
  //#endregion

  //#region FindPurchase
  setFindPurchase(companyId: number, purchase: FindPurchase): Observable<FindPurchase> {
    purchase.idCompany = companyId;
    const endpointUrl = `${this.configurations.baseUrl}/${this.controllerApi}/generic/findPurchases/${companyId}`;
    return this.http.patch<FindPurchase>(endpointUrl, JSON.stringify(purchase), this.requestHeaders).pipe<FindPurchase>(
      catchError(error => {
        return this.handleError(error, () => this.setCancel(companyId, purchase));
      }));
  }
  //#endregion

  //#region report
  getReportPurchase(companyId: number, purchaseType: number, id: any, title: string, icon: string, router: number): Observable<Report> {
    const endpointUrl = `${this.configurations.baseUrl}/${this.endPointReport}/${companyId}/${purchaseType}/${id}/${title}/${icon}/${router}`;
    return this.http.get<Report>(endpointUrl, this.requestHeaders).pipe<Report>(
      catchError(error => {
        return this.handleError(error, () => this.getReportPurchase(companyId, purchaseType, id, title, icon, router));
      }));
  }

  async getReportPurchaseAsync(companyId: number, purchaseType: number, id: any, title: string, icon: string, router: number) {
    return await firstValueFrom(this.getReportPurchase(companyId, purchaseType, id, title, icon, router));//.toPromise();
  }
  //#endregion

  async getPurchasesStatusAsync(companyId: number, page?: number, pageSize?: number) {
    return await firstValueFrom(this.getListByCompanyId<PurchaseStatus[]>(`${this.controllerDocument}/$status`, companyId, page, pageSize));//.toPromise();
  }

  newEntity<T>(companyId: number, entity: any): Observable<T> {
    return this.postByCompanyId<T>(this.endpoint, companyId, JSON.stringify(entity));
  }

  updateEntity<T>(companyId: number, entity: any): Observable<T> {
    return this.putByCompanyId<T>(this.endpoint, companyId, JSON.stringify(entity));
  }

  deleteEntity<T>(companyId: number, id: any): Observable<T> {
    return this.deleteByCompanyId(this.endpoint, companyId, id);
  }

  //#region post
  post<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.post(endPoint, entity));
      }));
  }

  postByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postByCompanyId(endPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region put
  put<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.put(endPoint, entity));
      }));
  }

  putByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putByCompanyId(endPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region patch
  patch<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patch(endPoint, entity));
      }));
  }

  patchByCompanyId<T>(endPoint: string, companyId: number, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchByCompanyId(endPoint, companyId, entity));
      }));
  }
  //#endregion

  //#region delete
  delete<T>(endPoint: string, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.delete(endPoint, id));
      }));
  }

  deleteByCompanyId<T>(endPoint: string, companyId: number, id: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}/${companyId}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteByCompanyId(endPoint, companyId, id));
      }));
  }
  //#endregion

}
