import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError} from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { Utilities } from '../../utils/utilities';
import { ConfigurationService } from '../';
import { ApiBaseService } from '../api/api-base.service';


@Injectable()
export class CustomerEndpoint extends ApiBaseService {

  constructor(protected configurations: ConfigurationService, protected http: HttpClient, protected authService: AuthService) {
    super(configurations, http, authService);
  }

  //#region Company
  ///
  // Company
  ///
  getCompanyEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/companies/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyEndpoint(companyId));
      }));
  }

  getCompanyByCompanyNameEndpoint<T>(companyName: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/companies/name/${companyName}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyByCompanyNameEndpoint(companyName));
      }));
  }

  getCompaniesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/customer/companies/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/customer/companies`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompaniesEndpoint(page, pageSize));
      }));
  }

  getNewCompanyEndpoint<T>(companyObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/companies`;

    return this.http.post<T>(endpointUrl, JSON.stringify(companyObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCompanyEndpoint(companyObject));
      }));
  }

  getUpdateCompanyEndpoint<T>(companyObject: any, companyId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/companies/${companyId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(companyObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateCompanyEndpoint(companyObject, companyId));
      }));
  }

  getDeleteCompanyEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/companies/${companyId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteCompanyEndpoint(companyId));
      }));
  }
  //#endregion

  //#region Erp Company
  ///
  // Erp Company
  ///
  getAllErpCompaniesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/companies`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllErpCompaniesEndpoint());
      }));
  }

  getAllErpCompaniesByUrlEndpoint<T>(apiEndPoint: string, token?: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/order/companies/${apiEndPoint}/${token}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllErpCompaniesByUrlEndpoint(apiEndPoint, token));
      }));
  }
  //#endregion

  //#region Catalog
  ///
  // Catalog
  ///

  //#region Get
  getCatalogEndpoint<T>(catalogId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/catalog/${catalogId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCatalogEndpoint(catalogId));
      }));
  }

  getCatalogsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/customer/catalogs/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/customer/catalogs`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCatalogsEndpoint(page, pageSize));
      }));
  }

  getCatalogsByDateEndpoint<T>(companyId: number, dateFrom?: Date, dateTo?: Date): Observable<T> {
    const endpointUrl = dateFrom && dateTo ? `${this.configurations.baseUrl}/api/customer/catalogs/${companyId}/${dateFrom}/${dateTo}` : `${this.configurations.baseUrl}/api/customer/catalogs/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCatalogsByDateEndpoint(companyId, dateFrom, dateTo));
      }));
  }

  getCatalogsByCompanydIdEndpoint<T>(companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/customer/catalogs/${companyId}/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/customer/catalogs/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCatalogsByCompanydIdEndpoint(companyId, page, pageSize));
      }));
  }
  //#endregion

  //#region Add
  postNewCatalogEndpoint<T>(catalogObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/catalog`;

    return this.http.post<T>(endpointUrl, JSON.stringify(catalogObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postNewCatalogEndpoint(catalogObject));
      }));
  }
  //#endregion

  //#region Update
  putUpdateCatalogEndpoint<T>(catalogObject: any, catalogId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/catalog/${catalogId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(catalogObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putUpdateCatalogEndpoint(catalogObject, catalogId));
      }));
  }
  //#endregion

  //#region Delete
  deleteCatalogEndpoint<T>(catalogId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/catalog/${catalogId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteCatalogEndpoint(catalogId));
      }));
  }
  //#endregion
  //#endregion

  //#region AppPortal
  ///
  // AppPortal
  ///

  //#region Get
  getAppPortalEndpoint<T>(portalId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/appPortal/${portalId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAppPortalEndpoint(portalId));
      }));
  }
  // appPortalsTitle
  getAppPortalsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/customer/appPortals/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/customer/appPortals`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAppPortalsEndpoint(page, pageSize));
      }));
  }
  //#endregion

  //#region Add
  postNewAppPortalEndpoint<T>(appPortalObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/appPortal`;

    return this.http.post<T>(endpointUrl, JSON.stringify(appPortalObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postNewAppPortalEndpoint(appPortalObject));
      }));
  }
  //#endregion

  //#region Update AppPortal
  putUpdateAppPortalEndpoint<T>(appPortalObject: any, appPortalId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/appPortal/${appPortalId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(appPortalObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putUpdateAppPortalEndpoint(appPortalObject, appPortalId));
      }));
  }
  //#endregion

  //#region Delete
  deleteAppPortalEndpoint<T>(appPortalId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/appPortal/${appPortalId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteAppPortalEndpoint(appPortalId));
      }));
  }
  //#endregion
  //#endregion

  //#region Generic
  ///
  // Generic
  ///

  //#region Get
  getGenericEndpoint<T>(endPoint: string, id: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/${endPoint}/${id}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getGenericEndpoint(endPoint, id));
      }));
  }

  getGenericsEndpoint<T>(endPoint: string, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/customer/${endPoint}/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/customer/${endPoint}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getGenericsEndpoint(endPoint, page, pageSize));
      }));
  }

  getByCompanydIdGenericEndpoint<T>(endPoint: string, companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/customer/${endPoint}/${companyId}/${page}/${pageSize}` :
      `${this.configurations.baseUrl}/api/customer/${endPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByCompanydIdGenericEndpoint(endPoint, companyId, page, pageSize));
      }));
  }

  getByCompanydIdDateGenericEndpoint<T>(endPoint: string, companyId: number, dateFrom?: Date, dateTo?: Date): Observable<T> {
    const endpointUrl = dateFrom && dateTo ? `${this.configurations.baseUrl}/api/customer/${endPoint}/${companyId}/${Utilities.DateJson(dateFrom)}/${Utilities.DateJson(dateTo)}` :
      dateFrom ? `${this.configurations.baseUrl}/api/customer/${endPoint}/${companyId}/${Utilities.DateJson(dateFrom)}/${null}` :
        dateTo ? `${this.configurations.baseUrl}/api/customer/${endPoint}/${companyId}/${null}/${Utilities.DateJson(dateTo)}` :
          `${this.configurations.baseUrl}/api/customer/${endPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getByCompanydIdDateGenericEndpoint(endPoint, companyId, dateFrom, dateTo));
      }));
  }
  //#endregion

  //#region Add
  postNewGenericEndpoint<T>(endPoint: string, entityObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/${endPoint}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entityObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postNewGenericEndpoint(endPoint, entityObject));
      }));
  }

  postNewGenericFileEndpoint<T>(endPoint: string, entityObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/${endPoint}`;

    // this.http.post( 'https://localhost:5001/api/upload', formData, { reportProgress: true, observe: 'events' } )
    //  .subscribe( event =>
    //  {
    //    if ( event.type === HttpEventType.UploadProgress )
    //      this.progress = Math.round( 100 * event.loaded / event.total );
    //    else if ( event.type === HttpEventType.Response )
    //    {
    //      this.message = 'Upload success.';
    //      this.onUploadFinished.emit( event.body );
    //    }
    //  } );
    return this.http.post<T>(endpointUrl, JSON.stringify(entityObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postNewGenericFileEndpoint(endPoint, entityObject));
      }));
  }
  //#endregion

  //#region Update
  patchGenericByUrlByKey<T>(endPoint: string, entity: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/${endPoint}ByUrl`;
    return this.http.patch<T>(endpointUrl, JSON.stringify(entity), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchGenericByUrlByKey(endPoint, entity));
      }));
  }

  putUpdateGenericEndpoint<T>(endPoint: string, entityObject: any, id: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/${endPoint}/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entityObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putUpdateGenericEndpoint(endPoint, entityObject, id));
      }));
  }
  //#endregion

  //#region Delete
  deleteGenericEndpoint<T>(endPoint: string, id: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/customer/${endPoint}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteGenericEndpoint(endPoint, id));
      }));
  }
  //#endregion
  //#endregion

  //#region Customer
  //#region Get
  getCustomerEndpoint<T>(controller: string, endPoint: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerEndpoint(controller, endPoint));
      }));
  }

  getCustomerByTagEndpoint<T>(controller: string, endPoint: string, tag: string): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${tag}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerByTagEndpoint(controller, endPoint, tag));
      }));
  }

  getCustomerByIdEndpoint<T>(controller: string, endPoint: string, id: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${id}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerByIdEndpoint(controller, endPoint, id));
      }));
  }

  getCustomerByIdCompanyIdEndpoint<T>(controller: string, endPoint: string, id: number, companyId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${id}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomerByIdCompanyIdEndpoint(controller, endPoint, id, companyId));
      }));
  }



  getCustomersEndpoint<T>(controller: string, endPoint: string, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${page}/${pageSize}` : `${this.configurations.baseUrl}/api/${controller}/${endPoint}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomersEndpoint(controller, endPoint, page, pageSize));
      }));
  }
  getCustomersByCompanydIdEndpoint<T>(controller: string, endPoint: string, companyId: number, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${companyId}/${page}/${pageSize}` :
      `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${companyId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomersByCompanydIdEndpoint(controller, endPoint, companyId, page, pageSize));
      }));
  }
  getCustomersByCompanydIdSalesmanEndpoint<T>(controller: string, endPoint: string, companyId: number, salesman: string, page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${companyId}/${salesman}/${page}/${pageSize}` :
      `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${companyId}/${salesman}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomersByCompanydIdSalesmanEndpoint(controller, endPoint, companyId, salesman, page, pageSize));
      }));
  }
  getCustomersByCompanydIdSalesmanDateEndpoint<T>(controller: string, endPoint: string, companyId: number, salesman: string, dateFrom?: Date, dateTo?: Date): Observable<T> {
    let endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${companyId}/${salesman}`;
    if (dateFrom) {
      endpointUrl += `/${dateFrom}`;
    }
    else {
      endpointUrl += `/${null}`;
    }

    if (dateTo) {
      endpointUrl += `/${dateTo}`;
    }
    else {
      endpointUrl += `/${null}`;
    }
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomersByCompanydIdSalesmanDateEndpoint(controller, endPoint, companyId, salesman, dateFrom, dateTo));
      }));
  }
  getCustomersByCompanydIdSalesmanOrderTypeDateEndpoint<T>(controller: string, endPoint: string, id: any, companyId: number, salesman: string, orderType: number, estado: string, dateFrom?: Date, dateTo?: Date): Observable<T> {
    let endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${id}/${companyId}/${salesman}/${orderType}/${estado}`;
    if (dateFrom) {
      endpointUrl += `/${Utilities.DateJson(dateFrom)}`;
    }
    else {
      endpointUrl += `/${null}`;
    }

    if (dateTo) {
      endpointUrl += `/${Utilities.DateJson(dateTo)}`;
    }
    else {
      endpointUrl += `/${null}`;
    }

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCustomersByCompanydIdSalesmanOrderTypeDateEndpoint(controller, endPoint, id, companyId, salesman, orderType, estado, dateFrom, dateTo));
      }));
  }
  //#endregion

  //#region Add
  postNewCustomerEndpoint<T>(controller: string, endPoint: string, entityObject: any): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(entityObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postNewCustomerEndpoint(controller, endPoint, entityObject));
      }));
  }
  //#endregion

  //#region Update
  putUpdateCustomerEndpoint<T>(controller: string, endPoint: string, entityObject: any, id: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entityObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putUpdateCustomerEndpoint(controller, endPoint, entityObject, id));
      }));
  }

  putUpdateCustomerByCompanyIdEndpoint<T>(controller: string, endPoint: string, entityObject: any, id: number, companyId: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${id}/${companyId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(entityObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putUpdateCustomerByCompanyIdEndpoint(controller, endPoint, entityObject, id, companyId));
      }));
  }
  //#endregion

  //#region Delete
  deleteCustomerEndpoint<T>(controller: string, endPoint: string, id: number): Observable<T> {
    const endpointUrl = `${this.configurations.baseUrl}/api/${controller}/${endPoint}/${id}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteCustomerEndpoint(controller, endPoint, id));
      }));
  }
  //#endregion
  //#endregion
}
