import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {  ToastaConfig} from 'ngx-toasta';
import { NbMediaBreakpointsService, NbThemeService } from '@nebular/theme';
import {AuthService} from '../../../@core/services';

@Component({
  selector: 'app-layout',
  styleUrls: ['./layout.layout.scss'],
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  stickyToasties: number[] = [];

  constructor(private authService: AuthService, private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService, private toastaConfig: ToastaConfig) {

    this.toastaConfig.theme = 'material';
    this.toastaConfig.position = 'bottom-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = true;
  }

  ngOnInit(): void {
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
