import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/services';
import { UserLogin } from '../../../@core/models';
import { getDeepFromObject, Utilities } from '../../../@core/utils';

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {

  form: FormGroup;

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: UserLogin = new UserLogin();
  submitted: boolean = false;
  rememberMe = false;
  showPassword = false;

  options: any = {
    forms: {
      login: {
        redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
        strategy: 'email',  // provider id key. If you have multiple strategies, or what to use your own
        rememberMe: true,   // whether to show or not the `rememberMe` checkbox
        showMessages: {     // show/not show success/error messages
          success: true,
          error: true,
        }
      },
      validation: {
        password: {
          required: true,
          minLength: 3,
          maxLength: 50,
        },
        userName: {
          required: true,
        },
        fullName: {
          required: false,
          minLength: 4,
          maxLength: 50,
        },
      },
    },
  };

  loginStatusSubscription: any;

  constructor(private authService: AuthService, private cd: ChangeDetectorRef, private router: Router) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      body: new FormControl('', Validators.required)
    });

    this.user.rememberMe = this.authService.rememberMe;

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.authService.redirectLoginUser();
        }
      });
    }

  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }


  getShouldRedirect() {
    return this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.showPassword = false;

    this.authService.loginWithPassword(this.user.userName, this.user.password, this.user.rememberMe)
      .subscribe(
        {
          next: (user) => {
            setTimeout(() => {
              this.submitted = false;
              this.messages = [`Welcome ${user.userName}!`];
            }, 500);

            setTimeout(() => {
              return this.router.navigate(['/']);
              // return this.router.navigateByUrl(redirect);
            }, this.redirectDelay);

            this.cd.detectChanges();
          },
          error: (error) => {
            if (Utilities.checkNoNetwork(error)) {
              this.errors = [`${Utilities.noNetworkMessageCaption} - ${Utilities.noNetworkMessageDetail}`];
              // this.offerAlternateHost();
            } else {
              const errorMessage = Utilities.getHttpResponseMessage(error);

              if (errorMessage) {
                this.errors = [`Unable to login - ${this.mapLoginErrorMessage(errorMessage)}`];
              } else {
                this.errors = [`Unable to login - An error occured whilst logging in, please try again later.\nError: ${Utilities.getResponseBody(error)}`];
              }
            }
            setTimeout(() => {
              this.submitted = false;
            }, 500);

            this.cd.detectChanges();
          }
        });
  }

  mapLoginErrorMessage(error: string) {

    if (error === 'invalid_username_or_password') {
      return 'Invalid username or password';
    }

    if (error === 'invalid_grant') {
      return 'This account has been disabled';
    }

    return error;
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword(e) {
    if (e.pointerType && e.pointerType === 'mouse') {
      this.showPassword = !this.showPassword;
    } else {
      this.showPassword = false;
    }
  }
}
