import { Permission } from './permission.model';
import { RoleCompanies } from '../companies/role-companies.model';

export class Role {
  constructor(name?: string, description?: string, permissions?: Permission[], companiesId?: number[]) {
    this.name = name;
    this.description = description;
    this.permissions = permissions;
    this.companiesId = companiesId;
  }

  public id?: string;
  public name?: string;
  public description?: string;
  public usersCount?: number;
  public permissions?: Permission[];
  public companies?: RoleCompanies[];
  public companiesId?: number[];
}
